import { UiIcon, ColumnsType, UiTableProps, UiTable, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { SurveyChoiceListModel } from '@vkph/common/types/models';
import CommentAltSvg from '@vkph/ui/svg/comment-alt.svg';

export interface SurveyQuestionTableProps extends UiTableProps {
  data: SurveyChoiceListModel[];
}

const columns: ColumnsType<SurveyChoiceListModel> = [
  {
    title: 'Ответы',
    dataIndex: 'answer',
    key: 'answer',
  },
  {
    title: 'Количество',
    dataIndex: 'amount',
    key: 'amount',
    sorter: ({ amount: a }, { amount: b }) => a - b,
  },
  {
    title: 'Доля',
    dataIndex: 'percent',
    key: 'percent',
    render: (value) => <UiTypography.Text strong>{value}%</UiTypography.Text>,
    sorter: ({ percent: a }, { percent: b }) => a - b,
  },
  {
    title: '',
    dataIndex: 'comments',
    key: 'comments',
    render: (value) => {
      return value ? <UiIcon.Label component={CommentAltSvg} label={value} /> : null;
    },
  },
];

export const SurveyQuestionTable: FC<SurveyQuestionTableProps> = (props) => {
  const { data, ...restProps } = props;

  return <UiTable columns={columns} dataSource={data} pagination={false} {...restProps} />;
};
