import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getPostAttachmentsStorage } from '@vkph/common/store/attachment';
import { BlogModel, PostBasicModel } from '@vkph/common/types/models';

import { PostsStorage } from '../../../store/post';
import { PostCompoundCommentsWithInput } from './comments-with-input/PostCompoundCommentsWithInput';
import { PostCompoundEdit } from './edit/PostCompoundEdit';
import { PostCompoundLoading } from './loading/PostCompoundLoading';
import { PostCompoundRepresentation } from './representation/PostCompoundRepresentation';
import { PostCompoundSearchView } from './search-view/PostCompoundSearchView';

type PostItemComposition = {
  View: typeof PostCompoundRepresentation;
  Edit: typeof PostCompoundEdit;
  SearchView: typeof PostCompoundSearchView;
  Loading: typeof PostCompoundLoading;
  CommentsWithInput: typeof PostCompoundCommentsWithInput;
};

export type PostItemProps = {
  post: PostBasicModel;
  postsStorage: PostsStorage;
  blog?: BlogModel;
  onPin?: () => void;
};

type PostItemComponent = FC<PostItemProps> & PostItemComposition;

export const PostCompound: PostItemComponent = (props) => {
  const { postsStorage, post, blog, onPin } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setIsEditMode(false);
  }, [post]);

  const attachmentsStorage = useMemo(
    () => getPostAttachmentsStorage({ postId: post.id, postType: post.type }),
    [post.id, post.type],
  );

  const { data: postAttachments, refetchWithLastParams: attachmentsRefetchWithLastParams } =
    useAbstractStorage(attachmentsStorage.storage, {
      autoFetchAndRefetch: post.attachmentsCount > 0,
      cancelPendingRequestOnUnmount: true,
    });

  const switchEditMode = useCallback(() => {
    setIsEditMode((isEdit) => !isEdit);
    attachmentsRefetchWithLastParams();
  }, [attachmentsRefetchWithLastParams]);

  if (isEditMode) {
    return (
      <PostCompoundEdit
        post={post}
        attachments={postAttachments}
        blog={blog}
        postType={post.type}
        onCancel={switchEditMode}
      />
    );
  }

  return (
    <PostCompoundRepresentation
      post={post}
      attachments={postAttachments}
      blog={blog}
      switchEditMode={switchEditMode}
      onPin={onPin}
      postsStorage={postsStorage}
      attachmentsStorage={attachmentsStorage}
    />
  );
};

PostCompound.View = PostCompoundRepresentation;
PostCompound.Edit = PostCompoundEdit;
PostCompound.SearchView = PostCompoundSearchView;
PostCompound.Loading = PostCompoundLoading;
PostCompound.CommentsWithInput = PostCompoundCommentsWithInput;
