import { BreadcrumbHistory, ShareButton } from '@vkph/components';
import { Layout, UiBreadcrumbProps } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useCurrentProfile } from '@vkph/common/providers';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel, ProfileInfoModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, isPathMatchRoute, RouteNames } from '@vkph/common/utils';

interface Props extends UiBreadcrumbProps {
  hideShareButton?: boolean;
}

export const ProfileBreadcrumb: FC<Props> = (props) => {
  const { hideShareButton, items, ...rest } = props;
  const navigate = useNavigate();
  const profile = useCurrentProfile();
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();
  const { pathname } = useLocation();

  const isCurrentProfile = profile.id === userId;
  const isEditAvailable = isCurrentProfile || profile.superAdmin;
  const isProfileEdit = isPathMatchRoute(pathname, RouteNames.ProfileEdit);
  const profilePath = getRoutePath(RouteNames.Profile, { id: userId });

  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);

  const { main: profileMainData } = profileFullInfoData || ({} as Partial<ProfileInfoModel>);

  const name = profileMainData?.fullName
    ? getFullNameWithoutPatronymic(profileMainData.fullName)
    : 'Нет такого профиля';

  useEffect(() => {
    if (isProfileEdit && !isEditAvailable) {
      navigate(profilePath);
    }
  }, [isEditAvailable, isProfileEdit]);

  const routes = useMemo(() => {
    const breadcrumbProfileEditRoutes = [
      { path: `${getRoutePath(RouteNames.Home)}`, title: 'Главная' },
      { path: `${profilePath}`, title: isCurrentProfile ? 'Мой профиль' : name },
      { path: '', title: 'Редактирование' },
    ];

    let breadcrumbProfileRoutes: UiBreadcrumbProps['items'] = items || [];

    if (name) {
      breadcrumbProfileRoutes = [...breadcrumbProfileRoutes, { path: '', title: name }];
    }

    return isProfileEdit ? breadcrumbProfileEditRoutes : breadcrumbProfileRoutes;
  }, [isProfileEdit, isCurrentProfile, profilePath, name, items]);

  return (
    <Layout.Breadcrumb>
      <BreadcrumbHistory
        {...rest}
        items={routes}
        after={!isProfileEdit && !hideShareButton && <ShareButton label="Поделиться" />}
      />
    </Layout.Breadcrumb>
  );
};
