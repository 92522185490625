import React, { FC, PropsWithChildren } from 'react';

import DragSvg from '@vkph/ui/svg/drag.svg';

import { useSpace } from '../../../../hooks';
import { UiFlex } from '../../../flex';
import { UiIcon } from '../../../icon';
import styles from './UiTableDnDCellOverlay.scss';

interface UiTableDnDCellOverlayProps {
  grabIcon?: boolean;
}

export const UiTableDnDCellOverlay: FC<PropsWithChildren<UiTableDnDCellOverlayProps>> = (props) => {
  const { children, grabIcon = true } = props;
  const { spaceXS } = useSpace();

  return (
    <UiFlex className={styles.uiTableDndCellOverlay} justify="space-between" gap={spaceXS}>
      {children}
      {grabIcon && <UiIcon width={20} height={20} component={DragSvg} />}
    </UiFlex>
  );
};
