import { UiCard, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './SurveyQuestionSection.scss';

export type SurveyQuestionSectionProps = {
  index: number;
  className?: string;
  onFocus?: () => void;
};

export const SurveyQuestionSection: FC<PropsWithChildren<SurveyQuestionSectionProps>> = (props) => {
  const { index = 0, children, className, ...restProps } = props;

  return (
    <UiCard {...restProps} size="default" className={classNames(styles.surveyQuestionSection, className)}>
      <UiTypography.Title level={3} type="secondary">
        {`Вопрос ${index + 1}`}
      </UiTypography.Title>
      {children}
    </UiCard>
  );
};
