import { Notification } from '@vkph/components';
import { UiAvatarProps, UiButton } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { GamificationBadges, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, authService, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../NotificationsDrawer';

interface Props {
  notification: NotificationModel<GamificationBadges>;
}

export const NotificationGamificationReward: FC<Props> = (props) => {
  const { notification } = props;
  const { createdAt, content } = notification;

  const currentUserId = authService.getCurrentUserId();
  const navigate = useNavigate();

  const notificationTitle = content.badge.name;
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: content.badge.image } satisfies UiAvatarProps;

  const profileRewardLocation = {
    pathname: getRoutePath(RouteNames.ProfileRewardsProgress, {
      id: String(currentUserId),
      rewardId: content.id,
    }),
  };

  const onClick = () => navigate(profileRewardLocation);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={profileRewardLocation}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={notification.initiator.isActive}
      />
      <Notification.Body>
        <UiButton style={{ marginBottom: 12 }} label="Посмотреть" type="primary" onClick={onClick} />
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
