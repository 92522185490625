import { File, VideoPlayer } from '@vkph/components';
import { FILESTORAGE_HISTORY_PAGE_SIZE, fileStorageEntryHistoryStorage } from '@vkph/modules';
import {
  UiButton,
  UiCard,
  UiDescriptions,
  UiDescriptionsItemProps,
  UiDivider,
  UiEmpty,
  UiImage,
  UiSkeleton,
  UiSpace,
  UiStatCell,
  UiStatCellBorderType,
  UiTag,
  UiTruncateMarkup,
  UiTypography,
  UiTypographySymbolName,
  message,
  useBreakpoint,
  UiIcon,
  UiFlex,
  notification,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { GlobalModalNames, closeGlobalModal, openGlobalModal } from '@vkph/common/store/global-modals';
import {
  FileStorageContextServiceTypes,
  FileStorageEntryId,
  FileStorageEntryType,
  FileStorageListEntry,
  FileStoragePermissions,
} from '@vkph/common/types/models';
import { FileUploadAccepts, checkFileMimeType, getFormattedDate } from '@vkph/common/utils';
import { useClipboard, useSpace } from '@vkph/ui/hooks';
import AboutSvg from '@vkph/ui/svg/about.svg';
import HistorySvg from '@vkph/ui/svg/history.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';
import { downloadFileByURL, getFormattedFileSize } from '@vkph/ui/utils';

const SHAREABLE_CONTEXT_LIST: FileStorageContextServiceTypes[] = [
  FileStorageContextServiceTypes.Blogs,
  FileStorageContextServiceTypes.Projects,
  FileStorageContextServiceTypes.FileStorage,
];

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileStorageRootId?: FileStorageEntryId;
  onFileHistoryOpen?: (isOpen: boolean) => void;
  onFileInfoGeneralOpen?: (isOpen: boolean) => void;
};

export const FileInfoCard: FC<Props> = (props) => {
  const { fileInfoStorage, fileStorageRootId, onFileInfoGeneralOpen, onFileHistoryOpen } = props;
  const { data: fileInfo, error: fileInfoError } = useStore(fileInfoStorage.storage.store);
  const isFileInfoPending = useStore(fileInfoStorage.storage.fetchEffect.pending);
  const navigate = useNavigate();
  const { setCopied } = useClipboard(window.location.href, {
    onSuccess: () => notification.success({ message: 'Ссылка скопирована' }),
  });
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceL } = useSpace();

  const isShareable = useMemo(() => {
    if (fileInfo?.context?.service) {
      return SHAREABLE_CONTEXT_LIST.includes(fileInfo.context.service);
    }

    return false;
  }, [fileInfo?.context?.service]);

  const isEditAllowed = useMemo(() => {
    if (fileInfo) {
      return fileInfo?.permissionsV2?.includes(FileStoragePermissions.FileEdit);
    }

    return false;
  }, [fileInfo]);

  const descriptionItemProps: UiDescriptionsItemProps = {
    spaceProps: {
      direction: isLayoutLarge ? 'horizontal' : 'vertical',
      size: spaceL,
      align: 'start',
      full: true,
    },
    labelProps: { strong: true, type: 'secondary' },
    labelRowProps: { justify: 'start', style: { width: 188 } },
    span: 24,
  };

  const descriptionMediaItemProps: UiDescriptionsItemProps = {
    ...descriptionItemProps,
    spaceProps: { ...descriptionItemProps.spaceProps, direction: 'vertical' },
  };

  const onDownload = () => {
    if (!fileInfo?.file) {
      message.error('Ссылка недоступна');
    } else {
      message.info('Загрузка началась');

      downloadFileByURL(fileInfo?.file)
        .then((name) => message.success(`Файл "${name}" загружен`))
        .catch(() => message.error('Ошибка загрузки'));
    }
  };

  const onEdit = () => {
    if (fileInfo) {
      openGlobalModal(GlobalModalNames.FileStorageUpdate, {
        fileStorageInfo: fileInfo,
        parent: fileInfo.parent,
        onSuccess: () => {
          fileInfoStorage.storage.refetchWithLastParams();
          fileStorageEntryHistoryStorage.refetchLastChangeEffect({
            pageNumber: 1,
            pageSize: FILESTORAGE_HISTORY_PAGE_SIZE,
            id: fileInfo.id,
          });
        },
      });
    }
  };

  const onMove = () => {
    if (fileStorageRootId && fileInfo) {
      openGlobalModal(GlobalModalNames.FileStorageMoveEntries, {
        rootId: fileStorageRootId,
        rootName: 'Файлы',
        moveItems: [{ ...fileInfo, type: FileStorageEntryType.File }],
        onClose: () => closeGlobalModal(GlobalModalNames.FileStorageMoveEntries),
      });
    } else {
      message.error('Не найдена корневая папка');
    }
  };

  const fileItem = useMemo<FileStorageListEntry | null>(() => {
    if (fileInfo) {
      const { id, name, size, createdAt, createdBy, parent, file, additional, updatedAt, shortName } =
        fileInfo;

      return {
        id,
        name,
        shortName,
        size,
        createdAt,
        createdBy,
        updatedAt,
        parent,
        file,
        type: FileStorageEntryType.File,
        foldersCount: 0,
        filesCount: 0,
        additional,
      };
    }

    return null;
  }, [fileInfo]);

  const isAllowedImageFile =
    fileItem?.additional?.imageData &&
    checkFileMimeType(FileUploadAccepts.ImagePngJpgGifBmp, fileItem.additional.mimeType);
  const isAllowedVideoFile =
    fileItem?.additional?.videoData &&
    checkFileMimeType(FileUploadAccepts.VideoAll, fileItem.additional.mimeType);

  const isShowError = !isFileInfoPending && fileInfoError;

  const FileTitle: FC<PropsWithChildren> = (titleProps) => {
    const { children } = titleProps;

    return (
      <UiTypography.Text strong>
        <UiTruncateMarkup lines={1} truncate tooltipProps={{ title: children }}>
          {children}
        </UiTruncateMarkup>
      </UiTypography.Text>
    );
  };

  const fileSubtitle = (
    <UiTypography.Text type="secondary">
      {getFormattedFileSize(fileItem?.size)}
      <UiDivider.Dot type="secondary" />
      {fileItem?.updatedAt ? getFormattedDate(fileItem.updatedAt, 'dd.MM.yyyy') : ''}
    </UiTypography.Text>
  );

  return (
    <UiCard size="default" emptyRadius={!isLayoutLarge} emptyPadding={!isFileInfoPending}>
      {isFileInfoPending && (
        <UiSkeleton loading height={50} count={3} block style={{ marginBottom: spaceM }} />
      )}
      {isShowError && <UiEmpty.Feed emptyMessage={{ header: 'Информация о файле не найдена' }} />}
      {!isFileInfoPending && fileInfo && (
        <>
          <UiCard.Header
            action={
              !isLayoutLarge && (
                <UiFlex gap={spaceM}>
                  <UiButton
                    type="link-secondary"
                    disabledFocus
                    onClick={() => onFileHistoryOpen?.(true)}
                    icon={<UiIcon component={HistorySvg} width={20} height={20} />}
                  />
                  <UiButton
                    type="link-secondary"
                    disabledFocus
                    onClick={() => onFileInfoGeneralOpen?.(true)}
                    icon={<UiIcon component={AboutSvg} width={20} height={20} />}
                  />
                </UiFlex>
              )
            }
            hasBottomBorder
          >
            <UiCard.Header.Back onClick={() => navigate(-1)} />
            <UiCard.Header.Title>
              <UiSpace size={2} align="center" full>
                <UiTypography.Title level={3} type="secondary" style={{ margin: 0 }}>
                  Версия {fileInfo.revision} <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />
                </UiTypography.Title>
                <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
                <UiTypography.Title level={3} style={{ margin: 0 }}>
                  <UiTruncateMarkup lines={1} truncate>
                    {fileInfo.name}
                  </UiTruncateMarkup>
                </UiTypography.Title>
              </UiSpace>
            </UiCard.Header.Title>
          </UiCard.Header>
          <UiCard.Content basePadding>
            <UiSpace size={spaceL} direction="vertical" full>
              <UiDescriptions gutter={[0, spaceL]}>
                {fileInfo?.description && (
                  <UiDescriptions.Item {...descriptionItemProps} label="Описание">
                    <UiTypography.Paragraph>{fileInfo.description}</UiTypography.Paragraph>
                  </UiDescriptions.Item>
                )}
                {isAllowedImageFile && (
                  <UiDescriptions.Item {...descriptionMediaItemProps} label="Текущая версия">
                    <UiImage
                      src={fileItem.file}
                      width={300}
                      preview={{
                        customize: {
                          onShare: setCopied,
                          onDownload,
                          title: isLayoutLarge && fileInfo?.name,
                          subtitle:
                            isLayoutLarge && getFormattedDate(fileInfo?.updatedAt, 'dd.MM.yyyy HH:mm'),
                        },
                      }}
                    />
                  </UiDescriptions.Item>
                )}
                {isAllowedVideoFile && (
                  <UiDescriptions.Item {...descriptionMediaItemProps} label="Текущая версия">
                    <VideoPlayer
                      light
                      url={fileItem.file}
                      actions={isShareable ? [{ icon: ShareSvg, onClick: setCopied }] : []}
                    />
                  </UiDescriptions.Item>
                )}
                {fileItem && !isAllowedVideoFile && !isAllowedImageFile && (
                  <UiDescriptions.Item {...descriptionItemProps} label="Текущая версия">
                    <UiStatCell.Frame border={UiStatCellBorderType.Solid}>
                      <File
                        title={<FileTitle>{fileInfo.name}</FileTitle>}
                        subtitle={fileSubtitle}
                        fileName={fileInfo.name}
                        file={fileItem}
                        justify="space-between"
                        flex="unset"
                      />
                    </UiStatCell.Frame>
                  </UiDescriptions.Item>
                )}
                {fileInfo.tags.length > 0 && (
                  <UiDescriptions.Item {...descriptionItemProps} label="Теги">
                    <UiTag.Group tags={fileInfo.tags} />
                  </UiDescriptions.Item>
                )}
              </UiDescriptions>
              {fileInfo.categories.length > 0 && (
                <UiDescriptions.Item {...descriptionItemProps} label="Категории">
                  <UiTag.Group tags={fileInfo.categories} />
                </UiDescriptions.Item>
              )}
            </UiSpace>
          </UiCard.Content>
          <UiCard.Footer hasTopBorder>
            <UiCard.Footer.Buttons>
              {isEditAllowed && (
                <UiButton size="large" type="primary" label="Редактировать" onClick={onEdit} />
              )}
              {fileStorageRootId && (
                <UiButton size="large" type="tertiary" label="Переместить" onClick={onMove} />
              )}
            </UiCard.Footer.Buttons>
          </UiCard.Footer>
        </>
      )}
    </UiCard>
  );
};
