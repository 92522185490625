import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import mapValues from 'lodash/mapValues';

import { endpoints } from '../../endpoints';
import { PortalSettingModel, SettingsAuthModel, SettingsConfigLayoutModel } from '../../types/models';
import { PortalSettingData } from '../../types/settings';
import { abstractStorageFactory } from '../../utils';
import { updatePortalSettings, updateSettingsLayouts } from './api';

const defaultValue: SettingsConfigLayoutModel = {
  headerEventsMeetingsDropdownButtonDisabled: {
    value: true,
    label: 'Скрыть кнопку для создания мероприятия в шапке',
  },
  profileStartOfWorkEnabled: {
    value: true,
    label: 'Отображать поле "Дата начала работы" в профиле',
  },
  profileWorkPhoneExtension: {
    value: false,
    label: 'Отображать поле "Добавочный номер" в профиле',
  },
  profileEditAccessControlEnabled: {
    value: false,
    label: 'Отображать меню "Управление доступом" в редактировании профиля',
  },
  adminSurveyTabEnabled: {
    value: false,
    label: 'Отображать вкладку "Опросы" в "Управление контентом" и "Администрирование"',
  },
  pageVKHRTekEnabled: {
    value: false,
    label: 'Отображать страницу vk-hr-tek с виджетом для VKHRTek',
  },
  timelinesErrorPostHide: {
    value: false,
    label: 'Скрывать посты с ошибками',
  },
  profileCalendarEnabled: {
    value: true,
    label: 'Отображать "Календарь" в профиле',
  },
};

const getSettingsLayoutsConfigStorage = () => {
  const storage = abstractStorageFactory<
    SettingsConfigLayoutModel,
    SettingsConfigLayoutModel,
    SettingsConfigLayoutModel
  >({
    endpointBuilder: endpoints.settings.layouts,
    defaultValue,
    dataMapper: (data) => {
      return mapValues(defaultValue, (config, key) => {
        return { ...config, value: data[key] ? data[key].value : config.value };
      });
    },
    cancelPendingRequestOnFetch: true,
  });

  const updateSettingsLayoutsEffect = createEffect<SettingsConfigLayoutModel, unknown, AxiosError>(
    updateSettingsLayouts,
  );

  return { storage, updateSettingsLayoutsEffect };
};

const getPortalSettings = () => {
  const dataMapper = (data: PortalSettingModel[]) =>
    data.reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {});

  const storage = abstractStorageFactory<PortalSettingModel[], PortalSettingData, null>({
    endpointBuilder: endpoints.settings.portalSettings,
    defaultValue: null,
    dataMapper,
    cancelPendingRequestOnFetch: true,
  });

  const updatePortalSettingsEffect = createEffect<PortalSettingData, PortalSettingModel[], AxiosError>(
    (params) => {
      const portalSettings = Object.entries(params).map(([type, value]) => ({ type, value }));

      return updatePortalSettings(portalSettings).then(({ data }) => data);
    },
  );

  storage.store.on(updatePortalSettingsEffect.doneData, (state, newValue) => ({
    ...state,
    data: dataMapper(newValue),
  }));

  return { storage, updatePortalSettingsEffect };
};

export const getSettingsAuthConfigStorage = () => {
  const storage = abstractStorageFactory<SettingsAuthModel, SettingsAuthModel, null>({
    endpointBuilder: endpoints.tenants.auth,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

/** @deprecated Не используй! Тебе пригодится только хук: useSettingsConfig */
export const settingsLayoutsConfigStorage = getSettingsLayoutsConfigStorage();
export const settingsAuthConfigStorage = getSettingsAuthConfigStorage();

export const portalSettingStorage = getPortalSettings();
export type PortalSettingStorage = ReturnType<typeof getPortalSettings>;
