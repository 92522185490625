import { UiInput } from '@vkph/ui';
import cn from 'classnames';
import React, { FC, ChangeEventHandler } from 'react';

import styles from '../CellInput.scss';
import { CellInputProps } from '../types';

export const CellInputLink: FC<CellInputProps> = (props) => {
  const { onChange, value, ...restProps } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange?.(event);
  };

  const handleClick = () => {
    if (value && typeof value === 'string') {
      window.open(value, '_blank');
    }
  };

  return (
    <UiInput
      {...restProps}
      className={cn(styles.uiCellInput, {
        [styles.uiCellInput_link]: Boolean(value),
      })}
      onClick={handleClick}
      value={value}
      onChange={handleChange}
    />
  );
};
