import { UiAvatarProps } from '@vkph/ui';
import React, { FC } from 'react';

import { LongreadTypes, NewsLongreadParams } from '@vkph/common/types/longread';
import { NotificationModel, NotificationNewsCommentModel, PostTypes } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames, parseEntityText } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../NotificationsDrawer';
import { NotificationComment, NotificationTargetTypes } from '../../comment';

type Props = {
  notification: NotificationModel<NotificationNewsCommentModel>;
};

export const NotificationNewsComment: FC<Props> = (props) => {
  const { notification } = props;

  const { content, createdAt, image, initiator } = notification;
  const { fullName, keycloakId, isActive } = initiator;
  const { news, comment, parent } = content;
  const { id, title } = news;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const longreadParams: NewsLongreadParams = {
    type: LongreadTypes.News,
    newsType: PostTypes.Simple,
    id,
  };

  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const targetDescription = `К новости: ${title}`;

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      isActive={isActive}
      subtitle={notificationDescription}
      avatarProps={avatar}
      to={userLink}
      replyText={replyText}
      commentText={commentText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Longread}
      targetParams={{ ...longreadParams, scrollToComments: true }}
      targetDescription={targetDescription}
    />
  );
};
