import { ListColumnId, ListId, ListRowId, ListGroupId, SiteServiceSlug } from '../types/models';

export const listsEndpoints = {
  lists: () => `/api/lists/lists/`,
  listsAuthors: () => `/api/lists/lists/authors/`,
  listId: (listId: ListId) => `/api/lists/lists/${listId}/`,
  listsSlugId: (slugId: SiteServiceSlug) => `/api/lists/lists/slug/${slugId}/`,
  listIdGenerateXlsx: (listId: ListId) => `/api/lists/lists/${listId}/generate-xlsx/`,

  listIdColumnsOrdering: (listId: ListId) => `/api/lists/lists/${listId}/columns-ordering/`,
  listIdColumns: (listId: ListId) => `/api/lists/lists/${listId}/columns/`,
  listIdColumnsId: (listId: ListId, columnId: ListColumnId) =>
    `/api/lists/lists/${listId}/columns/${columnId}/`,
  listIdColumnsIdCopy: (listId: ListId, columnId: ListColumnId) =>
    `/api/lists/lists/${listId}/columns/${columnId}/copy/`,
  listIdColumnsIdUniqueValues: (listId: ListId, columnId: ListColumnId) =>
    `/api/lists/lists/${listId}/columns/${columnId}/unique-values/`,
  listIdColumnsIdToggleVisible: (listId: ListId, columnId: ListColumnId) =>
    `/api/lists/lists/${listId}/columns/${columnId}/toggle-visible/`,

  listIdRowsGetData: (listId: ListId) => `/api/lists/lists/${listId}/rows/get-data/`,
  listIdRows: (listId: ListId) => `/api/lists/lists/${listId}/rows/`,
  listIdRowsDeleteBatch: (listId: ListId) => `/api/lists/lists/${listId}/rows/delete-batch/`,
  listIdRowsId: (listId: ListId, rowId: ListRowId) => `/api/lists/lists/${listId}/rows/${rowId}/`,

  listIdGroups: (listId: ListId) => `/api/lists/lists/${listId}/groups/`,
  listIdGroupsId: (listId: ListId, groupId: ListGroupId) => `/api/lists/lists/${listId}/groups/${groupId}/`,
  listIdGroupsIdAddRows: (listId: ListId, groupId: ListGroupId) =>
    `/api/lists/lists/${listId}/groups/${groupId}/add-rows/`,
  listIdGroupsIdExcludeRows: (listId: ListId, groupId: ListGroupId) =>
    `/api/lists/lists/${listId}/groups/${groupId}/exclude-rows/`,
  listIdGroupsIdUngroup: (listId: ListId, groupId: ListGroupId) =>
    `/api/lists/lists/${listId}/groups/${groupId}/ungroup/`,
  permissionsRoles: () => '/api/lists/permissions/roles/',
  listIdRoles: (listId: ListId) => `/api/lists/lists/${listId}/roles/`,
};
