import { UiIcon, UiButton } from '@vkph/ui';
import React, { CSSProperties, FC, useState, useEffect, useRef } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import { CommentDetailListModel, CommentId, EmojiUuid } from '@vkph/common/types/models';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';

import { CommentItem } from '../comment-item';
import styles from './CommentList.scss';

export type CommentListProps = {
  comments: CommentDetailListModel[];
  showCount?: number;
  maxLevel?: number | null;
  loading?: boolean;
  onSend: (text: string, parentId: CommentId | null) => Promise<unknown>;
  onCommentEdit?: (commentId: CommentId, text: string) => Promise<unknown>;
  onCommentDelete?: (commentId: CommentId) => void;
  onReaction: (commentId: CommentId, emojiUuid?: EmojiUuid) => void;
  maxNestLevelWithShift: number;
  style?: CSSProperties;
};

export const CommentList: FC<CommentListProps> = (props) => {
  const {
    comments,
    showCount = 1,
    maxLevel = null,
    maxNestLevelWithShift,
    onSend,
    loading,
    onCommentEdit,
    onCommentDelete,
    onReaction,
    style,
  } = props;

  const { queryParams } = useQueryParams();
  const { scrollToComments } = queryParams || {};

  const [isExpanded, setIsExpanded] = useState(scrollToComments || comments.length <= showCount);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToComments && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToComments]);

  const shownComments = isExpanded ? comments : comments.slice(0, showCount);
  const actualMaxLevel = isExpanded ? null : maxLevel;

  const ArrowIcon = (
    <UiIcon
      style={{
        display: 'flex',
        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      component={() => <ArrowExpandSvg />}
    />
  );

  return (
    <div ref={containerRef} style={style} className={styles.commentsList}>
      {shownComments.map((comment) => {
        return (
          <CommentItem
            key={comment.id}
            className={styles.commentsList__comment}
            comment={comment}
            onCommentReply={onSend}
            loading={loading}
            maxLevel={actualMaxLevel}
            maxNestLevelWithShift={maxNestLevelWithShift}
            onReaction={onReaction}
            onCommentEdit={onCommentEdit}
            onCommentDelete={onCommentDelete}
          />
        );
      })}
      {!isExpanded && (
        <UiButton
          icon={ArrowIcon}
          onClick={() => setIsExpanded(true)}
          className={styles.commentsList__btnMore}
        >
          Все комментарии
        </UiButton>
      )}
    </div>
  );
};
