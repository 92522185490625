import { Notification, NotificationHeaderProps, RouterLink } from '@vkph/components';
import { UiButton, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { LongreadParams } from '@vkph/common/types/longread';
import { NotificationStatuses, UTCDate } from '@vkph/common/types/models';
import { parseMentionsToPlainText } from '@vkph/common/utils';

import { NotificationTargetTypes } from './constants';

export type NotificationTargetParams = { scrollToComments: boolean };

type NotificationTargetPage = {
  targetType: NotificationTargetTypes.Longread;
  targetDescription: string;
  targetParams: LongreadParams & NotificationTargetParams;
};

type NotificationTargetLongread = {
  targetType: NotificationTargetTypes.Page;
  targetDescription: string;
  targetName: string;
  targetUrl?: string;
};

type NotificationTarget = NotificationTargetPage | NotificationTargetLongread;

type NotificationCommentBase = {
  status: NotificationStatuses;
  commentText: string;
  replyText?: string;
  createdAt: UTCDate;
  disabled?: boolean;
};

type NotificationCommentProps = NotificationCommentBase & NotificationTarget & NotificationHeaderProps;

export const NotificationComment: FC<NotificationCommentProps> = (props) => {
  const {
    status,
    commentText,
    replyText,
    createdAt,
    targetType,
    targetDescription,
    disabled,
    ...notificationHeaderProps
  } = props;

  const { openLongread } = useLongreadNavigation();

  const NotificationCommentTarget: FC = () => {
    if (targetType === NotificationTargetTypes.Longread) {
      const { targetParams } = props;

      return (
        <UiButton
          label="Посмотреть"
          type="primary"
          onClick={() => openLongread(targetParams)}
          disabled={disabled}
        />
      );
    }

    if (targetType === NotificationTargetTypes.Page) {
      const { targetName, targetUrl } = props;

      if (!targetUrl) {
        return null;
      }

      return (
        <RouterLink to={targetUrl} disabled={disabled}>
          {targetName}
        </RouterLink>
      );
    }

    return null;
  };

  return (
    <Notification status={status}>
      <Notification.Header {...notificationHeaderProps} />
      <Notification.Body>
        <UiSpace direction="vertical" full>
          {replyText && (
            <>
              <UiTruncateMarkup lines={2} truncate tooltipProps={{ title: replyText }}>
                {parseMentionsToPlainText(replyText)}
              </UiTruncateMarkup>
              <UiTypography.Text type="secondary">На ваш комментарий:</UiTypography.Text>
            </>
          )}
          <UiTruncateMarkup lines={2} truncate tooltipProps={{ title: commentText }}>
            {parseMentionsToPlainText(commentText)}
          </UiTruncateMarkup>
          <UiTypography.Text type="secondary">{targetDescription}</UiTypography.Text>
          <NotificationCommentTarget />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
