import { Empty, EmptyProps as UiEmptyProps } from 'antd';
import React, { FC, ReactNode } from 'react';

import { useTheme } from '../../providers/theme';
import { UiIcon } from '../icon';
import { UiImage } from '../image';
import styles from './UiEmpty.scss';
import { EMPTY_STATE_SVG_DEFAULT, UiEmptyFeed } from './feed/UiEmptyFeed';
import { UiEmptyFrame } from './frame/UiEmptyFrame';

type UiEmptyComposition = {
  Feed: typeof UiEmptyFeed;
  Frame: typeof UiEmptyFrame;
};

export const UiEmpty: FC<UiEmptyProps> & UiEmptyComposition = (props) => {
  const [{ images: themeImages }] = useTheme();

  const emptyImageSrc = themeImages.emptyState;

  let IconComponent: ReactNode = <UiIcon component={EMPTY_STATE_SVG_DEFAULT} width={120} height={104} />;

  if (emptyImageSrc) {
    IconComponent = <UiImage wrapperClassName={styles.empty__image} width={120} src={emptyImageSrc} />;
  }

  return <Empty image={IconComponent} {...props} />;
};

UiEmpty.Feed = UiEmptyFeed;
UiEmpty.Frame = UiEmptyFrame;
