import { BreadcrumbHistory, TagMentionSearch } from '@vkph/components';
import {
  UiBreadcrumbItemType,
  UiCol,
  UiFileDirection,
  UiIcon,
  UiIconLabelProps,
  UiRow,
  UiSelect,
  UiTypography,
  UiTypographyTextTypes,
} from '@vkph/ui';
import React, { FC, useMemo, useState } from 'react';

import { useFileStorageDisplayType } from '@vkph/common/providers';
import { TagRelationObjectType } from '@vkph/common/types/models';
import { getBreadcrumbRoutesOverflow } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import TaskCardSvg from '@vkph/ui/svg/task-card.svg';
import WidgetsSvg from '@vkph/ui/svg/widgets.svg';

import { FileListManagerNavigationProps } from '../FileListManagerNavigation';

export const FileListManagerNavigationDesktop: FC<FileListManagerNavigationProps> = (props) => {
  const { fileStorageRootId, routes, onSearch, onClear } = props;
  const [searchText, setSearchText] = useState('');
  const [displayType, setDisplayType] = useFileStorageDisplayType();

  const { spaceXL, spaceM } = useSpace();
  const { padding } = usePaddingStyle([spaceXL]);

  const onClearHandler = () => {
    setSearchText('');
    onClear?.();
  };

  const DirectionSelectOptionLabel: FC<UiIconLabelProps> = (labelProps) => {
    return <UiIcon.Label type={UiTypographyTextTypes.Secondary} width={20} height={20} {...labelProps} />;
  };

  const directionOptions = useMemo(
    () => [
      {
        value: UiFileDirection.Horizontal,
        label: <DirectionSelectOptionLabel component={TaskCardSvg} label="Таблица" />,
      },
      {
        value: UiFileDirection.Vertical,
        label: <DirectionSelectOptionLabel component={WidgetsSvg} label="Плитка" />,
      },
    ],
    [],
  );

  const breadcrumbRoutes = useMemo<UiBreadcrumbItemType[]>(
    () => getBreadcrumbRoutesOverflow({ routes }),
    [routes],
  );

  return (
    <UiRow align="middle" justify="space-between" style={{ padding }}>
      <UiCol span={8} xxl={14}>
        {routes?.length === 1 && (
          <UiTypography.Title style={{ margin: 0 }} level={2}>
            Файловое хранилище
          </UiTypography.Title>
        )}
        {routes && routes?.length > 1 && (
          <BreadcrumbHistory style={{ padding: 0 }} items={breadcrumbRoutes} />
        )}
      </UiCol>
      <UiCol span={16} xxl={10}>
        <UiRow wrap={false} gutter={spaceM}>
          <UiCol>
            <UiSelect
              style={{ width: 136 }}
              defaultValue={displayType}
              onChange={setDisplayType}
              optionLabelProp="label"
              options={directionOptions}
            />
          </UiCol>
          <UiCol flex="1 1 100%">
            <TagMentionSearch
              size="middle"
              value={searchText}
              onSearch={onSearch}
              onChange={setSearchText}
              onClear={onClearHandler}
              filters={{
                serviceObjects: [
                  {
                    objectId: fileStorageRootId,
                    objectType: TagRelationObjectType.StorageObject,
                  },
                ],
              }}
            />
          </UiCol>
        </UiRow>
      </UiCol>
    </UiRow>
  );
};
