import { UiFlex, UiForm, UiInput, UiSwitch, useSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { MAX_LENGTH_INPUT_250, getCharactersLeft, getMaxLengthRule, requiredRule } from '@vkph/common/utils';

const rules = [getMaxLengthRule(MAX_LENGTH_INPUT_250), requiredRule];

export const BooleanFormFields: FC = () => {
  const { spaceL } = useSpace();

  return (
    <UiFlex vertical gap={spaceL}>
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <>
            <UiForm.Item
              name="trueValueName"
              style={{ marginBottom: 0 }}
              label="Название выбранных ячеек"
              rules={rules}
              extra={getCharactersLeft(getFieldValue('trueValueName')?.length ?? 0, MAX_LENGTH_INPUT_250)}
            >
              <UiInput maxLength={MAX_LENGTH_INPUT_250} />
            </UiForm.Item>
            <UiForm.Item
              name="falseValueName"
              style={{ marginBottom: 0 }}
              label="Название невыбранных ячеек"
              rules={rules}
              extra={getCharactersLeft(getFieldValue('falseValueName')?.length ?? 0, MAX_LENGTH_INPUT_250)}
            >
              <UiInput maxLength={MAX_LENGTH_INPUT_250} />
            </UiForm.Item>
          </>
        )}
      </UiForm.Item>
      <UiForm.Item noStyle name="defaultCellValue" valuePropName="checked" initialValue={false}>
        <UiSwitch label="Сделать все ячейки в столбце выбранными" />
      </UiForm.Item>
    </UiFlex>
  );
};
