import { Notification } from '@vkph/components';
import { UiAvatarProps, UiIcon, UiRow, UiTruncateMarkup, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { ContentSkillModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';
import SkillProSvg from '@vkph/ui/svg/skill-pro.svg';
import SkillTagSvg from '@vkph/ui/svg/skill-tag.svg';

import { getNotificationDescription } from '../../NotificationsDrawer';

interface Props {
  notification: NotificationModel<ContentSkillModel>;
}

export const NotificationSkill: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
  } = notification;
  const { name, approversCount, proveThreshold } = content;
  const IconSvg = approversCount >= proveThreshold ? SkillProSvg : SkillTagSvg;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiRow>
          <UiIcon.Label type={UiTypographyTextTypes.Primary} component={IconSvg} size={32} strong>
            <UiTruncateMarkup truncate>{name}</UiTruncateMarkup>
          </UiIcon.Label>
        </UiRow>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
