import React, { FC } from 'react';

import AttachSvg from '@vkph/ui/svg/attach.svg';
import ClearSvg from '@vkph/ui/svg/clear.svg';
import ErrorSvg from '@vkph/ui/svg/error.svg';
import RefreshSvg from '@vkph/ui/svg/refresh.svg';

import type { UiUploadFileListProps } from '..';
import { useSpace } from '../../../hooks';
import { useTheme } from '../../../providers/theme';
import { getFormattedFileSize } from '../../../utils';
import { UiButton } from '../../button';
import { UiFile } from '../../file';
import { UiFlex } from '../../flex';
import { UiCol, UiRow } from '../../grid';
import { UiIcon } from '../../icon';
import { UiProgress } from '../../progress';
import { UiSpace } from '../../space';
import { UiSpinner } from '../../spinner';
import { UiTruncateMarkup } from '../../truncate-markup';
import { UiTypography, UiTypographyTextTypes } from '../../typography';
import { UiUploadFile, UiUploadFileStatusType } from '../../upload';
import styles from './UiUploadFileListItem.scss';

export enum UiUploadFileType {
  Simple = 'simple',
  Verbose = 'verbose',
  Progress = 'progress',
}

interface UiUploadFileListItemProps extends Omit<UiUploadFileListProps, 'files'> {
  file: UiUploadFile;
}

const getPercent = (total?: number, loaded?: number) => {
  if (total && loaded) {
    return (loaded / total) * 100;
  }

  return 0;
};

const getTime = (timeInSeconds?: number) => {
  if (timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds - minutes * 60 - hours * 3600);

    const hoursLeft = hours ? `${hours} ч.` : '';
    const minutesLeft = minutes ? `${minutes} мин.` : '';
    const secondsLeft = `${seconds || 0} сек.`;

    return `Еще ${hoursLeft} ${minutesLeft} ${secondsLeft}`;
  }

  return null;
};

export const UiUploadFileListItem: FC<UiUploadFileListItemProps> = (props) => {
  const { file, onDelete, onRefetch, type = UiUploadFileType.Simple } = props;
  const { status, name: fileName, uid, size } = file;

  const [{ variables: themeVariables }] = useTheme();

  const fileSize = getFormattedFileSize(size);
  const { spaceXS, spaceM } = useSpace();
  const isUploadingStatus = (statusType: UiUploadFileStatusType) => status === statusType;

  const isUploading = isUploadingStatus(UiUploadFileStatusType.Uploading);
  const isError = isUploadingStatus(UiUploadFileStatusType.Error);
  const isUploaded =
    isUploadingStatus(UiUploadFileStatusType.Done) || isUploadingStatus(UiUploadFileStatusType.Success);
  const isShowDefaultDelete = onDelete && !(type === UiUploadFileType.Progress);
  const isShowError = (type === UiUploadFileType.Simple || type === UiUploadFileType.Progress) && file.error;

  return (
    <UiSpace direction="vertical" full size={spaceXS} className={styles.uiUploadFileListItem}>
      <UiRow justify="space-between" align="middle" wrap={false}>
        <UiCol span={24}>
          {type === UiUploadFileType.Progress && (
            <UiRow gutter={spaceM} justify="space-between" align="middle" wrap={false}>
              <UiCol flex={1}>
                <UiFile
                  size="middle"
                  title={
                    <UiTypography.Text
                      type={isError ? UiTypographyTextTypes.Danger : UiTypographyTextTypes.Primary}
                    >
                      <UiTruncateMarkup truncate>{file.name}</UiTruncateMarkup>
                    </UiTypography.Text>
                  }
                  fileName={file.name}
                  subtitle={fileSize}
                />
              </UiCol>
              {isError && (
                <UiCol>
                  <UiButton
                    type="link"
                    icon={<UiIcon width={20} height={20} component={RefreshSvg} />}
                    style={{ marginRight: spaceXS }}
                    onClick={() => onRefetch?.(file.uid)}
                    label="Повторить"
                  />
                </UiCol>
              )}
              {Boolean(file?.progressEvent?.estimated) && (
                <UiCol>
                  <UiTypography.Text type="secondary">
                    {getTime(file?.progressEvent?.estimated)}
                  </UiTypography.Text>
                </UiCol>
              )}
              <UiCol>
                <UiFlex gap={spaceXS} align="center">
                  <UiProgress
                    size={32}
                    type="circle"
                    strokeColor={themeVariables.colorBrand}
                    percent={getPercent(file?.progressEvent?.total, file?.progressEvent?.loaded)}
                    onUploading={() => onDelete?.(uid)}
                  />
                </UiFlex>
              </UiCol>
            </UiRow>
          )}
          {type === UiUploadFileType.Simple && (
            <>
              {isUploading && (
                <UiSpace size={spaceXS}>
                  <UiSpinner size="default" />
                  <UiTypography.Text>{fileName}</UiTypography.Text>
                </UiSpace>
              )}
              {isError && (
                <UiRow justify="space-between" wrap={false} style={{ width: '100%' }}>
                  <UiIcon.Label type={UiTypographyTextTypes.Danger} component={ErrorSvg}>
                    <UiTruncateMarkup truncate>{fileName}</UiTruncateMarkup>
                  </UiIcon.Label>
                  <UiButton
                    type="link"
                    icon={<UiIcon width={20} height={20} component={RefreshSvg} />}
                    style={{ marginRight: spaceXS }}
                    onClick={() => onRefetch?.(file.uid)}
                    label="Повторить"
                  />
                </UiRow>
              )}

              {isUploaded && (
                <UiIcon.Label type={UiTypographyTextTypes.Primary} component={AttachSvg}>
                  <UiTruncateMarkup truncate>{fileName}</UiTruncateMarkup>
                </UiIcon.Label>
              )}
            </>
          )}
          {type === UiUploadFileType.Verbose && (
            <UiFile
              title={
                <UiTypography.Text strong>
                  {isError && `Ошибка загрузки: `}
                  {fileName}
                </UiTypography.Text>
              }
              subtitle={fileSize}
              fileName={fileName}
              loading={isUploadingStatus(UiUploadFileStatusType.Uploading)}
            />
          )}
        </UiCol>
        <UiCol>
          {isShowDefaultDelete && (
            <UiButton
              size="small"
              type="link-secondary"
              onClick={() => onDelete(uid)}
              icon={<UiIcon component={ClearSvg} width={20} height={20} />}
            />
          )}
        </UiCol>
      </UiRow>
      {isShowError && (
        <UiTypography.Footnote type={UiTypographyTextTypes.Danger}>
          <UiTruncateMarkup truncate lines={2}>
            {file.error}
          </UiTruncateMarkup>
        </UiTypography.Footnote>
      )}
    </UiSpace>
  );
};
