import { SiteId, SitePageId, SiteServiceId, SiteServiceSlug, SiteSlug } from '../types/models';

export const sitectorEndpoints = {
  sites: () => '/api/sitector/sites/',
  sitesPublished: () => '/api/sitector/sites/published/',
  sitesEntryId: (entryId: SiteId) => `/api/sitector/sites/${entryId}/`,
  sitesSlugEntrySlug: (entrySlug: SiteSlug) => `/api/sitector/sites/slug/${entrySlug}/`,
  sitesEntryIdPublish: (entryId: SiteId) => `/api/sitector/sites/${entryId}/publish/`,
  sitesEntryIdUnpublish: (entryId: SiteId) => `/api/sitector/sites/${entryId}/unpublish/`,
  authors: () => `/api/sitector/authors/`,
  sitesEntryIdServices: (entryId: SiteId) => `/api/sitector/sites/${entryId}/services/`,
  sitesEntryIdServicesServiceObjectId: (entryId: SiteId, serviceObjectId: SiteServiceId) =>
    `/api/sitector/sites/${entryId}/services/${serviceObjectId}/`,
  sitesEntryIdNavbar: (entryId: SiteId) => `/api/sitector/sites/${entryId}/navbar/`,
  sitesSlugEntrySlugNavbar: (entrySlug: SiteSlug) => `/api/sitector/sites/slug/${entrySlug}/navbar/`,
  sitesEntryIdPages: (entryId: SiteId) => `/api/sitector/sites/${entryId}/pages/`,
  sitesPagesPageId: (pageId: SitePageId) => `/api/sitector/pages/${pageId}/`,
  pagesSlugPageSlug: (pageSlug: SiteServiceSlug) => `/api/sitector/pages/slug/${pageSlug}/`,
  sitesSlugSiteSlugPages: (siteSlug: SiteSlug) => `/api/sitector/sites/slug/${siteSlug}/pages/`,
  sitesEntryIdPagesPageIdFavorite: (pageId: SitePageId) => `/api/sitector/pages/${pageId}/favorite/`,
  sitesFavorites: () => '/api/sitector/pages/favorite/',
  sitesRoles: () => `/api/sitector/sites/roles/`,
  sitesEntryIdRoles: (entryId: SiteId) => `/api/sitector/sites/${entryId}/roles/`,
  pagesRoles: () => '/api/sitector/pages/roles/',
  pagesEntryIdRoles: (entryId: SiteId) => `/api/sitector/pages/${entryId}/roles/`,
  sitesEntryIdPagesAuthors: (entryId: SiteId) => `/api/sitector/sites/${entryId}/pages/authors/`,
};
