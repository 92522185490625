import { notification, UiButton, UiButtonProps, UiIcon } from '@vkph/ui';
import React, { FC } from 'react';

import { useClipboard } from '@vkph/ui/hooks';
import ShareSvg from '@vkph/ui/svg/share.svg';

interface ShareButtonProps extends Omit<UiButtonProps, 'type' | 'onClick' | 'icon' | 'href'> {
  value?: string;
}

export const ShareButton: FC<ShareButtonProps> = (props) => {
  const {
    style = { marginLeft: 'auto', height: 'auto' },
    value = window.location.href,
    ...buttonProps
  } = props;

  const { setCopied } = useClipboard(value, {
    onSuccess: () => notification.success({ message: 'Ссылка скопирована' }),
  });

  return (
    <UiButton
      type="link-secondary"
      onClick={setCopied}
      icon={<UiIcon component={ShareSvg} width={20} height={20} />}
      style={style}
      {...buttonProps}
    />
  );
};
