import { Calendar } from '@vkph/components';
import { format, differenceInMinutes, isSameDay, endOfDay, set } from 'date-fns';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage, useLongreadLink } from '@vkph/common/hooks';
import { GetCalendarEventsStorage, GetCalendarSelectedStorage } from '@vkph/common/store/calendar';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { LongreadTypes } from '@vkph/common/types';
import { CalendarEventModel, CalendarEventType, CalendarUserStatus } from '@vkph/common/types/models';
import { convertMinutesToPx, getFormattedTimeWithOptionalDate } from '@vkph/common/utils';

import { HIDDEN_HOUR } from '../../constants';
import { AppearanceTooltip } from '../../tooltip';
import { CalendarEventPosition } from '../../types';

const COMPENSATION_SEPARATOR_PX = 43;
const MIN_HEIGHT_MINUTES = 15;
const LINE_HEIGHT_MINUTES = 10;
const LINE_HEIGHT_MINUTES_OFFSET = 5;

type ColumnEventProps = {
  columnDate: Date;
  event: CalendarEventModel;
  position: CalendarEventPosition;
  isLastWeekDay?: boolean;
  isDayView?: boolean;
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
};

export const ColumnEvent: FC<ColumnEventProps> = (props) => {
  const { columnDate, event, position, isLastWeekDay, isDayView, eventsStorage, selectedStorage } = props;
  const { since, till, summary, eventType, myStatus, place, link } = event;

  const navigate = useNavigate();
  const { to: linkToEventLongread } = useLongreadLink({ type: LongreadTypes.Events, id: event.id });

  const sinceDate = new Date(since);
  const tillDate = new Date(till);
  const startDay = set(new Date(columnDate), { hours: HIDDEN_HOUR, minutes: 0 });

  const { refetchWithLastParams } = useAbstractStorage(eventsStorage.storage);
  const { isHiddenEarlyTimeSectionStore } = selectedStorage;
  const isHiddenEarlyTimeSection = useStore(isHiddenEarlyTimeSectionStore);

  const tillDateView = isSameDay(tillDate, columnDate) ? tillDate : endOfDay(columnDate);
  const isHiddenEvent = isHiddenEarlyTimeSection && tillDateView <= startDay;

  const sinceEventDate = isSameDay(sinceDate, columnDate) ? sinceDate : columnDate;
  const sinceDateView = isHiddenEarlyTimeSection && sinceEventDate < startDay ? startDay : sinceEventDate;

  const { column, totalColumns, colspan } = position;
  const isEventPassed = differenceInMinutes(Date.now(), tillDateView) >= 0;
  const isEventUnconfirmed = myStatus === CalendarUserStatus.NeedAction;
  const isEventMaybe = myStatus === CalendarUserStatus.Maybe;

  const isTopCompensation = sinceDateView < startDay;
  const isDurationCompensation = isTopCompensation && tillDateView > startDay;
  const topCompensationHeight = isTopCompensation ? -COMPENSATION_SEPARATOR_PX : 0;
  const durationCompensationHeight = isDurationCompensation ? COMPENSATION_SEPARATOR_PX : 0;

  const topInMinutes = differenceInMinutes(sinceDateView, startDay);
  const heightInMinutes = differenceInMinutes(tillDateView, sinceDateView);
  const displayedHeightInMinutes = Math.max(MIN_HEIGHT_MINUTES, heightInMinutes);

  const height = convertMinutesToPx(displayedHeightInMinutes, durationCompensationHeight);

  const widthStep = 100 / totalColumns;
  const left = `${column * widthStep}%`;
  const width = `${colspan * widthStep}%`;
  const isLeft = column === 0;
  const isRight = column === totalColumns - 1;

  const linesCount = Math.floor(
    (displayedHeightInMinutes - LINE_HEIGHT_MINUTES_OFFSET) / LINE_HEIGHT_MINUTES,
  );

  const isOneLine = linesCount === 1;

  const timeStartString = format(sinceDate, 'HH:mm');

  const title = isOneLine ? timeStartString : getFormattedTimeWithOptionalDate(sinceDate, columnDate);
  const titleSuffix = !isOneLine ? `– ${getFormattedTimeWithOptionalDate(tillDate, columnDate)}` : '';

  const style: React.CSSProperties = {
    top: convertMinutesToPx(topInMinutes, topCompensationHeight),
    left,
    width,
    height,
    marginLeft: isLeft ? 0 : undefined,
    marginRight: isRight ? 0 : undefined,
  };

  const onOpenEventModal = () => {
    if (eventType === CalendarEventType.Activity) {
      navigate(linkToEventLongread);
    } else {
      openGlobalModal(GlobalModalNames.CalendarEventDetail, { event, onSuccess: refetchWithLastParams });
    }
  };

  if (isHiddenEvent) {
    return null;
  }

  return (
    <AppearanceTooltip
      isLastWeekDay={isLastWeekDay}
      event={event}
      columnDate={columnDate}
      eventsStorage={eventsStorage}
    >
      <Calendar.CardTask
        eventType={eventType}
        title={title}
        titleSuffix={titleSuffix}
        isHasPlace={Boolean(place)}
        isHasBroadcastLink={Boolean(link)}
        subtitle={summary}
        onClick={onOpenEventModal}
        linesCount={linesCount}
        isEventPassed={isEventPassed}
        isEventUnconfirmed={isEventUnconfirmed}
        isEventMaybe={isEventMaybe}
        isLargeGap={isDayView}
        style={style}
      />
    </AppearanceTooltip>
  );
};
