import { UploadDraggerArea } from '@vkph/components';
import {
  message,
  UiButton,
  UiForm,
  UiModal,
  UiSpace,
  UiTypography,
  UiUploadFile,
  UiUploadFileList,
  UiUploadFileType,
  UiUploadOriginFile,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';

import { useUnmount } from '@vkph/common/hooks';
import { transferFileStorageEntriesEffect } from '@vkph/common/store/filestorage';
import {
  closeGlobalModal,
  GlobalModalNames,
  GlobalModalsStorePayloads,
} from '@vkph/common/store/global-modals';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { FileUploadAccepts, getErrorResponseMessage } from '@vkph/common/utils';
import { UploadFile } from '@vkph/common/utils/effector';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { FilesProgress } from '../../files/progress';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.FileStorageCreateMultiple]['payload']>;

export const FileStorageCreateMultiple: FC<Props> = (props) => {
  const { onSuccess: onSuccessModal, parent, uploadStore } = props;
  const {
    store: uploadFilesStore,
    uploadFilesEvent,
    refetchFileEvent,
    removeFilesEvent,
    resetFilesEvent,
  } = uploadStore;
  const [form] = UiForm.useForm();
  const preloadedFiles = useStore(uploadFilesStore);
  const preloadedUiFiles = useMemo<UiUploadFile[]>(() => {
    return preloadedFiles.map(
      (file) =>
        ({
          ...file.fileData,
          name: file.fileData.name,
          status: file.status,
          error: file.errorMessage,
          size: file.fileData.size,
          progressEvent: file?.progressEvent,
        } satisfies UiUploadFile),
    );
  }, [preloadedFiles]);

  const isTransferFilesPending = useStore(transferFileStorageEntriesEffect.pending);

  useUnmount(resetFilesEvent);

  const onCloseModal = () => closeGlobalModal(GlobalModalNames.FileStorageCreateMultiple);

  const onFinish = () => {
    const isAllFilesUploaded = !preloadedUiFiles.some((file) => file.status === 'uploading');

    if (isAllFilesUploaded) {
      transferFileStorageEntriesEffect({
        destination: parent,
        objectIds: preloadedUiFiles.map((file) => file.uid),
      })
        .then(() => {
          message.success('Файлы успешно загружены');
          onSuccessModal?.();
          onCloseModal();
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось загрузить файлы')));
    }
  };

  const isSaveDisabled = useMemo(() => {
    return preloadedUiFiles.some((file) => {
      return file.status === 'uploading' || file.status === 'error';
    });
  }, [preloadedUiFiles]);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      uploadFilesEvent({ filesToUpload: uploadFileList, appendData: true });
    },
    [uploadFilesEvent],
  );

  const onPickFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      uploadFiles(filesToUpload);
    },
    [uploadFiles],
  );

  const onRemoveFile = useCallback(
    (fileId: FileStorageEntryId) => {
      const file = preloadedFiles.find((preloadedFile) => preloadedFile.fileData.uid === fileId);

      if (file) {
        removeFilesEvent([file.key]);
      }
    },
    [removeFilesEvent, preloadedFiles],
  );

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузка файлов')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm size="large" onFinish={onFinish} form={form}>
          <UiSpace direction="vertical" size={8} full>
            <UiTypography.Text>Файлы</UiTypography.Text>
            <UploadDraggerArea
              multiple
              onPickFiles={onPickFiles}
              accept={FileUploadAccepts.All}
              description="Максимальный размер файла: 2 Гб"
              compact
            />
            <FilesProgress files={preloadedUiFiles} />
            <UiUploadFileList
              type={UiUploadFileType.Progress}
              files={preloadedUiFiles}
              onDelete={onRemoveFile}
              onRefetch={refetchFileEvent}
            />
          </UiSpace>
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Сохранить"
            onClick={form.submit}
            size="large"
            type="primary"
            disabled={isSaveDisabled}
            loading={isTransferFilesPending}
          />
          <UiButton label="Отмена" onClick={onCloseModal} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
