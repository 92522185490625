import { UiButton, UiButtonProps, UiIcon, notification } from '@vkph/ui';
import React, { FC } from 'react';

import { useClipboard } from '@vkph/ui/hooks';
import CopySvg from '@vkph/ui/svg/copy.svg';

interface ClipboardButtonProps extends Omit<UiButtonProps, 'value'> {
  value: string;
  successMessage?: string;
  successDuration?: number;
}

export const ClipboardButton: FC<ClipboardButtonProps> = React.memo(
  ({ value, successMessage = 'Скопировано', onClick, ...buttonProps }) => {
    const { setCopied } = useClipboard(value, {
      onSuccess: () => notification.success({ message: successMessage }),
    });

    const onButtonClick: UiButtonProps['onClick'] = (event) => {
      event.preventDefault();
      event.stopPropagation();

      setCopied();
      onClick?.(event);
    };

    return (
      <UiButton
        type="link-secondary"
        onClick={onButtonClick}
        icon={<UiIcon component={CopySvg} width={20} height={20} />}
        {...buttonProps}
      />
    );
  },
);
