import { Notification, RouterLink } from '@vkph/components';
import {
  UiAvatarProps,
  UiButton,
  UiSpace,
  UiTypography,
  UiTypographySymbol,
  UiTypographySymbolName,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks/useAbstractStorage';
import { getProfileStorage } from '@vkph/common/store/profile';
import { IdeaModel, IdeaStatusTypes, NotificationModel, NotificationTypes } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { isNotificationSomeOfTypes } from '../../../../../types/guards/notification-guards';
import { getNotificationDescription } from '../../../NotificationsDrawer';

type Props = {
  notification: NotificationModel<IdeaModel>;
};

export const NotificationIdeaDefault: FC<Props> = (props) => {
  const { notification } = props;
  const {
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    image: initiatorAvatar,
  } = notification;
  const { name, id, status, statusDescription, responsible } = content;

  const responsibleProfileStorage = useMemo(getProfileStorage, []);
  const { data: responsibleProfileData } = useAbstractStorage(responsibleProfileStorage.storage, {
    autoFetchAndRefetch: Boolean(responsible),
    autoFetchParams: { userId: responsible?.keycloakId || '' },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const navigate = useNavigate();

  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: initiatorAvatar } satisfies UiAvatarProps;
  const headerLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const ideaLink = getRoutePath(RouteNames.Idea, { id });

  const description = useMemo(() => {
    if (isNotificationSomeOfTypes(notification, [NotificationTypes.IdeaSetResponsible])) {
      if (responsibleProfileData) {
        return (
          <>
            <RouterLink to={getRoutePath(RouteNames.Profile, { id: responsibleProfileData.id })}>
              {getFullNameWithoutPatronymic(responsibleProfileData.fullName)}
            </RouterLink>
            <UiTypographySymbol name={UiTypographySymbolName.NBSP} />
            назначен(а) ответственным за реализацию идеи
          </>
        );
      }

      return `Назначен ответственный за реализацию идеи`;
    }

    return getNotificationDescription(notification);
  }, [notification, responsibleProfileData]);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={headerLink}
        title={title}
        subtitle={description}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <RouterLink to={ideaLink}>{name}</RouterLink>
          {status === IdeaStatusTypes.Declined && statusDescription && (
            <UiTypography.Text>{statusDescription}</UiTypography.Text>
          )}
          {!isNotificationSomeOfTypes(notification, [NotificationTypes.IdeaRequiredReview]) && (
            <UiButton type="primary" label="Посмотреть" onClick={() => navigate(ideaLink)} />
          )}
          {isNotificationSomeOfTypes(notification, [NotificationTypes.IdeaRequiredReview]) && (
            <UiButton type="primary" onClick={() => navigate(ideaLink)} label="Рассмотреть" />
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
