import { ThemeConfig } from 'antd';

import { ThemeOptions } from './types';
import {
  layoutSizes,
  spaceSizes,
  themeFonts,
  themeInput,
  themeSizes,
  themeWeights,
  themePalette,
} from './variables';

export const getAntTheme = (theme: ThemeOptions): ThemeConfig => ({
  hashed: false,
  token: {
    fontFamily: themeFonts.fontFamily,
    fontSize: parseFloat(themeSizes.heading4FontSize),
    fontSizeSM: parseFloat(themeSizes.heading4FontSize),
    fontSizeLG: parseFloat(themeSizes.heading4FontSize),
    lineHeight: parseFloat(themeSizes.lineHeightBase),
    borderRadius: parseFloat(themeSizes.borderRadiusBase),

    fontSizeHeading2: parseFloat(themeSizes.heading2FontSize),
    fontSizeHeading3: parseFloat(themeSizes.heading3FontSize),
    fontSizeHeading4: parseFloat(themeSizes.heading4FontSize),
    fontSizeHeading5: parseFloat(themeSizes.heading5FontSize),

    colorPrimary: theme.variables.colorBrand,
    colorFillAlter: theme.variables.colorBgPrimary,
    colorBgBase: theme.variables.colorBgPrimary,
    colorBgLayout: theme.variables.colorBgSecondary,
    colorBgTextHover: theme.variables.colorBrand08,
    colorPrimaryHover: theme.variables.colorHoverOnBrand,
    colorLink: theme.variables.colorLinkBrand,
    colorTextBase: theme.variables.colorTextPrimary,

    controlHeight: parseFloat(themeSizes.heightBase),
    controlHeightSM: parseFloat(themeSizes.heightSM),
    controlHeightLG: parseFloat(themeSizes.heightLG),

    screenXSMin: parseFloat(layoutSizes.screenXS),
    screenXS: parseFloat(layoutSizes.screenXS),
    screenXSMax: parseFloat(layoutSizes.screenSM) - 1,
    screenSMMin: parseFloat(layoutSizes.screenSM),
    screenSM: parseFloat(layoutSizes.screenSM),
    screenSMMax: parseFloat(layoutSizes.screenMD) - 1,
    screenMDMin: parseFloat(layoutSizes.screenMD),
    screenMD: parseFloat(layoutSizes.screenMD),
    screenMDMax: parseFloat(layoutSizes.screenLG) - 1,
    screenLGMin: parseFloat(layoutSizes.screenLG),
    screenLG: parseFloat(layoutSizes.screenLG),
    screenLGMax: parseFloat(layoutSizes.screenXL) - 1,
    screenXLMin: parseFloat(layoutSizes.screenXL),
    screenXL: parseFloat(layoutSizes.screenXL),
    screenXLMax: parseFloat(layoutSizes.screenXXL) - 1,
    screenXXLMin: parseFloat(layoutSizes.screenXXL),
    screenXXL: parseFloat(layoutSizes.screenXXL),
  },
  components: {
    // App: {},
    // Affix: {},
    Alert: {
      colorIcon: themePalette.colorIcon,
      colorError: themePalette.colorNegative,
      colorErrorBg: themePalette.colorNegativeBg,
      colorInfo: theme.variables.colorBrand,
      colorInfoBg: themePalette.colorInfoBg,
      colorSuccess: themePalette.colorSuccess,
      colorSuccessBg: themePalette.colorSuccessBg,
      colorWarning: themePalette.colorWarning,
      colorWarningBg: themePalette.colorWarningBg,
      defaultPadding: spaceSizes.spaceM,
      withDescriptionPadding: spaceSizes.spaceM,
    },
    // Anchor: {},
    Avatar: {
      containerSizeSM: parseFloat(themeSizes.avatarSizeSmall),
      containerSize: parseFloat(themeSizes.avatarSizeMiddle),
      containerSizeLG: parseFloat(themeSizes.avatarSizeLarge),
    },
    // BackTop: {},
    // Badge: {},
    Button: {
      fontWeight: themeWeights.btnFontWeight,
      lineWidth: parseFloat(themeSizes.btnBorderWidth),
      lineHeight: parseFloat(themeSizes.lineHeight),
      fontSizeSM: parseFloat(themeSizes.btnFontSize),
      fontSizeLG: parseFloat(themeSizes.btnFontSize),
      paddingInline: parseFloat(themeSizes.btnPaddingHorizontalBase),
    },
    Breadcrumb: {
      itemColor: theme.variables.colorTextSecondary,
      lastItemColor: theme.variables.colorTextPrimary,
      linkColor: theme.variables.colorTextSecondary,
      linkHoverColor: theme.variables.colorHoverOnBrand,
      separatorColor: themePalette.colorIcon,
      separatorMargin: 0,
    },
    // Card:  {},
    // Carousel:  {},
    /** @see не используется */
    // Calendar:  {},
    /** @see не используется */
    // Cascader:  {},
    /** @see не используется */
    // ColorPicker:  {},
    // Collapse:  {},
    Checkbox: {
      colorTextDisabled: theme.variables.colorTextTertiary,
      colorBgContainerDisabled: theme.variables.colorStroke,
      lineHeight: parseFloat(themeSizes.lineHeightBase),
    },
    // DatePicker: {},
    // Descriptions: {},
    // Divider: {},
    // Drawer: {},
    // Dropdown: {},
    // Empty: {},
    // Flex: {},
    // FloatButton: {},
    // Form: {},
    // Grid: {},
    // Image: {},
    Input: {
      paddingInline: parseFloat(themeInput.paddingHorizontalBase),
      paddingInlineSM: parseFloat(themeInput.paddingHorizontalSm),
      paddingInlineLG: parseFloat(themeInput.paddingHorizontalLg),
      paddingBlock: parseFloat(themeInput.paddingVerticalBase),
      paddingBlockSM: parseFloat(themeInput.paddingVerticalSm),
      paddingBlockLG: parseFloat(themeInput.paddingVerticalLg),
    },
    /** @see не используется, у нас свой InputNumber */
    // InputNumber: {},
    // Layout: {},
    List: {
      emptyTextPadding: spaceSizes.spaceXL,
    },
    // Mentions: {},
    Menu: {
      itemBorderRadius: 0,
      controlHeight: parseFloat(themeSizes.heightBase),
      controlHeightLG: parseFloat(themeSizes.heightBase),
      controlHeightSM: parseFloat(themeSizes.heightBase),
      itemHeight: parseFloat(themeSizes.heightBase),
    },
    // Modal: {},
    // Message: {},
    // Notification: {},
    Pagination: {
      itemSizeSM: parseFloat(themeSizes.paginationItemSizeSm),
    },
    // Progress: {},
    // Popover: {},
    // Popconfirm: {},
    // Rate: {},
    // Radio: {},
    // Result: {},
    // Space: {},
    Segmented: {
      colorTextDisabled: theme.variables.colorTextTertiary,
      colorTextLabel: theme.variables.colorTextSecondary,
      itemSelectedColor: theme.variables.colorTextPrimary,
      borderRadius: parseFloat(themeSizes.borderRadiusSmall),
      colorBgBase: theme.variables.colorBgPrimary,
      colorBgLayout: theme.variables.colorStroke,
    },
    Select: {
      optionHeight: parseFloat(themeSizes.selectDropdownHeight),
      optionFontSize: parseFloat(themeSizes.selectDropdownFontSize),
      optionLineHeight: themeSizes.selectDropdownLineHeight,
      singleItemHeightLG: parseFloat(themeSizes.selectSingleItemHeightLg),
    },
    // Skeleton: {},
    // Slider: {},
    Spin: {
      controlHeight: parseFloat(themeSizes.heightSM),
    },
    // Statistic: {},
    Steps: {
      controlHeight: parseFloat(themeSizes.heightSM),
      controlHeightSM: parseFloat(themeSizes.heightSM),
      controlHeightLG: parseFloat(themeSizes.heightSM),
    },
    // Switch: {},
    // Tag: {},
    Tabs: {
      horizontalItemPadding: themeSizes.tabsHorizontalPadding,
      horizontalItemPaddingSM: themeSizes.tabsHorizontalPaddingSm,
      horizontalItemPaddingLG: themeSizes.tabsHorizontalPaddingLg,
      horizontalItemGutter: themeSizes.tabsHorizontalMargin,
    },
    Table: {
      cellPaddingBlock: spaceSizes.spaceXL,
      cellPaddingInline: spaceSizes.spaceXL,
      selectionColumnWidth: parseFloat(themeSizes.selectionColumnWidth),
      headerBorderRadius: parseFloat(themeSizes.borderRadiusLarge),
      headerSplitColor: 'transparent',
    },
    // Tree: {},
    // TreeSelect: {},
    // Typography: {},
    // Timeline: {},
    /** @see не используется */
    // Transfer: {},
    /** @see не используется */
    // Tour: {},
    // Tooltip: {},
    // Upload: {},
    // QRCode: {},
  },
});
