import { UiFileDirection, UiSegmentedOption } from '@vkph/ui';
import React, { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { FileStorageDisplayType } from '../../../types/models';

type FileStorageDisplayTypeContextValueType = [
  displayType: UiFileDirection,
  setDisplayType?: (displayType: UiFileDirection) => void,
];

export const fileStorageDirectionMap: Record<FileStorageDisplayType, UiFileDirection> = {
  [FileStorageDisplayType.Table]: UiFileDirection.Horizontal,
  [FileStorageDisplayType.Tile]: UiFileDirection.Vertical,
};

export const fileStorageDirectionOptions: UiSegmentedOption<FileStorageDisplayType>[] = [
  { value: FileStorageDisplayType.Tile, label: 'Плитка' },
  { value: FileStorageDisplayType.Table, label: 'Таблица' },
];

export const FileStorageDisplayTypeContext = createContext<FileStorageDisplayTypeContextValueType>([
  UiFileDirection.Vertical,
]);

export const FileStorageDisplayTypeProvider: FC<PropsWithChildren<{ value: UiFileDirection }>> = (props) => {
  const { children, value } = props;
  const [displayType, setDisplayType] = useState(value);

  const contextValue = useMemo<FileStorageDisplayTypeContextValueType>(
    () => [displayType, setDisplayType],
    [displayType],
  );

  return (
    <FileStorageDisplayTypeContext.Provider value={contextValue}>
      {children}
    </FileStorageDisplayTypeContext.Provider>
  );
};
