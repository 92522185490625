import { Avatar, AvatarProps, Badge } from 'antd';
import { ScreenSizeMap } from 'antd/lib/_util/responsiveObserver';
import { AvatarSize as AntAvatarSize } from 'antd/lib/avatar/AvatarContext';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import AvatarUserSvg from '@vkph/ui/svg/avatar-user.svg';

import { variables, useTheme } from '../../providers/theme';
import { getSizeClsBySizeFullName } from '../config-provider';
import styles from './UiAvatar.scss';

export const AVATAR_SIZE_LIMIT = 64;

const ccn = classNames.bind(styles);

export type AvatarSize = Exclude<AntAvatarSize, 'default' | ScreenSizeMap> | 'extraSmall' | 'middle';
export interface UiAvatarProps extends Omit<AvatarProps, 'size'> {
  disabled?: boolean;
  withBadge?: boolean;
  bordered?: boolean;
  size?: AvatarSize;
}

const sizeNumberMap: Record<Exclude<AvatarSize, number>, number> = {
  extraSmall: parseInt(variables.themeSizes.avatarSizeExtraSmall, 10),
  middle: parseInt(variables.themeSizes.avatarSizeMiddle, 10),
  large: parseInt(variables.themeSizes.avatarSizeLarge, 10),
  small: parseInt(variables.themeSizes.avatarSizeSmall, 10),
};

export const getNumberSizeAvatar = (arg: AvatarSize) => {
  if (typeof arg === 'number') {
    return arg;
  }

  return sizeNumberMap[arg];
};

export const UiAvatar: FC<UiAvatarProps> = (props) => {
  const {
    size: avatarSize,
    className,
    withBadge = false,
    bordered = false,
    shape = 'circle',
    disabled,
    src,
    icon,
    ...avatarProps
  } = props;

  const [{ images: themeImages }] = useTheme();
  const currentSize = getNumberSizeAvatar(avatarSize ?? 'middle');
  const size = withBadge ? AVATAR_SIZE_LIMIT : currentSize;
  const sizeCls = getSizeClsBySizeFullName(
    (currentSize >= AVATAR_SIZE_LIMIT ? 'large' : 'small') || 'middle',
  );

  const avatarSrc = useMemo(() => {
    if (!src && !icon) {
      return themeImages?.userAvatar;
    }

    return src;
  }, [src, icon, themeImages?.userAvatar]);

  const Icon = useMemo(() => {
    if (!avatarSrc && !icon) {
      return <AvatarUserSvg height={size} width={size} />;
    }

    return icon;
  }, [icon, avatarSrc]);

  const classNameStyles = {
    [styles.uiAvatar__circle]: shape === 'circle',
    [styles.uiAvatar__square]: shape === 'square',
    [styles.uiAvatar_disabled]: disabled,
    [`${styles.uiAvatar_bordered}-${sizeCls}`]: bordered && sizeCls,
  };

  const AvatarComponent = (
    <Avatar
      style={{ minWidth: size, minHeight: size }}
      {...avatarProps}
      src={avatarSrc}
      icon={Icon}
      shape={shape}
      size={size}
      className={ccn(styles.uiAvatar, classNameStyles, className)}
    />
  );

  return withBadge ? <Badge dot>{AvatarComponent}</Badge> : AvatarComponent;
};
