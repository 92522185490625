import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { SearchParams, SiteModel } from '@vkph/common/types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '@vkph/common/utils';

export const getSearchSitesStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<SiteModel>, SiteModel[], SiteModel[], SearchParams>({
    endpointBuilder: (params) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesPublished(), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
