import classNames from 'classnames';
import React, { createContext, FC, HTMLAttributes, useContext, useMemo } from 'react';

import DragSvg from '@vkph/ui/svg/drag.svg';

import { UiButton } from '../../../button';
import { useDnDSortable } from '../../../dnd';
import { UiDnDSyntheticListenerMapProps } from '../../../dnd/utils';
import { UiFlex } from '../../../flex';
import { UiIcon } from '../../../icon';
import styles from './UiTableDnDRow.scss';

export interface UiTableDnDRowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

interface TableDnDRowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: UiDnDSyntheticListenerMapProps;
}

const TableDnDRowContext = createContext<TableDnDRowContextProps>({});

const UiTableDnDRowDragButton: FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(TableDnDRowContext);

  return (
    <UiFlex justify="flex-end" align="center" style={{ height: '100%' }}>
      <UiButton
        type="link-secondary"
        icon={<UiIcon width={20} height={20} component={DragSvg} />}
        style={{ cursor: 'grab' }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    </UiFlex>
  );
};

export interface UiTableDnDRowComposition {
  DragButton: typeof UiTableDnDRowDragButton;
}

export const UiTableDnDRow: FC<UiTableDnDRowProps> & UiTableDnDRowComposition = (props) => {
  const { className, 'data-row-key': id } = props;

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, active, over, activeIndex, overIndex } =
    useDnDSortable({
      id,
    });

  const contextValue = useMemo<TableDnDRowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  const dragDirection = overIndex > activeIndex ? 'top' : 'bottom';

  return (
    <TableDnDRowContext.Provider value={contextValue}>
      <tr
        {...props}
        ref={setNodeRef}
        className={classNames(styles.uiTableDndRow, className, {
          [styles.uiTableDndRow_drag]: active && active.id === id,
          [`${styles.uiTableDndRow}_${dragDirection}`]: over && id === over.id && active?.id !== over.id,
        })}
        {...attributes}
      />
    </TableDnDRowContext.Provider>
  );
};

UiTableDnDRow.DragButton = UiTableDnDRowDragButton;
