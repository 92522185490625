import React, { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { useSpace } from '../../hooks';
import { UiSpace, UiSpaceProps } from '../space';
import { UiTruncateMarkup } from '../truncate-markup';
import { UiTypography, UiTypographyTextProps } from '../typography';

export interface UiCellProps extends Pick<UiSpaceProps, 'className' | 'style'> {
  title: ReactNode;
  subtitle?: ReactNode;
  extra?: ReactNode;
  shift?: UiSpaceProps['size'];
  linkComponent?: FC<PropsWithChildren>;
  spaceProps?: Omit<UiSpaceProps, 'onClick' | 'title'>;
  avatar?: ReactNode;
  titleProps?: UiTypographyTextProps;
}

export const UiCell: FC<UiCellProps> = (props) => {
  const { spaceXS, spaceS } = useSpace();
  const {
    title,
    subtitle,
    extra,
    spaceProps: { size, align } = { size: spaceS, align: 'center' },
    shift = spaceXS,
    linkComponent: LinkComponent = Fragment,
    avatar,
    titleProps,
    ...spaceWrapperProps
  } = props;

  return (
    <UiSpace full align={align} size={size} {...spaceWrapperProps}>
      <LinkComponent>{avatar}</LinkComponent>
      <UiSpace align="start" direction="vertical" size={0}>
        <UiSpace align="center" size={shift}>
          <LinkComponent>
            <UiTypography.Text {...titleProps}>
              <UiTruncateMarkup.Atom>{title}</UiTruncateMarkup.Atom>
            </UiTypography.Text>
          </LinkComponent>
          {extra}
        </UiSpace>
        {subtitle && <UiTypography.Text type="secondary">{subtitle}</UiTypography.Text>}
      </UiSpace>
    </UiSpace>
  );
};
