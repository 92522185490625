import { AxiosError } from 'axios';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { createEffect, createEvent, sample } from 'effector';

import { endpoints } from '../../../endpoints';
import { SinceTillFetchParams } from '../../../types';
import {
  CalendarEventModel,
  CalendarReducedEvent,
  CalendarAttendee,
  CalendarEventCreateParams,
  CalendarEventUpdateParams,
  CalendarStatusUpdate,
  CalendarEventPatchParams,
  ParticipateStatus,
  SiteId,
} from '../../../types/models';
import {
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
  getReducedEventsByDate,
  INITIAL_DATE_FORMAT_MASK,
} from '../../../utils';
import { abstractStorageFactory } from '../../../utils/effector';
import {
  refetchOnCreateUpdateMeetingEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  fetchCalendarEvent,
  updateCalendarEvent,
  updateUserStatus,
  updateMembersCalendarEvent,
  addEventToCalendar,
  deleteEventFromCalendar,
} from '../api';

export type EventIdParams = { id: string };

export const fetchEventEffect = createEffect<string, CalendarEventModel, AxiosError>((id) =>
  fetchCalendarEvent(id).then(({ data }) => data),
);

export const addEventToCalendarEffect = createEffect<string, void, AxiosError>((id) =>
  addEventToCalendar(id).then(({ data }) => data),
);

export const deleteEventFromCalendarEffect = createEffect<string, void, AxiosError>((id) =>
  deleteEventFromCalendar(id).then(({ data }) => data),
);

export const createEventEffect = createEffect<CalendarEventCreateParams, CalendarEventModel, AxiosError>(
  (params) => createCalendarEvent(params).then(({ data }) => data),
);

export const updateEventEffect = createEffect<CalendarEventUpdateParams, CalendarEventModel, AxiosError>(
  (params) => updateCalendarEvent(params).then(({ data }) => data),
);

export const deleteEventEffect = createEffect<string, unknown, AxiosError>(deleteCalendarEvent);

export const updateUserStatusEffect = createEffect<CalendarStatusUpdate, unknown, AxiosError>((params) =>
  updateUserStatus(params),
);

export const updateMembersEventEffect = createEffect<
  CalendarEventPatchParams,
  CalendarEventModel,
  AxiosError
>((params) => updateMembersCalendarEvent(params).then(({ data }) => data));

export const getSingleEventStorage = () => {
  const storage = abstractStorageFactory<CalendarEventModel, CalendarEventModel, null, EventIdParams>({
    endpointBuilder: ({ id }) => endpoints.calendar.eventId(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data,
  });

  storage.store.on(
    [updateEventEffect, updateMembersEventEffect],
    (state, { attendeesToAdd, attendeesToRemove }) => {
      let attendees = state.data?.firstAttendees || [];

      if (attendeesToRemove) {
        attendees = attendees.filter(({ userId }) => userId !== attendeesToRemove[0]);
      }

      if (attendeesToAdd) {
        attendees = [...attendees, ...(attendeesToAdd as CalendarAttendee[])];
      }

      return { ...state, data: state.data ? { ...state.data, firstAttendees: attendees } : null };
    },
  );

  storage.store.on([updateEventEffect.doneData, updateMembersEventEffect.doneData], (state, newEvent) => {
    return { ...state, data: newEvent };
  });

  storage.store.on(updateMembersEventEffect.doneData, (state, newEvent) => {
    return { ...state, data: newEvent };
  });

  return { storage };
};

interface GetCalendarEventsParams extends SinceTillFetchParams, EndpointQueryParamsBaseType {
  siteId?: SiteId;
  meetingRoomsIds?: number;
}

type CalendarEventsType = 'personal' | 'permitted' | 'sites';

const isSiteIdNotExist = (type: CalendarEventsType, siteId: string | undefined): siteId is undefined =>
  type === 'sites' && !siteId;

const getCalendarEndpoints = (type: CalendarEventsType) => (params: GetCalendarEventsParams) => {
  const { siteId } = params;

  if (isSiteIdNotExist(type, siteId)) {
    throw new Error('siteId is required when type is "sites".');
  }

  const calendarEndpoints: Record<CalendarEventsType, ReturnType<typeof buildEndpointWithQueryParams>> = {
    personal: endpoints.calendar.personalEvents(),
    permitted: endpoints.calendar.eventsPermitted(),
    sites: endpoints.calendar.eventsSitesSiteId(siteId),
  };

  return buildEndpointWithQueryParams(calendarEndpoints[type], params);
};

export const getCalendarEventsStorage = (type: CalendarEventsType) => {
  const storage = abstractStorageFactory<
    CalendarEventModel[],
    CalendarReducedEvent,
    CalendarReducedEvent,
    GetCalendarEventsParams
  >({
    endpointBuilder: getCalendarEndpoints(type),
    defaultValue: {},
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      getReducedEventsByDate<CalendarEventModel>({
        objects: data.sort((a, b) =>
          isBefore(new Date(a.since), new Date(b.since)) ||
          (a.since === b.since && isBefore(new Date(b.till), new Date(a.till)))
            ? -1
            : 1,
        ),
      }),
  });

  const addNotificationEvent = createEvent<CalendarEventModel>();

  storage.store
    .on(addNotificationEvent, (state, newEvent) => {
      const eventKey = format(new Date(newEvent.since), INITIAL_DATE_FORMAT_MASK);
      const { since, till } = storage.getLastRequestParams();
      const isNewEventOnCurrentView = areIntervalsOverlapping(
        { start: new Date(newEvent.since), end: new Date(newEvent.till) },
        { start: new Date(since), end: new Date(till) },
      );

      return isNewEventOnCurrentView
        ? {
            ...state,
            data: { ...state.data, [eventKey]: [...(state.data[eventKey] || []), newEvent] },
          }
        : state;
    })
    .on([updateEventEffect.doneData, updateMembersEventEffect.doneData], (state, newEvent) => {
      const eventKey = format(new Date(newEvent.since), INITIAL_DATE_FORMAT_MASK);
      const eventIndex = state.data[eventKey].findIndex(({ id }) => id === newEvent.id);
      const newEventsOnKey = [...state.data[eventKey]];

      newEventsOnKey[eventIndex] = newEvent;

      return {
        ...state,
        data: { ...state.data, [eventKey]: newEventsOnKey },
      };
    });

  sample({
    clock: [updateUserStatusEffect.done, updateMembersEventEffect.done, refetchOnCreateUpdateMeetingEvent],
    source: storage.store,
    target: createEffect(storage.refetchWithLastParams),
  });

  return {
    storage,
    addNotificationEvent,
  };
};

export type GetCalendarEventsStorage = ReturnType<typeof getCalendarEventsStorage>;

export const getIsUserParticipatesStorage = () => {
  const storage = abstractStorageFactory<ParticipateStatus, ParticipateStatus, null, EventIdParams>({
    endpointBuilder: ({ id }) => endpoints.calendar.eventIdIsUserParticipates(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const singleEventStorage = getSingleEventStorage();
