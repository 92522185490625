import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import DragSvg from '@vkph/ui/svg/drag.svg';

import { AbsolutePlacement, useAbsolutePlacementShift, useSpace } from '../../../../hooks';
import { UiButton } from '../../../button';
import { useDnDSortable } from '../../../dnd';
import { UiIcon } from '../../../icon';
import styles from './UiTableDnDCell.scss';

export interface UiTableDnDCellProps extends HTMLAttributes<HTMLTableCellElement> {
  cellKey: string;
}

export const UiTableDnDCell: FC<UiTableDnDCellProps> = (props) => {
  const { cellKey: id, className, children, ...otherProps } = props;

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, active, over, activeIndex, overIndex } =
    useDnDSortable({
      id,
    });
  const { spaceL } = useSpace();

  const dragDirection = overIndex > activeIndex ? 'right' : 'left';

  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.CenterRight,
    shift: spaceL,
  });

  return (
    <th
      {...otherProps}
      className={classNames(styles.uiTableDndCell, className, {
        [styles.uiTableDndCell_drag]: active && active.id === id,
        [`${styles.uiTableDndCell}_${dragDirection}`]: over && id === over.id && active?.id !== over.id,
      })}
      ref={setNodeRef}
    >
      {children}

      <UiButton
        type="link-secondary"
        style={placementStyles}
        className={classNames(styles.uiTableDndCell__dragButton, placementCls)}
        icon={<UiIcon width={20} height={20} component={DragSvg} />}
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
      />
    </th>
  );
};
