import { UiFlex, UiForm, UiInput, UiOptionItem, UiSelect, useSpace } from '@vkph/ui';
import React, { FC, useRef } from 'react';

import {
  MAX_LENGTH_INPUT_250,
  MAX_LENGTH_INPUT_500,
  MAX_LENGTH_INPUT_1000,
  MAX_LENGTH_INPUT_2500,
  MAX_LENGTH_INPUT_5000,
  MAX_LENGTH_INPUT_8000,
  getMaxLengthRule,
  getCharactersLeft,
} from '@vkph/common/utils';

export const TextFormFields: FC = () => {
  const { spaceXL } = useSpace();
  const ref = useRef<HTMLDivElement>(null);

  const currentUserOptions: UiOptionItem[] = [
    MAX_LENGTH_INPUT_250,
    MAX_LENGTH_INPUT_500,
    MAX_LENGTH_INPUT_1000,
    MAX_LENGTH_INPUT_2500,
    MAX_LENGTH_INPUT_5000,
    MAX_LENGTH_INPUT_8000,
  ].map((item) => ({
    label: `${item}`,
    value: `${item}`,
  }));

  return (
    <UiFlex gap={spaceXL} vertical ref={ref}>
      <UiForm.Item
        name="maxLength"
        label="Задать максимальное кол-во символов в ячейке"
        style={{ marginBottom: 0 }}
        initialValue={MAX_LENGTH_INPUT_250}
        normalize={(value) => Number(value)}
      >
        <UiSelect getPopupContainer={() => ref.current || document.body} options={currentUserOptions} />
      </UiForm.Item>
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const maxLength = getFieldValue('maxLength');

          return (
            <UiForm.Item
              name="defaultCellValue"
              label="Данные ячейки для вставки во все ячейки столбца"
              style={{ marginBottom: 0 }}
              rules={[getMaxLengthRule(maxLength)]}
              extra={getCharactersLeft(getFieldValue('defaultCellValue')?.length ?? 0, maxLength)}
            >
              <UiInput maxLength={maxLength} />
            </UiForm.Item>
          );
        }}
      </UiForm.Item>
    </UiFlex>
  );
};
