import { Achievement } from '@vkph/components';
import {
  UiButton,
  UiCard,
  UiDivider,
  UiList,
  UiModal,
  UiModalTypes,
  UiSkeleton,
  UiSpace,
  UiTooltip,
} from '@vkph/ui';
import { useStore, useStoreMap } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAbstractStorage, useUnmount } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers';
import { closeGlobalModal, openGlobalModal } from '@vkph/common/store/global-modals';
import { GlobalModalNames } from '@vkph/common/store/global-modals/modal-types';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel, GamificationBadges, GamificationItemTypes } from '@vkph/common/types/models';
import { createArrayMock, getRoutePath, RouteNames } from '@vkph/common/utils';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import ExploreSvg from '@vkph/ui/svg/explore.svg';

import {
  GamificationUserThanksSummaryStorage,
  gamificationUserBadgesStorage,
} from '../../store/gamification';
import { AddBadge } from './add-badge/AddBadge';
import { AddThanks } from './add-thanks/AddThanks';
import { BadgeThanksView } from './badge-thanks-view/BadgeThanksView';
import { GamificationEmpty } from './empty/GamificationEmpty';
import { GamificationList } from './list/GamificationList';
import {
  GamificationModalTypes,
  getChosenUserBadgeStorage,
  getGamificationModalStorage,
  ToggleGamificationModalEvent,
} from './store';

const gamificationMocks = (
  <UiList
    split={false}
    grid={{ gutter: 16 }}
    dataSource={createArrayMock(4, (_, i) => i)}
    renderItem={() => (
      <UiList.Item>
        <UiSkeleton circle loading width={72} height={70} />
      </UiList.Item>
    )}
  />
);

type ModalProps = {
  size: UiModalTypes;
  onClose: () => void;
};

type Props = {
  userThanksStorage: GamificationUserThanksSummaryStorage;
};

export const Gamification: FC<Props> = (props) => {
  const { userThanksStorage } = props;
  const navigate = useNavigate();
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();

  const { storage: userBadgesStorage } = gamificationUserBadgesStorage;
  const { gamificationModalStore, toggleGamificationModalEvent } = useMemo(getGamificationModalStorage, []);
  const { type: modalType, isOpen: isModalOpen } = useStore(gamificationModalStore);

  const isUserActive = useStoreMap(profileFullInfoStorage.storage.store, ({ data }) =>
    Boolean(data?.main?.isActive),
  );

  const toggleGamificationModalByType = useCallback(({ type }: ToggleGamificationModalEvent) => {
    return () => toggleGamificationModalEvent({ type });
  }, []);

  const isActiveGamificationModal = useCallback(
    (key: GamificationModalTypes) => key === modalType,
    [modalType],
  );

  const modalProps: Record<GamificationModalTypes, ModalProps> = useMemo(() => {
    return {
      [GamificationModalTypes.UserBadge]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.UserBadge }),
      },
      [GamificationModalTypes.AddThanks]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks }),
      },
      [GamificationModalTypes.AddBadge]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AddBadge }),
      },
      [GamificationModalTypes.AllThanks]: {
        size: UiModalTypes.Large,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AllThanks }),
      },
    };
  }, [toggleGamificationModalByType]);

  const { chosenUserBadgeStore, updateChosenUserBadgeEvent } = useMemo(getChosenUserBadgeStorage, []);
  const chosenUserBadgeState = useStore(chosenUserBadgeStore);

  const { data: userBadgesItems, loading: isBadgesLoading } = useAbstractStorage(userBadgesStorage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
    autoFetchParams: {
      userId,
    },
  });

  const userBadges = useMemo(() => [...(userBadgesItems?.items || [])], [userBadgesItems]);

  const { data: userThanks, loading: isThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const profile = useCurrentProfile();
  const isCurrentProfileSuperAdmin = Boolean(profile.superAdmin);
  const isOwner = userId === profile.id;

  const userThanksIsEmpty = !isThanksLoading && !userThanks.length;

  const onUserBadgeClick = useCallback((badge: GamificationBadges) => {
    toggleGamificationModalEvent({ type: GamificationModalTypes.UserBadge });
    updateChosenUserBadgeEvent(badge);
  }, []);

  const onOpenRewardProgress = () => {
    navigate(getRoutePath(RouteNames.ProfileRewardsProgress, { id: userId }));
  };

  useEffect(() => {
    if (isActiveGamificationModal(GamificationModalTypes.AddThanks)) {
      const unsubscribe = userThanksStorage.createThanksEffect.doneData.watch((thank) => {
        openGlobalModal(GlobalModalNames.ProfileBadgeThanksView, {
          onClose: () => closeGlobalModal(GlobalModalNames.ProfileBadgeThanksView),
          thank,
        });

        return unsubscribe;
      });
    }
  }, [modalType]);

  useUnmount(userBadgesStorage.resetStoreEvent);

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Header>
        <UiCard.Header.Title>Награды</UiCard.Header.Title>
        <UiSpace size={16} style={{ marginLeft: 'auto' }}>
          {isCurrentProfileSuperAdmin && isUserActive && (
            <UiTooltip placement="top" title="Наградить">
              <UiButton
                type="link-secondary"
                icon={<AddCircleSvg />}
                onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AddBadge })}
              />
            </UiTooltip>
          )}

          <UiTooltip placement="left" title="Перейти в список наград">
            <UiButton onClick={onOpenRewardProgress} type="link-secondary" icon={<ExploreSvg />} />
          </UiTooltip>
        </UiSpace>
      </UiCard.Header>

      <UiCard.Content basePadding={false} style={{ padding: '0 24px 8px' }}>
        {isBadgesLoading && gamificationMocks}
        {((!isBadgesLoading && !userBadges.length) || !isUserActive) && (
          <GamificationEmpty type={GamificationItemTypes.Badge} style={{ marginBottom: 16 }} />
        )}
        {!isBadgesLoading && userBadges.length > 0 && isUserActive && (
          <UiList
            split={false}
            grid={{ gutter: 16, column: 4 }}
            dataSource={userBadges}
            rowKey={({ id }) => id}
            renderItem={(badge) => (
              <UiTooltip title={badge.badge.name}>
                <UiList.Item onClick={() => onUserBadgeClick(badge)} hoverable={false}>
                  <Achievement.Icon type="badge" size="small" src={badge.badge.image} />
                </UiList.Item>
              </UiTooltip>
            )}
          />
        )}
      </UiCard.Content>

      <UiDivider emptyMargin />

      <UiCard.Header>
        <UiCard.Header.Title>Благодарности</UiCard.Header.Title>
        {!isOwner && isUserActive && (
          <UiTooltip placement="left" title="Поблагодарить">
            <UiButton
              style={{ marginLeft: 'auto' }}
              type="link-secondary"
              icon={<AddCircleSvg />}
              onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks })}
            />
          </UiTooltip>
        )}
      </UiCard.Header>

      <UiCard.Content basePadding={false} style={{ padding: '0 24px 24px' }}>
        {isThanksLoading && gamificationMocks}
        {!userThanksIsEmpty && userThanks.length > 0 && isUserActive && (
          <UiList
            split={false}
            grid={{ gutter: 16, column: 4 }}
            dataSource={userThanks}
            rowKey={({ name }) => name}
            renderItem={(thank) => (
              <UiTooltip title={thank.name}>
                <UiList.Item>
                  <Achievement.Icon type="thank" size="small" src={thank.image} />
                </UiList.Item>
              </UiTooltip>
            )}
          />
        )}
        {!userThanksIsEmpty && isUserActive && (
          <UiButton
            type="link"
            label="Все благодарности"
            onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AllThanks })}
          />
        )}
        {(userThanksIsEmpty || !isUserActive) && (
          <GamificationEmpty
            type={isUserActive ? GamificationItemTypes.Thanks : GamificationItemTypes.Badge}
            toggleAddThanksOpen={toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks })}
          />
        )}
      </UiCard.Content>

      <UiModal onClose={modalProps[modalType].onClose} type={modalProps[modalType].size} isOpen={isModalOpen}>
        {isActiveGamificationModal(GamificationModalTypes.AllThanks) && <GamificationList />}
        {isActiveGamificationModal(GamificationModalTypes.AddThanks) && (
          <AddThanks userThanksStorage={userThanksStorage} />
        )}
        {isActiveGamificationModal(GamificationModalTypes.AddBadge) && (
          <AddBadge userBadgesStorage={gamificationUserBadgesStorage} />
        )}
        {isActiveGamificationModal(GamificationModalTypes.UserBadge) && chosenUserBadgeState && (
          <BadgeThanksView badge={chosenUserBadgeState} />
        )}
      </UiModal>
    </UiCard>
  );
};
