import { Modifier, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { getEventCoordinates } from '@dnd-kit/utilities';

import { UiDnDDragEndEvent } from './context/UiDnDContext';

export type { SyntheticListenerMap as UiDnDSyntheticListenerMapProps } from '@dnd-kit/core/dist/hooks/utilities';
export { CSS as DnDCSS } from '@dnd-kit/utilities';
export type DnDModifier = Modifier;

export {
  arrayMove as getDnDArrayMove,
  horizontalListSortingStrategy as dndHorizontalListSortingStrategy,
  verticalListSortingStrategy as dndVerticalListSortingStrategy,
} from '@dnd-kit/sortable';

export {
  restrictToVerticalAxis as dndRestrictToVerticalAxis,
  restrictToParentElement as dndRestrictToParentElement,
} from '@dnd-kit/modifiers';

export const getDnDEventCoordinates = getEventCoordinates;

interface snapDnDItemToCursorOptions {
  horizontalShift?: number;
  placement?: 'left' | 'right';
}

export const snapDnDItemToCursor =
  (options?: snapDnDItemToCursorOptions): Modifier =>
  ({ activatorEvent, draggingNodeRect, transform }) => {
    const { placement = 'left', horizontalShift } = options || {};

    if (draggingNodeRect && activatorEvent) {
      const activatorCoordinates = getDnDEventCoordinates(activatorEvent);
      const shift = horizontalShift || 0;

      if (!activatorCoordinates) {
        return transform;
      }

      const offsetX = activatorCoordinates.x - draggingNodeRect.left;
      const offsetY = activatorCoordinates.y - draggingNodeRect.top;

      const correction = placement === 'left' ? draggingNodeRect.width - shift : shift;

      return {
        ...transform,
        x: transform.x + offsetX - correction,
        y: transform.y + offsetY - draggingNodeRect.height / 2,
      };
    }

    return transform;
  };

export const getDnDDragEndArray = <T>(event: UiDnDDragEndEvent<T>, items: T[]) => {
  const { active, over } = event;
  const activeIndex = active.data?.current?.sortable?.index;
  const overIndex = over?.data?.current?.sortable?.index;

  if (over && activeIndex >= 0 && overIndex >= 0 && activeIndex !== overIndex) {
    return arrayMove(items, activeIndex, overIndex);
  }

  return items;
};

const findDragItemContainer = <T>(
  containers: Record<UniqueIdentifier, T[]>,
  id: UniqueIdentifier,
  itemKey: keyof T,
) => {
  if (id in containers) {
    return id;
  }

  return Object.keys(containers).find((key) => containers[key].find((item) => item[itemKey] === id));
};

export const getDnDDragEndContainersArray = <T>(
  event: UiDnDDragEndEvent<T>,
  containers: Record<UniqueIdentifier, T[]>,
  itemKey: keyof T,
): Record<UniqueIdentifier, T[]> => {
  const { active, over } = event;

  const activeContainer = findDragItemContainer(containers, active.id, itemKey);
  const overContainer = over?.id && findDragItemContainer(containers, over.id, itemKey);

  if (!activeContainer || !overContainer) {
    return containers;
  }

  const activeIndex = containers[activeContainer].findIndex((item) => item[itemKey] === active.id);
  const overIndex = containers[overContainer].findIndex((item) => item[itemKey] === over?.id);

  if (activeIndex !== overIndex || activeContainer !== overContainer) {
    if (activeContainer === overContainer) {
      return {
        ...containers,
        [overContainer]: getDnDDragEndArray(event, containers[overContainer]),
      };
    }

    const currentOverIndex = overIndex < 0 ? 0 : overIndex + 1;

    return {
      ...containers,
      [activeContainer]: [...containers[activeContainer].filter((item) => item[itemKey] !== active.id)],
      [overContainer]: [
        ...containers[overContainer].slice(0, currentOverIndex),
        containers[activeContainer][activeIndex],
        ...containers[overContainer].slice(currentOverIndex, containers[overContainer].length),
      ],
    };
  }

  return containers;
};
