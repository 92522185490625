import type { Rule } from '@vkph/ui';

import { regexpPatterns } from '../regexpPattern';

const URL_WITH_PROTOCOL_PATTERN = new RegExp(
  [regexpPatterns.urlProtocol, regexpPatterns.urlPattern].join(''),
  'i',
);

const URL_WITHOUT_PROTOCOL_PATTERN = new RegExp(
  [regexpPatterns.urlProtocol, '?', regexpPatterns.urlPattern].join(''),
  'i',
);

const ERROR_MESSAGE_URL_RULE = 'Поле должно быть заполнено как URL-адрес';

export const isValidURL = (url: string) =>
  URL_WITH_PROTOCOL_PATTERN.test(url) || URL_WITHOUT_PROTOCOL_PATTERN.test(url);

export const isValidURLWithProtocol = (url: string) => URL_WITH_PROTOCOL_PATTERN.test(url);

export const urlRule: Rule = {
  validator: (_, value?: string) =>
    !value || URL_WITHOUT_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: 'Укажите правильный url',
};

export const validURLRule: Rule = {
  validator: (_, value?: string) =>
    value && URL_WITHOUT_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: ERROR_MESSAGE_URL_RULE,
};

export const validURLWithProtocolRule: Rule = {
  validator: (_, value?: string) =>
    value && URL_WITH_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: ERROR_MESSAGE_URL_RULE,
};

export const validURLWithProtocolWithEmptyRule: Rule = {
  validator: (_, value?: string) =>
    !value || URL_WITH_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: ERROR_MESSAGE_URL_RULE,
};

export const validPartURLRule: Rule = {
  pattern: regexpPatterns.urlSlug,
  message: 'Поле может содержать только латинские символы, цифры и тире',
};
