import { Progress } from 'antd';
import { ProgressProps } from 'antd/es/progress';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { variables } from '../../providers/theme';
import styles from './UiProgress.scss';
import { UiProgressCircle, UiProgressCircleProps } from './circle';

export interface UiProgressProps
  extends ProgressProps,
    Pick<UiProgressCircleProps, 'onUploading' | 'onSuccess' | 'disabled'> {
  withChart?: boolean;
  className?: string;
}

const defaultFormatPercent = (percent: UiProgressProps['percent']) => `${percent}%`;

export const generateStrokeColor = (percent: UiProgressProps['percent'] = 0, colors: string[]) => {
  const step = 100 / colors.length;
  const percentList = colors.map((_, index) => step * (index + 1));
  const colorIndex = percentList.findIndex((value) => percent <= value);

  return colors[colorIndex];
};

const DEFAULT_PROGRESS_COLOR = [
  variables.themePalette.colorNegative,
  variables.themePalette.colorWarning,
  variables.themePalette.colorSuccess,
];

export const UiProgress: FC<UiProgressProps> = (props) => {
  const { percent = 0, withChart = true, type, ...reset } = props;
  const strokeColor = useMemo(() => generateStrokeColor(percent, DEFAULT_PROGRESS_COLOR), [percent]);

  if (type === 'circle') {
    return <UiProgressCircle percent={percent} {...reset} type="circle" />;
  }

  return (
    <Progress
      className={classNames(styles.uiProgress, { [styles.uiProgress_hideChart]: !withChart })}
      percent={percent}
      strokeColor={strokeColor}
      strokeLinecap="square"
      format={defaultFormatPercent}
      type={type}
      {...reset}
    />
  );
};
