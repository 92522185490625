import type { Editor } from '@ckeditor/ckeditor5-core';

import { closeGlobalModal, GlobalModalNames, openGlobalModal } from '@vkph/common/store';
import { ImageUploadCropPayload } from '@vkph/common/types';

import { CropConfig } from '../../types';
import { fileIdAttributeModelName } from './constants';

type CropAndInsertImageParams = {
  editor: Editor;
  cropConfig: CropConfig;
  value?: ImageUploadCropPayload['value'];
};

export const cropAndInsertImage = (params: CropAndInsertImageParams) => {
  const { editor, cropConfig, value } = params;

  openGlobalModal(GlobalModalNames.ImageUploadCrop, {
    ...cropConfig,
    value,
    title: 'Загрузка изображения',
    onClose: () => closeGlobalModal(GlobalModalNames.ImageUploadCrop),
    onChange: (image) => {
      editor.execute('insertImage', {
        source: [
          {
            src: image.file,
            [fileIdAttributeModelName]: image.id,
          },
        ],
      });
    },
  });
};
