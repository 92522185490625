// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b0596366_empty__image{margin-left:auto;margin-right:auto}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/empty/UiEmpty.scss"],"names":[],"mappings":"AAEE,uBACE,gBAAA,CACA,iBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.empty {\n  &__image {\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty__image": "b0596366_empty__image"
};
export default ___CSS_LOADER_EXPORT___;
