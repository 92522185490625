import { ToolbarRenderInfoType } from 'rc-image/lib/Preview';
import React, { ReactNode, FC } from 'react';

import DeleteSvg from '@vkph/ui/svg/delete.svg';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import RotateLeftSvg from '@vkph/ui/svg/rotate-left.svg';
import RotateSvg from '@vkph/ui/svg/rotate.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';
import ZoomInSvg from '@vkph/ui/svg/zoom-in.svg';
import ZoomOutSvg from '@vkph/ui/svg/zoom-out.svg';

import { useSpace } from '../../../../hooks';
import { UiRow } from '../../../grid';
import { UiSpace } from '../../../space';
import styles from '../UiImagePreview.scss';
import { UiImagePreviewButton } from '../button';

export const PREVIEW_TRANSFORM_SCALE = {
  SCALE_MIN: 0.2,
  SCALE_MAX: 10,
  SCALE_STEP: 0.2,
};

export interface UiImagePreviewToolbarExtension {
  toolbarExtra?: ReactNode;
  customize?: {
    onShare?: () => void;
    onDownload?: () => void;
    onDelete?: () => void;
    title?: ReactNode;
    subtitle?: ReactNode;
  };
  children?: ReactNode;
}

export interface ToolbarRenderType
  extends Omit<ToolbarRenderInfoType, 'current' | 'total' | 'image' | 'icons'>,
    UiImagePreviewToolbarExtension {}

export const UiImagePreviewToolbar: FC<ToolbarRenderType> = (props) => {
  const { children, transform, actions, customize } = props;
  const { scale } = transform;
  const { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } = actions;
  const { spaceXL } = useSpace();

  return (
    <UiRow justify="space-between" style={{ width: '100%' }} className={styles.uiImagePreview__footer}>
      <UiSpace direction="vertical" className={styles.uiImagePreview__info}>
        {customize?.title}
        {customize?.subtitle}
      </UiSpace>
      <UiSpace size={48} className={styles.uiImagePreview__actions}>
        <UiSpace size={spaceXL}>
          {customize?.onDownload && (
            <UiImagePreviewButton icon={DownloadSvg} onClick={customize.onDownload} />
          )}
          {customize?.onDelete && <UiImagePreviewButton icon={DeleteSvg} onClick={customize.onDelete} />}
          {customize?.onShare && <UiImagePreviewButton icon={ShareSvg} onClick={customize.onShare} />}
        </UiSpace>
        <UiSpace size={spaceXL}>
          <UiImagePreviewButton icon={RotateLeftSvg} onClick={onRotateLeft} />
          <UiImagePreviewButton icon={RotateSvg} onClick={onRotateRight} />
        </UiSpace>
        <UiSpace size={spaceXL}>
          <UiImagePreviewButton
            icon={ZoomInSvg}
            onClick={onZoomIn}
            disabled={scale === PREVIEW_TRANSFORM_SCALE.SCALE_MAX}
          />
          <UiImagePreviewButton
            icon={ZoomOutSvg}
            disabled={scale === PREVIEW_TRANSFORM_SCALE.SCALE_MIN}
            onClick={onZoomOut}
          />
        </UiSpace>
        {children}
      </UiSpace>
    </UiRow>
  );
};
