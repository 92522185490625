// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2df4000d_ui-card-content_base-padding{padding:24px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/card/content/UiCardContent.scss","webpack://./../../libs/ui/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AAEE,wCACE,YCCU","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-card-content {\n  &_base-padding {\n    padding: $baseSpacing;\n  }\n}","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$borderRadiusLarge: get('themeSizes.borderRadiusLarge');\n$borderRadiusSmall: get('themeSizes.borderRadiusSmall');\n$baseIconSize: get('themeSizes.baseIconSize');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: getNumberToPx(get('spaceSizes.space2XS'));\n\n$tableCellSelectionPadding: get('themeSizes.tableCellSelectionPadding');\n\n$space2XS: getNumberToPx(get('spaceSizes.space2XS'));\n$spaceXS: getNumberToPx(get('spaceSizes.spaceXS'));\n$spaceS: getNumberToPx(get('spaceSizes.spaceS'));\n$spaceM: getNumberToPx(get('spaceSizes.spaceM'));\n$spaceL: getNumberToPx(get('spaceSizes.spaceL'));\n$spaceXL: getNumberToPx(get('spaceSizes.spaceXL'));\n$space2XL: getNumberToPx(get('spaceSizes.space2XL'));\n$space3XL: getNumberToPx(get('spaceSizes.space3XL'));\n$space4XL: getNumberToPx(get('spaceSizes.space4XL'));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-content_base-padding": "_2df4000d_ui-card-content_base-padding",
	"uiCardContent_basePadding": "_2df4000d_ui-card-content_base-padding"
};
export default ___CSS_LOADER_EXPORT___;
