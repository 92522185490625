import { BlogAlbumImageModel, BlogModel } from './blog.model';
import { CommentDetailListModel } from './comment.model';
import { CreateDateModel, PublishDateModel } from './date.model';
import { FileStorageEntryVersionModel, FileStorageListEntryModel } from './file.model';
import { GamificationAddedThank, GamificationBadges } from './gamification.model';
import { GroupDetailedInfoModel } from './groups.model';
import { MicropostModel, PostBlogModel, PostNewsModel } from './post';
import { SkillApprover, SkillId, UserSkill } from './profile.model';
import { ProjectsTasksModel } from './projects';
import { ReactionModel } from './reactions.model';
import { SitePageModel } from './sites.model';
import { UserIdModel, UserModel, UserPositionModel } from './user.model';

export type TimelineId = number;

export enum TimelineTypes {
  BadgeAutoCreated = 'badge_auto_created',
  BadgeManualCreated = 'badge_manual_created',
  BlogEntryCreated = 'blog_entry_created',
  CommentCreatedAlbumImage = 'comment_created_album_image',
  CommentCreatedSitectorPage = 'comment_created_sitector_page',
  CommentCreatedEntry = 'comment_created_entry',
  CommentCreatedFileVersion = 'comment_created_file_version',
  CommentCreatedMicropost = 'comment_created_micropost',
  CommentCreatedNews = 'comment_created_news',
  CommentCreatedRecord = 'comment_created_record',
  CommentCreatedTask = 'comment_created_task',
  CommentCreatedThanks = 'comment_created_thanks',
  CommentCreatedUserBadge = 'comment_created_user_badge',
  CommentReplyAlbumImage = 'comment_reply_album_image',
  CommentReplySitectorPage = 'comment_reply_sitector_page',
  CommentReplyEntry = 'comment_reply_entry',
  CommentReplyFileVersion = 'comment_reply_file_version',
  CommentReplyMicropost = 'comment_reply_micropost',
  CommentReplyNews = 'comment_reply_news',
  CommentReplyRecord = 'comment_reply_record',
  CommentReplyTask = 'comment_reply_task',
  CommentReplyThanks = 'comment_reply_thanks',
  CommentReplyUserBadge = 'comment_reply_user_badge',
  CompetenceApproved = 'competence_approved',
  MicropostCreated = 'micropost_created',
  NewsPinned = 'news_pinned',
  SkillApproved = 'skill_approved',
  ThanksCreated = 'thanks_created',
  UserStructureUpdated = 'user_structure_updated',
}

export interface TimelineBaseModel extends CreateDateModel, PublishDateModel {
  id: TimelineId;
}

export type TimelineBlogEntryContentModel = {
  entry: PostBlogModel;
};

export type TimelineMicropostContentModel = {
  micropost: MicropostModel;
};

export type TimelineNewsContentModel = {
  news: PostNewsModel;
};

export type TimelineSitectorPageContentModel = {
  sitector: SitePageModel;
};

export type TimelineAlbumImageContentModel = {
  albumImage: BlogAlbumImageModel;
  blog: BlogModel;
};

export type TimelineTaskContentModel = {
  task: ProjectsTasksModel;
};

export type TimelineFileVersionContentModel = {
  fileVersion: FileStorageEntryVersionModel;
  file: FileStorageListEntryModel;
};

export type TimelineCommentContentModel = {
  comment: CommentDetailListModel;
  parent?: CommentDetailListModel;
};

export type TimelineThanksContentModel = {
  thanks: GamificationAddedThank;
};

export type TimelineBadgeContentModel = {
  userBadge: GamificationBadges;
};

interface TimelineSkillApprove extends SkillApprover {
  skillId: SkillId;
}

interface TimelineCompetenceApprove extends SkillApprover {
  competenceId: SkillId;
}

export interface TimelineSkill extends UserSkill {
  id: SkillId;
  ownerUserId: UserIdModel;
}

export type TimelineSkillContentModel = {
  skill: TimelineSkill;
  skillApprove: TimelineSkillApprove;
};

export type TimelineCompetenceContentModel = {
  userCompetence: TimelineSkill;
  userCompetenceApprove: TimelineCompetenceApprove;
};

export type TimelineCommentRecordModel = {
  record: TimelineRecordTargetModel<TimelineUserStructureContentModel>;
};

export type TimelineCommentNewsContentModel = TimelineNewsContentModel & TimelineCommentContentModel;
export type TimelineCommentPostContentModel = TimelineBlogEntryContentModel & TimelineCommentContentModel;
export type TimelineCommentMicropostContentModel = TimelineMicropostContentModel &
  TimelineCommentContentModel;
export type TimelineCommentUserBadgeContentModel = TimelineBadgeContentModel & TimelineCommentContentModel;
export type TimelineCommentThanksContentModel = TimelineThanksContentModel & TimelineCommentContentModel;
export type TimelineCommentFileVersionContentModel = TimelineFileVersionContentModel &
  TimelineCommentContentModel;
export type TimelineCommentSitectorPageContentModel = TimelineSitectorPageContentModel &
  TimelineCommentContentModel;
export type TimelineCommentAlbumImageContentModel = TimelineAlbumImageContentModel &
  TimelineCommentContentModel;
export type TimelineCommentTaskContentModel = TimelineTaskContentModel & TimelineCommentContentModel;
export type TimelineCommentRecordContentModel = TimelineCommentRecordModel & TimelineCommentContentModel;

export type TimelineUserStructureContentModel = {
  position?: UserPositionModel;
  oldPosition?: UserPositionModel;
  group?: GroupDetailedInfoModel;
  oldGroup?: GroupDetailedInfoModel;
  commentsCount?: number;
  reactions?: ReactionModel[];
};

export type TimelineCommentContentModels =
  | TimelineCommentNewsContentModel
  | TimelineCommentPostContentModel
  | TimelineCommentMicropostContentModel
  | TimelineCommentThanksContentModel
  | TimelineCommentFileVersionContentModel
  | TimelineCommentTaskContentModel
  | TimelineCommentSitectorPageContentModel
  | TimelineCommentAlbumImageContentModel
  | TimelineCommentRecordContentModel
  | TimelineCommentUserBadgeContentModel;

export type TimelineContent =
  | TimelineBlogEntryContentModel
  | TimelineMicropostContentModel
  | TimelineThanksContentModel
  | TimelineBadgeContentModel
  | TimelineNewsContentModel
  | TimelineSkillContentModel
  | TimelineCommentContentModels
  | TimelineCompetenceContentModel
  | TimelineUserStructureContentModel;

export interface TimelineRecordModel<T extends TimelineContent = TimelineContent> extends TimelineBaseModel {
  type: TimelineTypes;
  target?: UserModel;
  actor?: UserModel;
  content: T;
}

export interface TimelineRecordActorModel<T extends TimelineContent = TimelineContent>
  extends Omit<TimelineRecordModel<T>, 'actor'> {
  actor: UserModel;
}

export interface TimelineRecordTargetModel<T extends TimelineContent = TimelineContent>
  extends Omit<TimelineRecordModel<T>, 'target'> {
  target: UserModel;
}

export interface TimelineRecordTargetActorModel<T extends TimelineContent = TimelineContent>
  extends Omit<TimelineRecordModel<T>, 'target' | 'actor'> {
  target: UserModel;
  actor: UserModel;
}

export interface TimelineModel extends TimelineBaseModel {
  type: TimelineTypes;
  recordsGroup: TimelineRecordModel[];
}

export enum TimelineSettingsAllType {
  All = 'all',
  Subscriptions = 'subscriptions',
  Nothing = 'nothing',
}

export type TimelineSettingsAllFields =
  | 'entries'
  | 'microposts'
  | 'skills'
  | 'competences'
  | 'thanks'
  | 'badges';

export type TimelineSettingsAllModel = Record<TimelineSettingsAllFields, TimelineSettingsAllType>;
