import Icon from '@ant-design/icons';
import { Form as AntForm, FormItemProps as AntFormItemProps, TooltipProps as AntTooltipProps } from 'antd';
import classNames from 'classnames';
import React, { FC, isValidElement, useMemo } from 'react';

import FaqSvg from '@vkph/ui/svg/faq.svg';

import { usePaddingStyle } from '../../../hooks';
import { useSpace } from '../../../providers/space';
import styles from './UiFormItem.scss';

const isTooltip = (tooltip: AntFormItemProps['tooltip']): tooltip is AntTooltipProps => {
  if (!tooltip) {
    return false;
  }

  return typeof tooltip === 'object' && !isValidElement(tooltip);
};

interface Props extends Omit<AntFormItemProps, 'labelAlign'> {
  labelAlign?: AntFormItemProps['labelAlign'] | 'top';
}

export const UiFormItem: FC<Props> = (props) => {
  const { tooltip, className, labelAlign = 'left', ...rest } = props;

  const { spaceS } = useSpace();
  const hintPadding = usePaddingStyle([0, spaceS]);

  const itemTooltip = useMemo(() => {
    if (!tooltip) {
      return tooltip;
    }

    const hintIcon = <Icon component={FaqSvg} style={{ fontSize: 20, cursor: 'help' }} />;

    if (isTooltip(tooltip)) {
      return {
        ...tooltip,
        title:
          Array.isArray(tooltip.title) &&
          !isValidElement(tooltip.title) &&
          tooltip.title.every((item) => !isValidElement(item)) ? (
            <ul style={{ listStyle: 'initial', ...hintPadding }}>
              {tooltip.title.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          ) : (
            tooltip.title
          ),
        icon: tooltip?.icon || hintIcon,
      };
    }

    return {
      title: tooltip,
      icon: hintIcon,
    };
  }, [tooltip]);

  return (
    <AntForm.Item
      className={classNames(className, styles.uiFormItem, {
        [styles.uiFormItem_labelTop]: labelAlign === 'top',
      })}
      labelAlign={labelAlign === 'top' ? 'left' : labelAlign}
      tooltip={itemTooltip}
      {...rest}
    />
  );
};
