/**
 * Do not edit directly
 * Generated on Wed, 22 Jan 2025 11:42:40 GMT
 */

export const ColorsAchromatic0 = "#FFFFFF";
export const ColorsAchromatic10 = "#FBFBFB";
export const ColorsAchromatic20 = "#F6F6F6";
export const ColorsAchromatic30 = "#EEEEEE";
export const ColorsAchromatic40 = "#E3E3E3";
export const ColorsAchromatic50 = "#D6D6D7";
export const ColorsAchromatic60 = "#C9C9CA";
export const ColorsAchromatic70 = "#BBBCBD";
export const ColorsAchromatic80 = "#B1B1B2";
export const ColorsAchromatic90 = "#A3A4A5";
export const ColorsAchromatic100 = "#969798";
export const ColorsAchromatic150 = "#898A8B";
export const ColorsAchromatic200 = "#7C7D7F";
export const ColorsAchromatic250 = "#6F7072";
export const ColorsAchromatic300 = "#646567";
export const ColorsAchromatic350 = "#57585A";
export const ColorsAchromatic400 = "#4C4D50";
export const ColorsAchromatic450 = "#3D3E41";
export const ColorsAchromatic500 = "#303134";
export const ColorsAchromatic550 = "#252629";
export const ColorsAchromatic600 = "#212225";
export const ColorsAchromatic650 = "#1C1D1F";
export const ColorsAchromatic700 = "#161719";
export const ColorsAchromatic750 = "#111112";
export const ColorsAchromatic800 = "#0F0F11";
export const ColorsAchromatic850 = "#0C0C0D";
export const ColorsAchromatic900 = "#040405";
export const ColorsAchromatic950 = "#000000";
export const ColorsGreyBlue0 = "#FCFDFD";
export const ColorsGreyBlue10 = "#F5F6F8";
export const ColorsGreyBlue20 = "#EFF0F4";
export const ColorsGreyBlue30 = "#E0E3E9";
export const ColorsGreyBlue40 = "#D8DCE4";
export const ColorsGreyBlue50 = "#D2D7DF";
export const ColorsGreyBlue60 = "#CAD0DA";
export const ColorsGreyBlue70 = "#C3C9D5";
export const ColorsGreyBlue80 = "#BBC3CF";
export const ColorsGreyBlue90 = "#B3BCCA";
export const ColorsGreyBlue100 = "#ADB7C7";
export const ColorsGreyBlue150 = "#A9B2C2";
export const ColorsGreyBlue200 = "#A4AEBF";
export const ColorsGreyBlue250 = "#9EA9BB";
export const ColorsGreyBlue300 = "#98A3B7";
export const ColorsGreyBlue350 = "#8F9BB1";
export const ColorsGreyBlue400 = "#8795AB";
export const ColorsGreyBlue450 = "#818FA7";
export const ColorsGreyBlue500 = "#778498";
export const ColorsGreyBlue550 = "#6B778A";
export const ColorsGreyBlue600 = "#616B7D";
export const ColorsGreyBlue650 = "#576171";
export const ColorsGreyBlue700 = "#4D5664";
export const ColorsGreyBlue750 = "#49515F";
export const ColorsGreyBlue800 = "#434854";
export const ColorsGreyBlue850 = "#3D414A";
export const ColorsGreyBlue900 = "#343A44";
export const ColorsGreyBlue950 = "#292D35";
export const ColorsBrand0 = "#FAFCFF";
export const ColorsBrand10 = "#F5F9FF";
export const ColorsBrand20 = "#EBF2FF";
export const ColorsBrand30 = "#DEEAFE";
export const ColorsBrand40 = "#BFD7FE";
export const ColorsBrand50 = "#B0CDFD";
export const ColorsBrand60 = "#A3C5FD";
export const ColorsBrand70 = "#94BCFC";
export const ColorsBrand80 = "#85B2FC";
export const ColorsBrand90 = "#75A9FC";
export const ColorsBrand100 = "#669FFB";
export const ColorsBrand150 = "#5795FB";
export const ColorsBrand200 = "#4A8DFB";
export const ColorsBrand250 = "#3B84FA";
export const ColorsBrand300 = "#2E7CFA";
export const ColorsBrand350 = "#1C71FA";
export const ColorsBrand400 = "#0D67F9";
export const ColorsBrand450 = "#005FF9";
export const ColorsBrand500 = "#0056E0";
export const ColorsBrand550 = "#004DCA";
export const ColorsBrand600 = "#0045B3";
export const ColorsBrand650 = "#0041A8";
export const ColorsBrand700 = "#003995";
export const ColorsBrand750 = "#003486";
export const ColorsBrand800 = "#002F7B";
export const ColorsBrand850 = "#002B70";
export const ColorsBrand900 = "#002765";
export const ColorsBrand950 = "#002157";
export const ColorsRed0 = "#FFFBFB";
export const ColorsRed10 = "#FEF7F7";
export const ColorsRed20 = "#FDEEEE";
export const ColorsRed30 = "#FCE4E4";
export const ColorsRed40 = "#FBD8D8";
export const ColorsRed50 = "#F9CBCB";
export const ColorsRed60 = "#F8BFBF";
export const ColorsRed70 = "#F6B4B4";
export const ColorsRed80 = "#F5A8A8";
export const ColorsRed90 = "#F39C9C";
export const ColorsRed100 = "#F28F8F";
export const ColorsRed150 = "#F18383";
export const ColorsRed200 = "#EF7676";
export const ColorsRed250 = "#EE6C6C";
export const ColorsRed300 = "#ED6060";
export const ColorsRed350 = "#EB5555";
export const ColorsRed400 = "#EA4747";
export const ColorsRed450 = "#E73030";
export const ColorsRed500 = "#D02B2B";
export const ColorsRed550 = "#B92626";
export const ColorsRed600 = "#AD2424";
export const ColorsRed650 = "#9C2020";
export const ColorsRed700 = "#003995";
export const ColorsRed750 = "#8B1D1D";
export const ColorsRed800 = "#7D1A1A";
export const ColorsRed850 = "#6E1717";
export const ColorsRed900 = "#681616";
export const ColorsRed950 = "#5E1313";
export const ColorsOrange0 = "#FFFCFC";
export const ColorsOrange10 = "#FFFAF8";
export const ColorsOrange20 = "#FEF4F1";
export const ColorsOrange30 = "#FEEEE9";
export const ColorsOrange40 = "#FDDED4";
export const ColorsOrange50 = "#FDD6C9";
export const ColorsOrange60 = "#FCCFC1";
export const ColorsOrange70 = "#FCC7B6";
export const ColorsOrange80 = "#FCBFAC";
export const ColorsOrange90 = "#FBB7A2";
export const ColorsOrange100 = "#FBAF97";
export const ColorsOrange150 = "#FAA78D";
export const ColorsOrange200 = "#FAA184";
export const ColorsOrange250 = "#FA997A";
export const ColorsOrange300 = "#F99271";
export const ColorsOrange350 = "#F98965";
export const ColorsOrange400 = "#F8815B";
export const ColorsOrange450 = "#F87A52";
export const ColorsOrange500 = "#DF6E4A";
export const ColorsOrange550 = "#C96343";
export const ColorsOrange600 = "#B2583B";
export const ColorsOrange650 = "#A75338";
export const ColorsOrange700 = "#954931";
export const ColorsOrange750 = "#86422C";
export const ColorsOrange800 = "#703725";
export const ColorsOrange850 = "#643121";
export const ColorsOrange900 = "#572B1D";
export const ColorsOrange950 = "#4E271A";
export const ColorsYellow0 = "#FFFEFB";
export const ColorsYellow10 = "#FFFDF7";
export const ColorsYellow20 = "#FFFAEF";
export const ColorsYellow30 = "#FFF8E4";
export const ColorsYellow40 = "#FFF1CC";
export const ColorsYellow50 = "#FFEDC0";
export const ColorsYellow60 = "#FFEAB6";
export const ColorsYellow70 = "#FFE7A9";
export const ColorsYellow80 = "#FFE49D";
export const ColorsYellow90 = "#FFE091";
export const ColorsYellow100 = "#FFDD85";
export const ColorsYellow150 = "#FFD978";
export const ColorsYellow200 = "#FFD76E";
export const ColorsYellow250 = "#FFD362";
export const ColorsYellow300 = "#FFD058";
export const ColorsYellow350 = "#FFCC49";
export const ColorsYellow400 = "#FFC93D";
export const ColorsYellow450 = "#FFC633";
export const ColorsYellow500 = "#E6B22E";
export const ColorsYellow550 = "#CC9E29";
export const ColorsYellow600 = "#BF9526";
export const ColorsYellow650 = "#B88E25";
export const ColorsYellow700 = "#AD8623";
export const ColorsYellow750 = "#99771F";
export const ColorsYellow800 = "#8A6B1C";
export const ColorsYellow850 = "#735917";
export const ColorsYellow900 = "#675015";
export const ColorsYellow950 = "#594512";
export const ColorsGreen0 = "#FBFEFC";
export const ColorsGreen10 = "#F7FCF8";
export const ColorsGreen20 = "#EFFAF1";
export const ColorsGreen30 = "#E5F6E9";
export const ColorsGreen40 = "#CEEED4";
export const ColorsGreen50 = "#C2EACA";
export const ColorsGreen60 = "#B8E7C1";
export const ColorsGreen70 = "#ACE3B7";
export const ColorsGreen80 = "#A0DFAC";
export const ColorsGreen90 = "#95DBA2";
export const ColorsGreen100 = "#89D798";
export const ColorsGreen150 = "#7DD38D";
export const ColorsGreen200 = "#73CF85";
export const ColorsGreen250 = "#67CB7B";
export const ColorsGreen300 = "#5DC872";
export const ColorsGreen350 = "#50C366";
export const ColorsGreen400 = "#44BF5C";
export const ColorsGreen450 = "#3ABC53";
export const ColorsGreen500 = "#34A94B";
export const ColorsGreen550 = "#2F9844";
export const ColorsGreen600 = "#2A873C";
export const ColorsGreen650 = "#277F38";
export const ColorsGreen700 = "#237132";
export const ColorsGreen750 = "#1F652D";
export const ColorsGreen800 = "#1A5525";
export const ColorsGreen850 = "#174C22";
export const ColorsGreen900 = "#14421D";
export const ColorsGreen950 = "#123B1A";
export const ColorsMint0 = "#FAFEFF";
export const ColorsMint10 = "#F5FDFE";
export const ColorsMint20 = "#EBFBFD";
export const ColorsMint30 = "#DEF9FC";
export const ColorsMint40 = "#BFF4F9";
export const ColorsMint50 = "#B0F1F7";
export const ColorsMint60 = "#A3EFF6";
export const ColorsMint70 = "#94EDF5";
export const ColorsMint80 = "#75E7F2";
export const ColorsMint90 = "#57E2EF";
export const ColorsMint100 = "#4AE0ED";
export const ColorsMint150 = "#3BDDEC";
export const ColorsMint200 = "#2EDBEB";
export const ColorsMint250 = "#1CD8E9";
export const ColorsMint300 = "#0DD5E7";
export const ColorsMint350 = "#00D3E6";
export const ColorsMint400 = "#00C9DA";
export const ColorsMint450 = "#00BECF";
export const ColorsMint500 = "#00B3C4";
export const ColorsMint550 = "#00ABBA";
export const ColorsMint600 = "#0098A6";
export const ColorsMint650 = "#008F9B";
export const ColorsMint700 = "#007F8A";
export const ColorsMint750 = "#00727C";
export const ColorsMint800 = "#005F67";
export const ColorsMint850 = "#00555D";
export const ColorsMint900 = "#004A51";
export const ColorsMint950 = "#004348";
export const ColorsPurple0 = "#FCFCFF";
export const ColorsPurple10 = "#F9F8FE";
export const ColorsPurple20 = "#F4F2FD";
export const ColorsPurple30 = "#EDEAFC";
export const ColorsPurple40 = "#DCD6F9";
export const ColorsPurple50 = "#D4CCF7";
export const ColorsPurple60 = "#CDC4F6";
export const ColorsPurple70 = "#C4BBF5";
export const ColorsPurple80 = "#BCB1F3";
export const ColorsPurple90 = "#B3A7F2";
export const ColorsPurple100 = "#AB9DF0";
export const ColorsPurple150 = "#A393EF";
export const ColorsPurple200 = "#9C8BED";
export const ColorsPurple250 = "#9381EC";
export const ColorsPurple300 = "#8C79EB";
export const ColorsPurple350 = "#826EE9";
export const ColorsPurple400 = "#7A64E7";
export const ColorsPurple450 = "#735CE6";
export const ColorsPurple500 = "#6853CF";
export const ColorsPurple550 = "#5E4BBA";
export const ColorsPurple600 = "#5342A6";
export const ColorsPurple650 = "#4E3E9B";
export const ColorsPurple700 = "#45378A";
export const ColorsPurple750 = "#3E327C";
export const ColorsPurple800 = "#392D71";
export const ColorsPurple850 = "#342967";
export const ColorsPurple900 = "#2F255D";
export const ColorsPurple950 = "#282051";
export const ColorsLavender0 = "#FDFCFF";
export const ColorsLavender10 = "#FCF9FE";
export const ColorsLavender20 = "#F8F2FE";
export const ColorsLavender30 = "#F4EAFD";
export const ColorsLavender40 = "#EAD8FB";
export const ColorsLavender50 = "#E4CEFA";
export const ColorsLavender60 = "#E0C6FA";
export const ColorsLavender70 = "#DBBDF9";
export const ColorsLavender80 = "#D6B3F8";
export const ColorsLavender90 = "#D1AAF7";
export const ColorsLavender100 = "#CBA0F6";
export const ColorsLavender150 = "#C697F5";
export const ColorsLavender200 = "#C28FF4";
export const ColorsLavender250 = "#BD85F3";
export const ColorsLavender300 = "#B87DF3";
export const ColorsLavender350 = "#B272F2";
export const ColorsLavender400 = "#AD69F1";
export const ColorsLavender450 = "#A861F0";
export const ColorsLavender500 = "#9857D8";
export const ColorsLavender550 = "#874EC0";
export const ColorsLavender600 = "#814AB8";
export const ColorsLavender650 = "#7A46AD";
export const ColorsLavender700 = "#6F409E";
export const ColorsLavender750 = "#653A90";
export const ColorsLavender800 = "#5B3482";
export const ColorsLavender850 = "#522F75";
export const ColorsLavender900 = "#4C2C6C";
export const ColorsLavender950 = "#442762";
export const ColorsLiliac0 = "#FEFDFE";
export const ColorsLiliac10 = "#FEFBFE";
export const ColorsLiliac20 = "#FDF6FC";
export const ColorsLiliac30 = "#FBF1FA";
export const ColorsLiliac40 = "#F8E3F6";
export const ColorsLiliac50 = "#F6DDF4";
export const ColorsLiliac60 = "#F5D7F2";
export const ColorsLiliac70 = "#F3D0EF";
export const ColorsLiliac80 = "#F1CAED";
export const ColorsLiliac90 = "#EFC3EB";
export const ColorsLiliac100 = "#EEBCE9";
export const ColorsLiliac150 = "#ECB6E7";
export const ColorsLiliac200 = "#EAB0E5";
export const ColorsLiliac250 = "#E9AAE3";
export const ColorsLiliac300 = "#E7A4E1";
export const ColorsLiliac350 = "#E59CDE";
export const ColorsLiliac400 = "#E396DC";
export const ColorsLiliac450 = "#E290DA";
export const ColorsLiliac500 = "#CB82C4";
export const ColorsLiliac550 = "#B573AE";
export const ColorsLiliac600 = "#AA6CA4";
export const ColorsLiliac650 = "#986293";
export const ColorsLiliac700 = "#885683";
export const ColorsLiliac750 = "#7A4E76";
export const ColorsLiliac800 = "#70456C";
export const ColorsLiliac850 = "#664162";
export const ColorsLiliac900 = "#5B3A58";
export const ColorsLiliac950 = "#4F324C";
export const ColorsMagenta0 = "#FFFBFD";
export const ColorsMagenta10 = "#FFF7FA";
export const ColorsMagenta20 = "#FFEFF5";
export const ColorsMagenta30 = "#FFE5EF";
export const ColorsMagenta40 = "#FFD8E8";
export const ColorsMagenta50 = "#FFCEE1";
export const ColorsMagenta60 = "#FFC2D9";
export const ColorsMagenta70 = "#FFB8D3";
export const ColorsMagenta80 = "#FFACCC";
export const ColorsMagenta90 = "#FFA0C4";
export const ColorsMagenta100 = "#FF94BD";
export const ColorsMagenta150 = "#FF88B6";
export const ColorsMagenta200 = "#FF7CAE";
export const ColorsMagenta250 = "#FF72A8";
export const ColorsMagenta300 = "#FF67A1";
export const ColorsMagenta350 = "#FF5D9B";
export const ColorsMagenta400 = "#FF4F92";
export const ColorsMagenta450 = "#FF3985";
export const ColorsMagenta500 = "#E63378";
export const ColorsMagenta550 = "#CC2E6A";
export const ColorsMagenta600 = "#AD265A";
export const ColorsMagenta650 = "#992250";
export const ColorsMagenta700 = "#8A1F48";
export const ColorsMagenta750 = "#7C1D43";
export const ColorsMagenta800 = "#731A3C";
export const ColorsMagenta850 = "#671736";
export const ColorsMagenta900 = "#59142F";
export const ColorsMagenta950 = "#51122A";
export const OpacityLight = "rgba(0,0,0,40%)";
export const OpacityDark = "rgba(0,0,0,80%)";
export const NumbersRadius0 = 0;
export const NumbersRadius2 = 2;
export const NumbersRadius4 = 4;
export const NumbersRadius6 = 6;
export const NumbersRadius8 = 8;
export const NumbersRadius10 = 10;
export const NumbersRadius12 = 12;
export const NumbersRadius16 = 16;
export const NumbersRadius20 = 20;
export const NumbersRadius24 = 24;
export const NumbersRadius28 = 28;
export const NumbersRadius32 = 32;
export const NumbersRadius36 = 36;
export const NumbersRadius40 = 40;
export const NumbersRadius44 = 44;
export const NumbersRadius48 = 48;
export const NumbersRadius52 = 52;
export const NumbersRadius56 = 56;
export const NumbersRadius60 = 60;
export const NumbersRadius64 = 54;
export const NumbersRadius68 = 68;
export const NumbersRadius72 = 72;
export const NumbersRadius76 = 76;
export const NumbersRadius80 = 80;
export const NumbersRadius84 = 84;
export const NumbersRadius88 = 88;
export const NumbersRadius92 = 92;
export const NumbersRadius96 = 96;
export const NumbersRadius100 = 100;
export const NumbersRadius200 = 200;
export const NumbersSpacing0 = 0;
export const NumbersSpacing2 = 2;
export const NumbersSpacing4 = 4;
export const NumbersSpacing6 = 6;
export const NumbersSpacing8 = 8;
export const NumbersSpacing10 = 10;
export const NumbersSpacing12 = 12;
export const NumbersSpacing16 = 16;
export const NumbersSpacing20 = 20;
export const NumbersSpacing24 = 24;
export const NumbersSpacing28 = 28;
export const NumbersSpacing32 = 32;
export const NumbersSpacing36 = 36;
export const NumbersSpacing40 = 40;
export const NumbersSpacing44 = 44;
export const NumbersSpacing48 = 48;
export const NumbersSpacing52 = 52;
export const NumbersSpacing56 = 56;
export const NumbersSpacing60 = 60;
export const NumbersSpacing64 = 64;
export const NumbersSpacing68 = 68;
export const NumbersSpacing72 = 72;
export const NumbersSpacing76 = 76;
export const NumbersSpacing80 = 80;
export const NumbersSpacing84 = 84;
export const NumbersSpacing88 = 88;
export const NumbersSpacing92 = 92;
export const NumbersSpacing96 = 96;
export const NumbersSpacing100 = 100;
export const NumbersSpacing104 = 104;
export const NumbersSpacing108 = 108;
export const NumbersSpacing112 = 112;
export const NumbersSpacing116 = 116;
export const NumbersSpacing120 = 120;
export const NumbersSpacing124 = 124;
export const NumbersSpacing128 = 128;
export const NumbersSpacing132 = 132;
export const NumbersSpacing136 = 136;
export const NumbersSpacing140 = 140;
export const NumbersSpacing144 = 144;
export const NumbersSpacing148 = 148;
export const NumbersSpacing152 = 152;
export const NumbersSpacing156 = 156;
export const NumbersSpacing160 = 160;
export const NumbersSpacing164 = 164;
export const NumbersSpacing168 = 168;
export const NumbersSpacing172 = 172;
export const NumbersSpacing176 = 178;
export const NumbersSpacing180 = 180;
export const NumbersSpacing184 = 184;
export const NumbersSpacing190 = 190;
export const NumbersSpacing194 = 194;
export const NumbersSpacing198 = 198;
export const NumbersSpacing200 = 200;
export const NumbersSpacing208 = 208;
export const NumbersSpacing216 = 216;
export const NumbersSpacing224 = 224;
export const NumbersSpacing232 = 232;
export const NumbersSpacing240 = 240;
export const NumbersSpacing248 = 248;
export const NumbersSpacing256 = 256;
export const NumbersSpacing264 = 264;
export const NumbersSpacing272 = 272;
export const NumbersSpacing280 = 280;
export const NumbersSpacing288 = 288;
export const NumbersSpacing296 = 296;
export const NumbersSpacing304 = 304;
export const NumbersSpacingNegative40 = -40;
export const NumbersSpacingNegative36 = -36;
export const NumbersSpacingNegative32 = -32;
export const NumbersSpacingNegative28 = -28;
export const NumbersSpacingNegative24 = -24;
export const NumbersSpacingNegative20 = -20;
export const NumbersSpacingNegative16 = -16;
export const NumbersSpacingNegative12 = -12;
export const NumbersSpacingNegative8 = -8;
export const NumbersSpacingNegative4 = -4;
export const NumbersStroke10 = 1;
export const NumbersStroke12 = 1.2;
export const NumbersStroke14 = 1.4;
export const NumbersStroke16 = 1.6;
export const NumbersStroke18 = 1.8;
export const NumbersStroke20 = 2;
export const NumbersStroke30 = 3;
export const NumbersStroke40 = 4;
export const NumbersStroke50 = 5;
export const PaletteTextPrimary = "#252629";
export const PaletteTextSecondary = "#898A8B";
export const PaletteTextTertiary = "#818FA7";
export const PaletteTextInverted = "#FFFFFF";
export const PaletteTextLink = "#005FF9";
export const PaletteTextHover = "#004DCA";
export const PaletteTextActive = "#0041A8";
export const PaletteTextDisable = "#B1B1B2";
export const PaletteTextNegative = "#E73030";
export const PaletteTextNegativeHover = "#B92626";
export const PaletteTextNegativeActive = "#9C2020";
export const PaletteTextWarning = "#FFC633";
export const PaletteTextPositive = "#3ABC53";
export const PaletteTextInfo = "#A861F0";
export const PaletteStrokePrimary = "#E0E3E9";
export const PaletteStrokeSecondary = "#8F9BB1";
export const PaletteStrokeTertiary = "#005FF9";
export const PaletteStrokeHover = "#004DCA";
export const PaletteStrokeActive = "#0041A8";
export const PaletteStrokeSelected = "#252629";
export const PaletteStrokeDisable = "#BBBCBD";
export const PaletteStrokeInverted = "#FFFFFF";
export const PaletteStrokeNegative = "#E73030";
export const PaletteStrokeWarning = "#FFC633";
export const PaletteStrokePositive = "#3ABC53";
export const PaletteStrokeInfo = "#A861F0";
export const PaletteIconDevelop = "#000000";
export const PaletteIconPrimary = "#8F9BB1";
export const PaletteIconSecondary = "#005FF9";
export const PaletteIconHover = "#004DCA";
export const PaletteIconActive = "#0041A8";
export const PaletteIconInverted = "#FFFFFF";
export const PaletteIconNegative = "#E73030";
export const PaletteIconNegativeHover = "#B92626";
export const PaletteIconNegativeActive = "#9C2020";
export const PaletteIconDisable = "#B1B1B2";
export const PaletteIconPositive = "#3ABC53";
export const PaletteIconWarning = "#FFC633";
export const PaletteIconInfo = "#A861F0";
export const PaletteBgBackdropPrimary = "#FFFFFF";
export const PaletteBgBackdropSecondary = "#EFF0F4";
export const PaletteBgBackdropLightOverlay = "rgba(0,0,0,40%)";
export const PaletteBgBackdropDarkOverlay = "rgba(0,0,0,80%)";
export const PaletteBgBackdropLink = "#F5F9FF";
export const PaletteBgBackdropNegative = "#FEF7F7";
export const PaletteBgBackdropWarning = "#FFFDF7";
export const PaletteBgBackdropPositive = "#F7FCF8";
export const PaletteBgBackdropInfo = "#FCF9FE";
export const PaletteBgBadgeBrand = "#005FF9";
export const PaletteBgBadgeRed = "#E73030";
export const PaletteBgBadgeOrange = "#F87A52";
export const PaletteBgBadgeYellow = "#FFC633";
export const PaletteBgBadgeGreen = "#3ABC53";
export const PaletteBgBadgeMint = "#00BECF";
export const PaletteBgBadgePurple = "#735CE6";
export const PaletteBgBadgeLavender = "#A861F0";
export const PaletteBgBadgeLiliac = "#E290DA";
export const PaletteBgBadgeMagenta = "#FF3985";
export const PaletteBgBadgeBlack = "#252629";
export const PaletteBgBadgeWhite = "#FFFFFF";
export const PaletteBgBadgeGrey = "#A9B2C2";
export const PaletteBgButtonPrimary = "#005FF9";
export const PaletteBgButtonHover = "#004DCA";
export const PaletteBgButtonActive = "#0041A8";
export const PaletteBgButtonSecondary = "#EFF0F4";
export const PaletteBgButtonNegative = "#E73030";
export const PaletteBgButtonNegativeHover = "#004DCA";
export const PaletteBgButtonNegativeActive = "#0041A8";
export const PaletteBgButtonDisable = "#E3E3E3";
export const PaletteBgButtonSkeleton = "#EFF0F4";
export const NumberRadiusRs0 = "0px";
export const NumberRadiusRs4 = "4px";
export const NumberRadiusRs8 = "8px";
export const NumberRadiusRs12 = "12px";
export const NumberRadiusRs16 = "16px";
export const NumberRadiusRs20 = "20px";
export const NumberRadiusRs24 = "24px";
export const NumberRadiusRs28 = "28px";
export const NumberRadiusRs32 = "32px";
export const NumberRadiusRs36 = "36px";
export const NumberRadiusRs40 = "40px";
export const NumberRadiusRs44 = "44px";
export const NumberRadiusRs48 = "48px";
export const NumberRadiusRs52 = "52px";
export const NumberRadiusRs56 = "56px";
export const NumberRadiusRs60 = "60px";
export const NumberRadiusRs64 = "54px";
export const NumberRadiusRs68 = "68px";
export const NumberRadiusRs72 = "72px";
export const NumberRadiusRs76 = "76px";
export const NumberRadiusRs80 = "80px";
export const NumberRadiusRs84 = "84px";
export const NumberRadiusRs88 = "88px";
export const NumberRadiusRs92 = "92px";
export const NumberRadiusRs96 = "96px";
export const NumberRadiusRs100 = "100px";
export const NumberRadiusRs200 = "200px";
export const NumberRadiusRe2 = "2px";
export const NumberRadiusRe6 = "6px";
export const NumberRadiusRe10 = "10px";
export const NumberGapGh0 = "0px";
export const NumberGapGh4 = "4px";
export const NumberGapGh8 = "8px";
export const NumberGapGh12 = "12px";
export const NumberGapGh16 = "16px";
export const NumberGapGh20 = "20px";
export const NumberGapGh24 = "24px";
export const NumberGapGh28 = "28px";
export const NumberGapGh32 = "32px";
export const NumberGapGh36 = "36px";
export const NumberGapGh40 = "40px";
export const NumberGapGh44 = "44px";
export const NumberGapGh48 = "48px";
export const NumberGapGh52 = "52px";
export const NumberGapGh56 = "56px";
export const NumberGapGh60 = "60px";
export const NumberGapGh64 = "64px";
export const NumberGapGh68 = "68px";
export const NumberGapGh72 = "72px";
export const NumberGapGh76 = "76px";
export const NumberGapGh80 = "80px";
export const NumberGapGh84 = "84px";
export const NumberGapGh88 = "88px";
export const NumberGapGh92 = "92px";
export const NumberGapGh96 = "96px";
export const NumberGapGh100 = "100px";
export const NumberGapGh104 = "104px";
export const NumberGapGh108 = "108px";
export const NumberGapGh112 = "112px";
export const NumberGapGh116 = "116px";
export const NumberGapGh120 = "120px";
export const NumberGapGhNegative4 = "-4px";
export const NumberGapGhNegative8 = "-8px";
export const NumberGapGhNegative12 = "-12px";
export const NumberGapGhNegative16 = "-16px";
export const NumberGapGhNegative20 = "-20px";
export const NumberGapGhNegative24 = "-24px";
export const NumberGapGhNegative28 = "-28px";
export const NumberGapGhNegative32 = "-32px";
export const NumberGapGhNegative36 = "-36px";
export const NumberGapGhNegative40 = "-40px";
export const NumberGapGv0 = "0px";
export const NumberGapGv4 = "4px";
export const NumberGapGv8 = "8px";
export const NumberGapGv12 = "12px";
export const NumberGapGv16 = "16px";
export const NumberGapGv20 = "20px";
export const NumberGapGv24 = "24px";
export const NumberGapGv28 = "28px";
export const NumberGapGv32 = "32px";
export const NumberGapGv36 = "36px";
export const NumberGapGv40 = "40px";
export const NumberGapGv44 = "44px";
export const NumberGapGv48 = "48px";
export const NumberGapGv52 = "52px";
export const NumberGapGv56 = "56px";
export const NumberGapGv60 = "60px";
export const NumberGapGv64 = "64px";
export const NumberGapGv68 = "68px";
export const NumberGapGv72 = "72px";
export const NumberGapGv76 = "76px";
export const NumberGapGv80 = "80px";
export const NumberGapGv84 = "84px";
export const NumberGapGv88 = "88px";
export const NumberGapGv92 = "92px";
export const NumberGapGv96 = "96px";
export const NumberGapGv100 = "100px";
export const NumberGapGv104 = "104px";
export const NumberGapGv108 = "108px";
export const NumberGapGv112 = "112px";
export const NumberGapGv116 = "116px";
export const NumberGapGv120 = "120px";
export const NumberGapGvNegative4 = "-4px";
export const NumberGapGvNegative8 = "-8px";
export const NumberGapGvNegative12 = "-12px";
export const NumberGapGvNegative16 = "-16px";
export const NumberGapGvNegative20 = "-20px";
export const NumberGapGvNegative24 = "-24px";
export const NumberGapGvNegative28 = "-28px";
export const NumberGapGvNegative32 = "-32px";
export const NumberGapGvNegative36 = "-36px";
export const NumberGapGvNegative40 = "-40px";
export const NumberPaddingP0 = "0px";
export const NumberPaddingP4 = "4px";
export const NumberPaddingP8 = "8px";
export const NumberPaddingP12 = "12px";
export const NumberPaddingP16 = "16px";
export const NumberPaddingP20 = "20px";
export const NumberPaddingP24 = "24px";
export const NumberPaddingP28 = "28px";
export const NumberPaddingP32 = "32px";
export const NumberPaddingP36 = "36px";
export const NumberPaddingP40 = "40px";
export const NumberPaddingP44 = "44px";
export const NumberPaddingP48 = "48px";
export const NumberPaddingP52 = "52px";
export const NumberPaddingP56 = "56px";
export const NumberPaddingP60 = "60px";
export const NumberPaddingP64 = "64px";
export const NumberPaddingP68 = "68px";
export const NumberPaddingP72 = "72px";
export const NumberPaddingP76 = "76px";
export const NumberPaddingP80 = "80px";
export const NumberPaddingP84 = "84px";
export const NumberPaddingP88 = "88px";
export const NumberPaddingP92 = "92px";
export const NumberPaddingP96 = "96px";
export const NumberPaddingP100 = "100px";
export const NumberPaddingP104 = "104px";
export const NumberPaddingP108 = "108px";
export const NumberPaddingP112 = "112px";
export const NumberPaddingP116 = "116px";
export const NumberPaddingP120 = "120px";
export const NumberPaddingP124 = "124px";
export const NumberPaddingP128 = "128px";
export const NumberPaddingP132 = "132px";
export const NumberPaddingP136 = "136px";
export const NumberPaddingP140 = "140px";
export const NumberPaddingP144 = "144px";
export const NumberPaddingP148 = "148px";
export const NumberPaddingP152 = "152px";
export const NumberPaddingP156 = "156px";
export const NumberPaddingP160 = "160px";
export const NumberPaddingP164 = "164px";
export const NumberPaddingP168 = "168px";
export const NumberPaddingP172 = "172px";
export const NumberPaddingP176 = "178px";
export const NumberPaddingP180 = "180px";
export const NumberPaddingP184 = "184px";
export const NumberPaddingP190 = "190px";
export const NumberPaddingP194 = "194px";
export const NumberPaddingP198 = "198px";
export const NumberPaddingP200 = "200px";
export const NumberPaddingP208 = "208px";
export const NumberPaddingP216 = "216px";
export const NumberPaddingP224 = "224px";
export const NumberPaddingP232 = "232px";
export const NumberPaddingP240 = "240px";
export const NumberPaddingP248 = "248px";
export const NumberPaddingP256 = "256px";
export const NumberPaddingP264 = "264px";
export const NumberPaddingP272 = "272px";
export const NumberPaddingP280 = "280px";
export const NumberPaddingP288 = "288px";
export const NumberPaddingP296 = "296px";
export const NumberPaddingP304 = "304px";
export const NumberPaddingPh0 = "0px";
export const NumberPaddingPh4 = "4px";
export const NumberPaddingPh8 = "8px";
export const NumberPaddingPh12 = "12px";
export const NumberPaddingPh16 = "16px";
export const NumberPaddingPh20 = "20px";
export const NumberPaddingPh24 = "24px";
export const NumberPaddingPh28 = "28px";
export const NumberPaddingPh32 = "32px";
export const NumberPaddingPh36 = "36px";
export const NumberPaddingPh40 = "40px";
export const NumberPaddingPh44 = "44px";
export const NumberPaddingPh48 = "48px";
export const NumberPaddingPh52 = "52px";
export const NumberPaddingPh56 = "56px";
export const NumberPaddingPh60 = "60px";
export const NumberPaddingPh64 = "64px";
export const NumberPaddingPh68 = "68px";
export const NumberPaddingPh72 = "72px";
export const NumberPaddingPh76 = "76px";
export const NumberPaddingPh80 = "80px";
export const NumberPaddingPh84 = "84px";
export const NumberPaddingPh88 = "88px";
export const NumberPaddingPh92 = "92px";
export const NumberPaddingPh96 = "96px";
export const NumberPaddingPh100 = "100px";
export const NumberPaddingPh104 = "104px";
export const NumberPaddingPh108 = "108px";
export const NumberPaddingPh112 = "112px";
export const NumberPaddingPh116 = "116px";
export const NumberPaddingPh120 = "120px";
export const NumberPaddingPh124 = "124px";
export const NumberPaddingPh128 = "128px";
export const NumberPaddingPh132 = "132px";
export const NumberPaddingPh136 = "136px";
export const NumberPaddingPh140 = "140px";
export const NumberPaddingPh144 = "144px";
export const NumberPaddingPh148 = "148px";
export const NumberPaddingPh152 = "152px";
export const NumberPaddingPh156 = "156px";
export const NumberPaddingPh160 = "160px";
export const NumberPaddingPh164 = "164px";
export const NumberPaddingPh168 = "168px";
export const NumberPaddingPh172 = "172px";
export const NumberPaddingPh176 = "172px";
export const NumberPaddingPh180 = "180px";
export const NumberPaddingPh184 = "184px";
export const NumberPaddingPh190 = "190px";
export const NumberPaddingPh194 = "194px";
export const NumberPaddingPh198 = "198px";
export const NumberPaddingPh200 = "200px";
export const NumberPaddingPh208 = "208px";
export const NumberPaddingPh216 = "216px";
export const NumberPaddingPh224 = "224px";
export const NumberPaddingPh232 = "232px";
export const NumberPaddingPh240 = "240px";
export const NumberPaddingPh248 = "248px";
export const NumberPaddingPh256 = "256px";
export const NumberPaddingPh264 = "264px";
export const NumberPaddingPh272 = "272px";
export const NumberPaddingPh280 = "280px";
export const NumberPaddingPh288 = "288px";
export const NumberPaddingPh296 = "296px";
export const NumberPaddingPh304 = "304px";
export const NumberPaddingPv0 = "0px";
export const NumberPaddingPv4 = "4px";
export const NumberPaddingPv8 = "8px";
export const NumberPaddingPv12 = "12px";
export const NumberPaddingPv16 = "16px";
export const NumberPaddingPv20 = "20px";
export const NumberPaddingPv24 = "24px";
export const NumberPaddingPv28 = "28px";
export const NumberPaddingPv32 = "32px";
export const NumberPaddingPv36 = "36px";
export const NumberPaddingPv40 = "40px";
export const NumberPaddingPv44 = "44px";
export const NumberPaddingPv48 = "48px";
export const NumberPaddingPv52 = "52px";
export const NumberPaddingPv56 = "56px";
export const NumberPaddingPv60 = "60px";
export const NumberPaddingPv64 = "64px";
export const NumberPaddingPv68 = "68px";
export const NumberPaddingPv72 = "72px";
export const NumberPaddingPv76 = "76px";
export const NumberPaddingPv80 = "80px";
export const NumberPaddingPv84 = "84px";
export const NumberPaddingPv88 = "88px";
export const NumberPaddingPv92 = "92px";
export const NumberPaddingPv96 = "96px";
export const NumberPaddingPv100 = "100px";
export const NumberPaddingPv104 = "104px";
export const NumberPaddingPv108 = "108px";
export const NumberPaddingPv112 = "112px";
export const NumberPaddingPv116 = "116px";
export const NumberPaddingPv120 = "120px";
export const NumberPaddingPv124 = "124px";
export const NumberPaddingPv128 = "128px";
export const NumberPaddingPv132 = "132px";
export const NumberPaddingPv136 = "136px";
export const NumberPaddingPv140 = "140px";
export const NumberPaddingPv144 = "144px";
export const NumberPaddingPv148 = "148px";
export const NumberPaddingPv152 = "152px";
export const NumberPaddingPv156 = "156px";
export const NumberPaddingPv160 = "160px";
export const NumberPaddingPv164 = "164px";
export const NumberPaddingPv168 = "168px";
export const NumberPaddingPv172 = "172px";
export const NumberPaddingPv176 = "178px";
export const NumberPaddingPv180 = "180px";
export const NumberPaddingPv184 = "184px";
export const NumberPaddingPv190 = "190px";
export const NumberPaddingPv194 = "194px";
export const NumberPaddingPv198 = "198px";
export const NumberPaddingPv200 = "200px";
export const NumberPaddingPv208 = "208px";
export const NumberPaddingPv216 = "216px";
export const NumberPaddingPv224 = "224px";
export const NumberPaddingPv232 = "232px";
export const NumberPaddingPv240 = "240px";
export const NumberPaddingPv248 = "248px";
export const NumberPaddingPv256 = "256px";
export const NumberPaddingPv264 = "264px";
export const NumberPaddingPv272 = "272px";
export const NumberPaddingPv280 = "280px";
export const NumberPaddingPv288 = "288px";
export const NumberPaddingPv296 = "296px";
export const NumberPaddingPv304 = "304px";
export const NumberPaddingPt0 = "0px";
export const NumberPaddingPt4 = "4px";
export const NumberPaddingPt8 = "8px";
export const NumberPaddingPt12 = "12px";
export const NumberPaddingPt16 = "16px";
export const NumberPaddingPt20 = "20px";
export const NumberPaddingPt24 = "24px";
export const NumberPaddingPt28 = "28px";
export const NumberPaddingPt32 = "32px";
export const NumberPaddingPt36 = "36px";
export const NumberPaddingPt40 = "40px";
export const NumberPaddingPt44 = "44px";
export const NumberPaddingPt48 = "48px";
export const NumberPaddingPt52 = "52px";
export const NumberPaddingPt56 = "56px";
export const NumberPaddingPt60 = "60px";
export const NumberPaddingPt64 = "64px";
export const NumberPaddingPt68 = "68px";
export const NumberPaddingPt72 = "72px";
export const NumberPaddingPt76 = "76px";
export const NumberPaddingPt80 = "80px";
export const NumberPaddingPt84 = "84px";
export const NumberPaddingPt88 = "88px";
export const NumberPaddingPt92 = "92px";
export const NumberPaddingPt96 = "96px";
export const NumberPaddingPt100 = "100px";
export const NumberPaddingPt104 = "104px";
export const NumberPaddingPt108 = "108px";
export const NumberPaddingPt112 = "112px";
export const NumberPaddingPt116 = "116px";
export const NumberPaddingPt120 = "120px";
export const NumberPaddingPt124 = "124px";
export const NumberPaddingPt128 = "128px";
export const NumberPaddingPt132 = "132px";
export const NumberPaddingPt136 = "136px";
export const NumberPaddingPt140 = "140px";
export const NumberPaddingPt144 = "144px";
export const NumberPaddingPt148 = "148px";
export const NumberPaddingPt152 = "152px";
export const NumberPaddingPt156 = "156px";
export const NumberPaddingPt160 = "160px";
export const NumberPaddingPt164 = "164px";
export const NumberPaddingPt168 = "168px";
export const NumberPaddingPt172 = "172px";
export const NumberPaddingPt176 = "178px";
export const NumberPaddingPt180 = "180px";
export const NumberPaddingPt184 = "184px";
export const NumberPaddingPt190 = "190px";
export const NumberPaddingPt194 = "194px";
export const NumberPaddingPt198 = "198px";
export const NumberPaddingPt200 = "200px";
export const NumberPaddingPt208 = "208px";
export const NumberPaddingPt216 = "216px";
export const NumberPaddingPt224 = "224px";
export const NumberPaddingPt232 = "232px";
export const NumberPaddingPt240 = "240px";
export const NumberPaddingPt248 = "248px";
export const NumberPaddingPt256 = "256px";
export const NumberPaddingPt264 = "264px";
export const NumberPaddingPt272 = "272px";
export const NumberPaddingPt280 = "280px";
export const NumberPaddingPt288 = "288px";
export const NumberPaddingPt296 = "296px";
export const NumberPaddingPt304 = "304px";
export const NumberPaddingPb0 = "0px";
export const NumberPaddingPb4 = "4px";
export const NumberPaddingPb8 = "8px";
export const NumberPaddingPb12 = "12px";
export const NumberPaddingPb16 = "16px";
export const NumberPaddingPb20 = "20px";
export const NumberPaddingPb24 = "24px";
export const NumberPaddingPb28 = "28px";
export const NumberPaddingPb32 = "32px";
export const NumberPaddingPb36 = "36px";
export const NumberPaddingPb40 = "40px";
export const NumberPaddingPb44 = "44px";
export const NumberPaddingPb48 = "48px";
export const NumberPaddingPb52 = "52px";
export const NumberPaddingPb56 = "56px";
export const NumberPaddingPb60 = "60px";
export const NumberPaddingPb64 = "64px";
export const NumberPaddingPb68 = "68px";
export const NumberPaddingPb72 = "72px";
export const NumberPaddingPb76 = "76px";
export const NumberPaddingPb80 = "80px";
export const NumberPaddingPb84 = "84px";
export const NumberPaddingPb88 = "88px";
export const NumberPaddingPb92 = "92px";
export const NumberPaddingPb96 = "96px";
export const NumberPaddingPb100 = "100px";
export const NumberPaddingPb104 = "104px";
export const NumberPaddingPb108 = "108px";
export const NumberPaddingPb112 = "112px";
export const NumberPaddingPb116 = "116px";
export const NumberPaddingPb120 = "120px";
export const NumberPaddingPb124 = "124px";
export const NumberPaddingPb128 = "128px";
export const NumberPaddingPb132 = "132px";
export const NumberPaddingPb136 = "136px";
export const NumberPaddingPb140 = "140px";
export const NumberPaddingPb144 = "144px";
export const NumberPaddingPb148 = "148px";
export const NumberPaddingPb152 = "152px";
export const NumberPaddingPb156 = "156px";
export const NumberPaddingPb160 = "160px";
export const NumberPaddingPb164 = "164px";
export const NumberPaddingPb168 = "168px";
export const NumberPaddingPb172 = "172px";
export const NumberPaddingPb176 = "178px";
export const NumberPaddingPb180 = "180px";
export const NumberPaddingPb184 = "184px";
export const NumberPaddingPb190 = "190px";
export const NumberPaddingPb194 = "194px";
export const NumberPaddingPb198 = "198px";
export const NumberPaddingPb200 = "200px";
export const NumberPaddingPb208 = "208px";
export const NumberPaddingPb216 = "216px";
export const NumberPaddingPb224 = "224px";
export const NumberPaddingPb232 = "232px";
export const NumberPaddingPb240 = "240px";
export const NumberPaddingPb248 = "248px";
export const NumberPaddingPb256 = "256px";
export const NumberPaddingPb264 = "264px";
export const NumberPaddingPb272 = "272px";
export const NumberPaddingPb280 = "280px";
export const NumberPaddingPb288 = "288px";
export const NumberPaddingPb296 = "296px";
export const NumberPaddingPb304 = "304px";
export const NumberPaddingPl0 = "0px";
export const NumberPaddingPl4 = "4px";
export const NumberPaddingPl8 = "8px";
export const NumberPaddingPl12 = "12px";
export const NumberPaddingPl16 = "16px";
export const NumberPaddingPl20 = "20px";
export const NumberPaddingPl24 = "24px";
export const NumberPaddingPl28 = "28px";
export const NumberPaddingPl32 = "32px";
export const NumberPaddingPl36 = "36px";
export const NumberPaddingPl40 = "40px";
export const NumberPaddingPl44 = "44px";
export const NumberPaddingPl48 = "48px";
export const NumberPaddingPl52 = "52px";
export const NumberPaddingPl56 = "56px";
export const NumberPaddingPl60 = "60px";
export const NumberPaddingPl64 = "64px";
export const NumberPaddingPl68 = "68px";
export const NumberPaddingPl72 = "72px";
export const NumberPaddingPl76 = "76px";
export const NumberPaddingPl80 = "80px";
export const NumberPaddingPl84 = "84px";
export const NumberPaddingPl88 = "88px";
export const NumberPaddingPl92 = "92px";
export const NumberPaddingPl96 = "96px";
export const NumberPaddingPl100 = "100px";
export const NumberPaddingPl104 = "104px";
export const NumberPaddingPl108 = "108px";
export const NumberPaddingPl112 = "112px";
export const NumberPaddingPl116 = "116px";
export const NumberPaddingPl120 = "120px";
export const NumberPaddingPl124 = "124px";
export const NumberPaddingPl128 = "128px";
export const NumberPaddingPl132 = "132px";
export const NumberPaddingPl136 = "136px";
export const NumberPaddingPl140 = "140px";
export const NumberPaddingPl144 = "144px";
export const NumberPaddingPl148 = "148px";
export const NumberPaddingPl152 = "152px";
export const NumberPaddingPl156 = "156px";
export const NumberPaddingPl160 = "160px";
export const NumberPaddingPl164 = "164px";
export const NumberPaddingPl168 = "168px";
export const NumberPaddingPl172 = "172px";
export const NumberPaddingPl176 = "178px";
export const NumberPaddingPl180 = "180px";
export const NumberPaddingPl184 = "184px";
export const NumberPaddingPl190 = "190px";
export const NumberPaddingPl194 = "194px";
export const NumberPaddingPl198 = "198px";
export const NumberPaddingPl200 = "200px";
export const NumberPaddingPl208 = "208px";
export const NumberPaddingPl216 = "216px";
export const NumberPaddingPl224 = "224px";
export const NumberPaddingPl232 = "232px";
export const NumberPaddingPl240 = "240px";
export const NumberPaddingPl248 = "248px";
export const NumberPaddingPl256 = "256px";
export const NumberPaddingPl264 = "264px";
export const NumberPaddingPl272 = "272px";
export const NumberPaddingPl280 = "280px";
export const NumberPaddingPl288 = "288px";
export const NumberPaddingPl296 = "296px";
export const NumberPaddingPl304 = "304px";
export const NumberPaddingPr0 = "0px";
export const NumberPaddingPr4 = "4px";
export const NumberPaddingPr8 = "8px";
export const NumberPaddingPr12 = "12px";
export const NumberPaddingPr16 = "16px";
export const NumberPaddingPr20 = "20px";
export const NumberPaddingPr24 = "24px";
export const NumberPaddingPr28 = "28px";
export const NumberPaddingPr32 = "32px";
export const NumberPaddingPr36 = "36px";
export const NumberPaddingPr40 = "40px";
export const NumberPaddingPr44 = "44px";
export const NumberPaddingPr48 = "48px";
export const NumberPaddingPr52 = "52px";
export const NumberPaddingPr56 = "56px";
export const NumberPaddingPr60 = "60px";
export const NumberPaddingPr64 = "64px";
export const NumberPaddingPr68 = "68px";
export const NumberPaddingPr72 = "72px";
export const NumberPaddingPr76 = "76px";
export const NumberPaddingPr80 = "80px";
export const NumberPaddingPr84 = "84px";
export const NumberPaddingPr88 = "88px";
export const NumberPaddingPr92 = "92px";
export const NumberPaddingPr96 = "96px";
export const NumberPaddingPr100 = "100px";
export const NumberPaddingPr104 = "104px";
export const NumberPaddingPr108 = "108px";
export const NumberPaddingPr112 = "112px";
export const NumberPaddingPr116 = "116px";
export const NumberPaddingPr120 = "120px";
export const NumberPaddingPr124 = "124px";
export const NumberPaddingPr128 = "128px";
export const NumberPaddingPr132 = "132px";
export const NumberPaddingPr136 = "136px";
export const NumberPaddingPr140 = "140px";
export const NumberPaddingPr144 = "144px";
export const NumberPaddingPr148 = "148px";
export const NumberPaddingPr152 = "152px";
export const NumberPaddingPr156 = "156px";
export const NumberPaddingPr160 = "160px";
export const NumberPaddingPr164 = "164px";
export const NumberPaddingPr168 = "168px";
export const NumberPaddingPr172 = "172px";
export const NumberPaddingPr176 = "178px";
export const NumberPaddingPr180 = "180px";
export const NumberPaddingPr184 = "184px";
export const NumberPaddingPr190 = "190px";
export const NumberPaddingPr194 = "194px";
export const NumberPaddingPr198 = "198px";
export const NumberPaddingPr200 = "200px";
export const NumberPaddingPr208 = "208px";
export const NumberPaddingPr216 = "216px";
export const NumberPaddingPr224 = "224px";
export const NumberPaddingPr232 = "232px";
export const NumberPaddingPr240 = "240px";
export const NumberPaddingPr248 = "248px";
export const NumberPaddingPr256 = "256px";
export const NumberPaddingPr264 = "264px";
export const NumberPaddingPr272 = "272px";
export const NumberPaddingPr280 = "280px";
export const NumberPaddingPr288 = "288px";
export const NumberPaddingPr296 = "296px";
export const NumberPaddingPr304 = "304px";
export const NumberPaddingPe2 = "2px";
export const NumberPaddingPe6 = "6px";
export const NumberPaddingPe10 = "10px";
export const NumberPaddingPhe2 = "2px";
export const NumberPaddingPhe6 = "6px";
export const NumberPaddingPhe10 = "10px";
export const NumberPaddingPve2 = "2px";
export const NumberPaddingPve6 = "6px";
export const NumberPaddingPve10 = "10px";
export const NumberPaddingPte2 = "2px";
export const NumberPaddingPte6 = "6px";
export const NumberPaddingPte10 = "10px";
export const NumberPaddingPbe2 = "2px";
export const NumberPaddingPbe6 = "6px";
export const NumberPaddingPbe10 = "10px";
export const NumberPaddingPle2 = "2px";
export const NumberPaddingPle6 = "6px";
export const NumberPaddingPle10 = "10px";
export const NumberPaddingPre2 = "2px";
export const NumberPaddingPre6 = "6px";
export const NumberPaddingPre10 = "10px";
export const NumberStrokeS10 = "1px";
export const NumberStrokeS12 = "1.2px";
export const NumberStrokeS14 = "1.4px";
export const NumberStrokeS16 = "1.6px";
export const NumberStrokeS18 = "1.8px";
export const NumberStrokeS20 = "2px";
export const NumberStrokeS30 = "3px";
export const NumberStrokeS40 = "4px";
export const NumberStrokeS50 = "5px";
