import { Post, PostSwitchAction } from '@vkph/components';
import {
  message,
  UiDivider,
  UiEmpty,
  UiRender,
  UiRenderType,
  UiSpace,
  UiTag,
  useBreakpoint,
  useModalBase,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getPostAttachmentsStorage } from '@vkph/common/store/attachment';
import { reactionEffect } from '@vkph/common/store/reactions';
import { postReactionsContentTypeMap } from '@vkph/common/store/reactions/constants';
import { isBasicPost, isBlogPost } from '@vkph/common/types/guards';
import { LongreadBaseProps, PostLongreadParams } from '@vkph/common/types/longread';
import { EmojiUuid, PostStatuses, PostTypes } from '@vkph/common/types/models';
import { capitalizeFirstLetter, getErrorResponseMessage } from '@vkph/common/utils';
import { getPostBlogsMap } from '@vkph/components/utils';
import { useSpace } from '@vkph/ui/hooks';

import { deletePostEffect, getSinglePostStorage } from '../../../store/post';
import { OnAttachmentUpdateParams, PostAttachmentsAdapter } from '../../post/attachments/adapter';
import { PostCompound } from '../../post/compound';
import { LongreadComments } from '../comments/LongreadComments';
import { LongreadLoading } from '../loading/LongreadLoading';

type Props = LongreadBaseProps & PostLongreadParams;

export const PostLongread: FC<Props> = (props) => {
  const { id, postType, onClose } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const isDeletePostPending = useStore(deletePostEffect.pending);
  const { warning } = useModalBase();

  const postStorage = useMemo(getSinglePostStorage, []);
  const postAttachmentsStorage = useMemo(
    () => getPostAttachmentsStorage({ postId: id, postType }),
    [id, postType],
  );

  const { spaceM, space4XL, space2XL } = useSpace();
  const { lg: isLayoutLarge } = useBreakpoint();

  const { reloadAttachmentByIdEffect } = postAttachmentsStorage;
  const { updateCommentsCountEvent, switchFavoritePostEffect } = postStorage;

  const {
    data: post,
    loading: isPostLoading,
    error,
  } = useAbstractStorage(postStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { postId: id, postType },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const { data: postAttachments, loading: isPostAttachmentsLoading } = useAbstractStorage(
    postAttachmentsStorage.storage,
    {
      autoFetchAndRefetch: true,
      cancelPendingRequestOnUnmount: true,
    },
  );

  const onPostEdit = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const onPostDelete = useCallback(() => {
    const postName = postType === PostTypes.Micropost ? 'микропост' : 'пост';

    warning({
      title: `Удалить ${postName}`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      disabled: isDeletePostPending,
      onOk: async (confirmClose) => {
        try {
          await deletePostEffect({ postId: id, postType });
          confirmClose();
          onClose();
          message.success(`${capitalizeFirstLetter(postName)} удален`);
        } catch (e) {
          message.error(getErrorResponseMessage(e, `Ошибка удаления ${postName}`));
        }
      },
    });
  }, [id, postType, deletePostEffect]);

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      const contentType = post && postReactionsContentTypeMap[post.type];

      if (contentType) {
        reactionEffect({ objectId: post.id, contentType, emojiUuid }).catch((e) =>
          message.error(
            getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
          ),
        );
      }
    },
    [post, postType],
  );

  const onPostFavoriteSwitch: PostSwitchAction = useCallback(
    (isFavorite) => {
      switchFavoritePostEffect({ postId: id, postType, favorite: isFavorite }).catch(() =>
        message.error('Не удалось добавить пост в избранное'),
      );
    },
    [id, postType, switchFavoritePostEffect],
  );

  const onAttachmentReload = useCallback(
    ({ attachmentId }: OnAttachmentUpdateParams) => reloadAttachmentByIdEffect({ attachmentId }),
    [reloadAttachmentByIdEffect],
  );

  const tags = post ? post.tags : [];

  useEffect(() => {
    if (error) {
      onClose();
      message.error(getErrorResponseMessage(error, `Ошибка. Поста с идентификатором ${id} не существует`));
    }
  }, [error]);

  const postPadding = isLayoutLarge ? space4XL : spaceM;

  const blogs = post && isBlogPost(post) ? post?.blogs : [];
  const isCommentsDisabledByBlog = useMemo(() => {
    return Array.isArray(blogs) ? blogs.some((blog) => !blog.isComments) : !blogs?.isComments;
  }, [blogs]);

  const isPostEntry = post && isBasicPost(post);

  const isCommentsDisabledBySettings = isPostEntry && !post?.settings?.isComments;
  const isCommentsDisabled = isCommentsDisabledByBlog || isCommentsDisabledBySettings;

  const isLoading = isPostLoading || isPostAttachmentsLoading;
  const isError = (!post && !isLoading) || error;

  const postBlogs = useMemo(() => {
    return post ? getPostBlogsMap(post) : [];
  }, [post]);

  const isPremoderation =
    post && [PostStatuses.ApproveInProgress, PostStatuses.AwaitingApprove].includes(post?.flag);

  if (isError) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Пост не загрузился' }} />;
  }

  if (isLoading) {
    return <LongreadLoading />;
  }

  return (
    <>
      {isPostEntry && isEditMode && (
        <PostCompound.Edit
          onCancel={() => setIsEditMode(false)}
          post={post}
          postType={post.type}
          attachments={postAttachments}
        />
      )}
      {isPostEntry && !isEditMode && (
        <Post style={{ paddingTop: isLayoutLarge ? space2XL : spaceM }}>
          <Post.Header
            blogs={postBlogs}
            author={post.author}
            postDate={post.publishedAt || post.createdAt}
            padding={[0, postPadding]}
          >
            <UiSpace>
              <Post.Header.Actions
                onEdit={onPostEdit}
                post={post}
                onFavorite={onPostFavoriteSwitch}
                onDelete={onPostDelete}
              />
            </UiSpace>
          </Post.Header>
          <Post.Body padding={[spaceM, postPadding]}>
            <UiSpace size="large" full direction="vertical">
              <Post.Body.Markup post={post} />
              {tags.length > 0 && <UiTag.Group tags={tags} />}
              {postAttachments.length > 0 && (
                <Post.Body.Attachments
                  attachments={postAttachments}
                  renderItem={(item) => <PostAttachmentsAdapter value={item} onUpdate={onAttachmentReload} />}
                />
              )}
              {!isPremoderation && <Post.Body.Reactions post={post} onReaction={onReaction} />}
            </UiSpace>
          </Post.Body>
          <UiRender
            type={UiRenderType.NullElement}
            visible={!isCommentsDisabled && isLayoutLarge && !isPremoderation}
          >
            <UiDivider emptyMargin />
            <Post.Footer>
              <LongreadComments
                style={{ paddingLeft: 48, paddingRight: 48 }}
                post={post}
                onCommentsCountUpdate={updateCommentsCountEvent}
                showInput={Boolean(post.author.isActive)}
              />
            </Post.Footer>
          </UiRender>
        </Post>
      )}
    </>
  );
};
