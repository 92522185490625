import {
  UiButton,
  UiDrawer,
  UiForm,
  UiInput,
  UiModal,
  UiModalProps,
  UiModalTypes,
  UiSpace,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { normalizeValueTrimStart } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

export interface CreateFolderModalProps extends Pick<UiModalProps, 'onClose' | 'isOpen'> {
  existingItemName?: string;
  title: string;
  inputPlaceholder: string;
  onApply: (name: string) => void;
  loading: boolean;
}

type FormValues = {
  name: string;
};

const MAX_NAME_LENGTH = 100;

export const RenameFolderDialog: FC<CreateFolderModalProps> = (props) => {
  const { onClose, isOpen, existingItemName = '', title, onApply, loading, inputPlaceholder } = props;
  const [form] = UiForm.useForm<FormValues>();

  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();
  const paddingStyle = usePaddingStyle([0, spaceXL]);

  const onFormFinish = ({ name }: FormValues) => {
    if (name) {
      onApply(name.trim());
    }
  };

  useEffect(() => {
    form.setFieldsValue({ name: existingItemName });
  }, [isLayoutLarge, existingItemName]);

  const content = (
    <UiForm.Item noStyle name="name" normalize={normalizeValueTrimStart} shouldUpdate>
      <UiInput size="large" placeholder={inputPlaceholder} autoFocus maxLength={MAX_NAME_LENGTH} />
    </UiForm.Item>
  );

  const buttons = (
    <>
      <UiButton
        block
        disabled={loading}
        type="primary"
        size="large"
        onClick={form.submit}
        label="Сохранить"
      />
      <UiButton block disabled={loading} type="secondary" size="large" onClick={onClose} label="Отмена" />
    </>
  );

  return (
    <>
      {isLayoutLarge && (
        <UiForm form={form} onFinish={onFormFinish} clearOnDestroy>
          <UiModal type={UiModalTypes.Small} isOpen={isOpen} onClose={onClose}>
            <UiModal.Header>
              <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
            </UiModal.Header>
            <UiModal.Content style={paddingStyle}>{content}</UiModal.Content>
            <UiModal.Footer hasTopBorder={false}>
              <UiModal.Footer.Buttons>{buttons}</UiModal.Footer.Buttons>
            </UiModal.Footer>
          </UiModal>
        </UiForm>
      )}
      {!isLayoutLarge && (
        <UiForm form={form} onFinish={onFormFinish} clearOnDestroy>
          <UiDrawer
            open={isOpen}
            onClose={onClose}
            placement="bottom"
            title={title}
            footer={
              <UiSpace direction="vertical" size={spaceM} full style={{ marginTop: spaceM }}>
                {buttons}
              </UiSpace>
            }
          >
            {content}
          </UiDrawer>
        </UiForm>
      )}
    </>
  );
};
