import { Notification } from '@vkph/components';
import { UiAvatarProps, UiIcon, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CalendarEventModel,
  CalendarViewType,
  NotificationModel,
  NotificationTypes,
} from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath, utcToLocalDate } from '@vkph/common/utils';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { isNotificationType } from '../../../../types/guards/notification-guards';
import { getNotificationDescription } from '../../NotificationsDrawer';
import { CalendarEventActions } from './actions/CalendarEventActions';

interface Props {
  notification: NotificationModel<CalendarEventModel>;
}

export const NotificationCalendarEvent: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
  } = notification;
  const { since, summary } = content;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const eventStartTime = useMemo(() => utcToLocalDate(since), [since]);
  const onClick = () => {
    return navigate(
      { pathname: getRoutePath(RouteNames.Calendar), search: stringify({ tab: CalendarViewType.Day }) },
      { state: { date: since } },
    );
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <UiSpace size={4} direction="vertical">
            <UiTypography.Link strong onClick={onClick}>
              <UiTruncateMarkup truncate lines={2}>
                {summary}
              </UiTruncateMarkup>
            </UiTypography.Link>
            {eventStartTime && <UiIcon.Label component={CalendarSvg} label={eventStartTime} />}
          </UiSpace>
          {isNotificationType(notification.notificationType, NotificationTypes.CalendarGroupEventInvite) && (
            <CalendarEventActions notification={notification} />
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
