import {
  UiButton,
  UiCard,
  UiEmpty,
  UiIcon,
  UiMenuItemTypes,
  UiMenu,
  UiModal,
  UiModalTypes,
  UiSkeleton,
  UiSpace,
  message,
  useBreakpoint,
  notification,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';

import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageContextServiceTypes } from '@vkph/common/types/models';
import { createArrayMock, getErrorResponseMessage } from '@vkph/common/utils';
import { useClipboard, usePaddingStyle, useSpace, useToggle } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import CopySvg from '@vkph/ui/svg/copy.svg';
import FavoriteAddSvg from '@vkph/ui/svg/favorite-add.svg';
import FavoriteCheckedSvg from '@vkph/ui/svg/favorite-checked.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';

import { FileActionsShareModal } from './share-modal/FileActionsShareModal';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
};

const MOCK_COUNT = 2;
const actionsPendingMock = createArrayMock(MOCK_COUNT, (_, index) => (
  <UiSkeleton key={index} loading height={38} style={{ margin: '0 16px' }} block width={329} />
));

const SHAREABLE_CONTEXT_LIST: FileStorageContextServiceTypes[] = [
  FileStorageContextServiceTypes.Blogs,
  FileStorageContextServiceTypes.FileStorage,
];

export const FileActions: FC<Props> = (props) => {
  const { fileInfoStorage } = props;
  const { storage: fileInfoStorageInstance, toggleFavoriteEffect } = fileInfoStorage;
  const { data: fileInfoData, error: fileInfoError } = useStore(fileInfoStorageInstance.store);
  const isFileInfoPending = useStore(fileInfoStorageInstance.fetchEffect.pending);
  const { lg: isLayoutLarge } = useBreakpoint();
  const { setCopied } = useClipboard(window.location.href, {
    onSuccess: () => notification.success({ message: 'Ссылка скопирована' }),
  });
  const { spaceM } = useSpace();
  const paddingStyle = usePaddingStyle([spaceM, 0]);

  const [isShareModalOpen, toggleIsShareModalOpen] = useToggle([false, true]);

  const isShareable = useMemo(() => {
    if (fileInfoData?.context?.service) {
      return SHAREABLE_CONTEXT_LIST.includes(fileInfoData.context.service);
    }

    return false;
  }, [fileInfoData?.context?.service]);

  const onToggleFavorite = useCallback(() => {
    if (fileInfoData) {
      const { id, isFavorite } = fileInfoData;

      toggleFavoriteEffect({ id, isFavorite })
        .then(() => message.success(isFavorite ? 'Удалено из избранного' : 'Добавлено в избранное'))
        .catch((e) =>
          message.error(
            getErrorResponseMessage(
              e,
              `${isFavorite ? 'Ошибка удаления из избранного' : 'Ошибка добавления в избранное'}`,
            ),
          ),
        );
    }
  }, [fileInfoStorage, fileInfoData]);

  const items = useMemo<UiMenuItemTypes[]>(() => {
    if (fileInfoData) {
      const { isFavorite } = fileInfoData;
      const resultItems = [
        {
          key: 'copyUrl',
          label: 'Скопировать ссылку',
          icon: <UiIcon component={CopySvg} width={20} height={20} />,
          extraIcon: null,
          onClick: setCopied,
        },
        {
          key: 'favorite',
          label: 'Избранное',
          icon: (
            <UiIcon
              color={isFavorite ? variables.themePalette.colorWarning : variables.themePalette.colorIcon}
              component={isFavorite ? FavoriteCheckedSvg : FavoriteAddSvg}
              width={20}
              height={20}
            />
          ),
          extraIcon: null,
          onClick: onToggleFavorite,
        },
      ];

      if (isShareable && !isLayoutLarge) {
        resultItems.push({
          key: 'share',
          label: 'Поделиться',
          icon: <UiIcon component={ShareSvg} width={20} height={20} />,
          extraIcon: null,
          onClick: toggleIsShareModalOpen,
        });
      }

      return resultItems;
    }

    return [];
  }, [setCopied, onToggleFavorite, toggleIsShareModalOpen, fileInfoData, isLayoutLarge]);

  return (
    <>
      <UiCard emptyPadding emptyRadius={!isLayoutLarge} size="default">
        <UiCard.Content style={paddingStyle}>
          {!isFileInfoPending && (!fileInfoData || fileInfoError) && (
            <UiEmpty.Feed emptyMessage={{ header: 'Нет доступных действий' }} />
          )}
          {isFileInfoPending && (
            <UiSpace direction="vertical" size={2}>
              {actionsPendingMock}
            </UiSpace>
          )}
          {!isFileInfoPending && <UiMenu strong selectable={false} items={items} />}
        </UiCard.Content>
        {isShareable && isLayoutLarge && (
          <UiCard.Footer style={{ paddingTop: 0 }}>
            <UiButton
              icon={<UiIcon component={ShareSvg} />}
              block
              size="large"
              type="primary"
              label="Поделиться файлом"
              onClick={() => toggleIsShareModalOpen()}
            />
          </UiCard.Footer>
        )}
      </UiCard>
      <UiModal type={UiModalTypes.Medium} isOpen={isShareModalOpen} onClose={toggleIsShareModalOpen}>
        {fileInfoData && <FileActionsShareModal fileInfo={fileInfoData} onClose={toggleIsShareModalOpen} />}
      </UiModal>
    </>
  );
};
