import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { useDnDDroppable, UseDnDDroppableProps } from '../hooks';
import styles from './UiDnDDroppable.scss';

export type UiDnDDroppableProps = UseDnDDroppableProps<unknown>;

export const UiDnDDroppable: FC<PropsWithChildren<UiDnDDroppableProps>> = (props) => {
  const { id, disabled, children } = props;
  const { setNodeRef, isOver } = useDnDDroppable({
    disabled,
    id,
  });

  return (
    <div
      className={classNames(
        styles.uiDndDroppable,
        isOver && styles.uiDndDroppable_active,
        disabled && styles.uiDndDroppable_disabled,
      )}
      ref={setNodeRef}
    >
      {children}
    </div>
  );
};
