import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NotificationBlogEntryReactionModel, NotificationModel, PostTypes } from '@vkph/common/types/models';
import {
  RouteNames,
  getFullNameWithoutPatronymic,
  getPlainTextFromHtml,
  getRoutePath,
  parseMentionsToPlainText,
} from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../NotificationsDrawer';

interface Props {
  notification: NotificationModel<NotificationBlogEntryReactionModel>;
}

export const NotificationBlogEntryReaction: FC<Props> = (props) => {
  const { notification } = props;
  const {
    status,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
  } = notification;

  const description = getNotificationDescription(notification);
  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  const { entry } = notification.content;
  const { id, body } = entry;

  const { to, state } = useLongreadLink({ id, type: LongreadTypes.Post, postType: PostTypes.Entry });
  const plainTextContent = body?.data && getPlainTextFromHtml(body.data);
  const postText = (plainTextContent && parseMentionsToPlainText(plainTextContent)) || 'Открыть пост';

  return (
    <Notification status={status}>
      <Notification.Header
        title={title}
        subtitle={description}
        avatarProps={avatar}
        to={userLink}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <RouterLink to={to} state={state}>
            <UiTruncateMarkup lines={2} truncate>
              {postText}
            </UiTruncateMarkup>
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
