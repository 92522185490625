import { endpoints } from '../../endpoints';
import { FileStorageEntryId, FileStorageObjectModel, FileLogoVariants } from '../../types/models';
import { api } from '../../utils';

export type FileLogoScheme<T> = {
  fileStorageObject: T | null;
  type: FileLogoVariants;
};

export type HeaderLogoScheme<T> = Record<FileLogoVariants, T | null>;

export type HeaderLogoImageData = HeaderLogoScheme<FileStorageEntryId>;
export type HeaderLogoImageModel = HeaderLogoScheme<FileStorageObjectModel>;

export type FileSchemeLogoResponse = FileLogoScheme<FileStorageObjectModel>[];
export type FileSchemeLogoUpdate = FileLogoScheme<FileStorageEntryId>[];

export const updateHeaderLogoImage = (data: FileSchemeLogoUpdate) => {
  return api.post<FileSchemeLogoResponse>({
    url: endpoints.pages.image(),
    data,
  });
};

export const deleteHeaderLogoImage = () =>
  api.delete<FileSchemeLogoResponse>({
    url: endpoints.pages.image(),
  });
