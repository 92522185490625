import { Notification } from '@vkph/components';
import { UiAvatarProps, UiButton, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NotificationModel, PostNewsModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../NotificationsDrawer';

interface Props {
  notification: NotificationModel<PostNewsModel>;
}

export const NotificationNewsMention: FC<Props> = (props) => {
  const { notification } = props;
  const { content, createdAt, initiator, image } = notification;
  const { title, id, type } = content;
  const { fullName, keycloakId, isActive } = initiator;

  const { openLongread } = useLongreadNavigation();
  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const onOpenLongread = () => {
    openLongread({ type: LongreadTypes.News, id, newsType: type });
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace direction="vertical" full>
          <UiButton type="link" onClick={onOpenLongread} label={title} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
