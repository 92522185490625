import { UiFlex, UiForm, UiInput, useSpace } from '@vkph/ui';
import React, { FC, useRef } from 'react';

import { MAX_LENGTH_INPUT_2083, getCharactersLeft } from '@vkph/common/utils';

export const HyperlinkFormFields: FC = () => {
  const { spaceXL } = useSpace();
  const ref = useRef<HTMLDivElement>(null);

  const rules = [
    {
      max: MAX_LENGTH_INPUT_2083,
      message: 'Пожалуйста, введите сокращённый URL',
    },
  ];

  return (
    <UiFlex gap={spaceXL} vertical ref={ref}>
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <UiForm.Item
            name="default"
            label="Данные ячейки для вставки во все ячейки столбца"
            style={{ marginBottom: 0 }}
            rules={rules}
            extra={getCharactersLeft(getFieldValue('default')?.length ?? 0, MAX_LENGTH_INPUT_2083)}
          >
            <UiInput maxLength={MAX_LENGTH_INPUT_2083} />
          </UiForm.Item>
        )}
      </UiForm.Item>
    </UiFlex>
  );
};
