import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { EventModel, SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

export const getSearchEventStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<EventModel>, EventModel[], EventModel[], SearchParams>(
    {
      endpointBuilder: () => buildEndpointWithQueryParams(endpoints.events.eventsSearch()),
      defaultValue: [],
      dataMapper: ({ items }) => items,
      paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
      cancelPendingRequestOnFetch: true,
      dataBuilder: (params) => params,
      requestMethod: 'post',
    },
  );

  return { storage };
};
