import { ErrorBoundary } from '@vkph/components';
import { Layout, useBreakpoint } from '@vkph/ui';
import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAbstractStorage, useLocalStorage } from '@vkph/common/hooks';
import {
  currentProfileRolesPermissionsStorage,
  useCurrentProfile,
  usePrivateRoute,
} from '@vkph/common/providers';
import { ServiceRolesNames } from '@vkph/common/types';
import { Header } from '@vkph/modules/components/header';
import { HeaderErrorFallback } from '@vkph/modules/components/header/error';
import { Navbar } from '@vkph/modules/components/navbar';

export const PrivateRoute: FC = () => {
  const { id: currentProfileId } = useCurrentProfile();
  const [isNavbarHidden] = useLocalStorage('isNavbarHidden', false);
  const { setIsPrivateRoute } = usePrivateRoute();
  const breakpoints = useBreakpoint();

  const navbar = <Navbar />;
  const hasNavbar = (breakpoints.xl && !isNavbarHidden) || !breakpoints.lg;

  useEffect(() => {
    setIsPrivateRoute(true);
  }, []);

  const { loading: isCurrentProfileRolesPermissionsLoading } = useAbstractStorage(
    currentProfileRolesPermissionsStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userId: currentProfileId,
        groupBy: ServiceRolesNames.Events,
        serviceName: Object.values(ServiceRolesNames),
      },
    },
  );

  if (isCurrentProfileRolesPermissionsLoading) {
    return <Layout.Loading />;
  }

  return (
    <Layout
      header={
        <ErrorBoundary FallbackComponent={HeaderErrorFallback}>
          <Header navbar={navbar} />
        </ErrorBoundary>
      }
      navbar={hasNavbar ? navbar : null}
    >
      <Outlet />
    </Layout>
  );
};
