import { Post } from '@vkph/components';
import { UiEmpty } from '@vkph/ui';
import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { useSettingsConfig } from '@vkph/common/providers';

type ErrorBoundaryFallbackProps = FallbackProps;

export const ErrorBoundaryFallbackTimeline: FC<ErrorBoundaryFallbackProps> = () => {
  const { config } = useSettingsConfig();

  if (config.layouts?.timelinesErrorPostHide?.value) return null;

  return (
    <Post>
      <UiEmpty.Feed
        emptyMessage={{
          header: 'Ошибка',
          description: 'Невозможно отобразить пост',
        }}
      />
    </Post>
  );
};
