import { ServiceRolesGroupDataModel } from '../roles';
import { UserModel } from './user.model';

export type PermissionsId = number;
export type PermissionModel<T> = {
  description: string;
  name: T;
};
export interface PermissionsModel extends PermissionModel<string> {
  id: PermissionsId;
}

export enum SurveyAllowedPermission {
  CreateSurveys = 'create_surveys',
  ManageCategoriesSurveys = 'manage_categories_surveys',
  ManageAdminsSurveys = 'manage_admins_surveys',
  CreateSendingSurveys = 'create_sending_surveys',
}

export enum NewsAllowedPermission {
  CreateNews = 'create_news',
  ManageAllNews = 'manage_all_news',
  ManageAdmins = 'manage_admins',
  ManageCategories = 'manage_categories',
}

export enum EventsAllowedPermission {
  CreateEvents = 'create_events',
  ManageAdminsEvents = 'manage_admins_events',
  ManageAllEvents = 'manage_all_events',
}

export enum GroupsAllowedPermission {
  ManageGroups = 'manage_groups',
  ManageAdminsGroups = 'manage_admins_groups',
}

export enum SitesAllowedPermission {
  SitectorGlobalRead = 'sitector::global::read',
  SitectorGlobalCreate = 'sitector::global::create',
  SitectorGlobalUpdate = 'sitector::global::update',
  SitectorGlobalDelete = 'sitector::global::delete',

  FilestorageGlobalRead = 'filestorage::global::read',
  FilestorageGlobalCreate = 'filestorage::global::create',
  FilestorageGlobalUpdate = 'filestorage::global::update',
  FilestorageGlobaDelete = 'filestorage::global::delete',

  ListGlobalRead = 'list::global::read',
  ListGlobalCreate = 'list::global::create',
  ListGlobalUpdate = 'list::global::update',
  ListGlobalDelete = 'list::global::delete',

  PageGlobalRead = 'page::global::read',
  PageGlobalCreate = 'page::global::create',
  PageGlobalUpdate = 'page::global::update',
  PageGlobalDelete = 'page::global::delete',

  NewsGlobalRead = 'news::global::read',
  NewsGlobalCreate = 'news::global::create',
  NewsGlobalUpdate = 'news::global::update',
  NewsGlobalDelete = 'news::global::delete',

  CalendarGlobalRead = 'calendar::global::read',
  CalendarGlobalCreate = 'calendar::global::create',
  CalendarGlobalUpdate = 'calendar::global::update',
  CalendarGlobalDelete = 'calendar::global::delete',
}

export enum ProjectsAllowedPermission {
  CategoryEdit = 'category_edit',
  HistoryView = 'history_view',
  ProjectEdit = 'project_edit',
  ProjectView = 'project_view',
  TaskCreate = 'task_create',
  TaskView = 'task_view',
}

export enum IdeasAllowedPermission {
  ManageAdminsIdeas = 'manage_admins_ideas',
  IdeasManage = 'ideas_manage',
  EditCategories = 'ideas_edit_categories',
  EditReviewers = 'ideas_edit_reviewers',
}

export type AllowedPermission =
  | EventsAllowedPermission
  | NewsAllowedPermission
  | GroupsAllowedPermission
  | IdeasAllowedPermission
  | SurveyAllowedPermission
  | SitesAllowedPermission
  | ProjectsAllowedPermission;

export type AllAllowedPermissions = AllowedPermission;

export type PermissionRoleId = number;

export enum PermissionRoles {
  SiteAdmin = 'site_admin',
  Reader = 'reader',
  Editor = 'editor',
}

export type RolePermission = {
  id: PermissionsId;
  name: PermissionRoles;
  displayName: string;
  scope: string;
  subscope: string;
};

export interface PermissionRoleModel extends Omit<RolePermission, 'id'> {
  id: PermissionRoleId;
  isSystemDefined: boolean;
  permissions: RolePermission[];
}

export interface PermissionRoleDetailModel extends PermissionRoleModel {
  users: UserModel[];
  groups: ServiceRolesGroupDataModel[];
  isOverride?: boolean;
}
