import { RouterLink } from '@vkph/components';
import { UiAvatar, UiCol, UiRow, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, ReactNode } from 'react';

import { useLocalStorage } from '@vkph/common/hooks';
import { useCurrentProfile, useSettingsConfig } from '@vkph/common/providers';
import { headerLogoImageStorage } from '@vkph/common/store';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme, variables } from '@vkph/ui/providers/theme';

import { HeaderActions } from '../actions';
import { HeaderEventsButton } from '../events-button';
import { HeaderLogo } from '../logo';
import { HeaderNavbarButton } from '../navbar';
import { HeaderNotificationsButton } from '../notifications-button';
import { HeaderSearch } from '../search/HeaderSearch';

type Props = {
  navbar: ReactNode;
};

export const HeaderDesktop: FC<Props> = (props) => {
  const { navbar } = props;
  const [{ variables: themeVariables }] = useTheme();
  const { spaceS, spaceXS } = useSpace();
  const profile = useCurrentProfile();
  const { config } = useSettingsConfig();
  const breakpoints = useBreakpoint();
  const [isNavbarHidden] = useLocalStorage('isNavbarHidden', false);
  const isEventsButtonEnabled = !config.layouts?.headerEventsMeetingsDropdownButtonDisabled?.value;
  const { data: logoImageData } = useStore(headerLogoImageStorage.storage.store);
  const portalHatImage = logoImageData.portalHat?.file;

  const isTooltip = isNavbarHidden || !breakpoints.xl;

  return (
    <UiCol
      flex="auto"
      style={{
        backgroundImage: `url(${portalHatImage})`,
        paddingLeft: spaceXS,
        paddingRight: spaceS,
      }}
    >
      <UiRow justify="space-between" align="middle" wrap={false} style={{ marginLeft: 6 }} gutter={[20, 0]}>
        {isTooltip && (
          <UiCol>
            <HeaderNavbarButton>{navbar}</HeaderNavbarButton>
          </UiCol>
        )}
        <UiCol>
          <HeaderLogo />
        </UiCol>
        <UiCol flex="auto">
          <HeaderSearch />
        </UiCol>
        <UiCol>
          <UiRow align="middle" wrap={false} gutter={[16, 0]}>
            {isEventsButtonEnabled && (
              <UiCol>
                <HeaderEventsButton
                  type="default"
                  size="large"
                  style={{ backgroundColor: themeVariables.colorInputOnBrand }}
                />
              </UiCol>
            )}
            <UiCol>
              <HeaderNotificationsButton
                type="default"
                size="large"
                style={{ backgroundColor: themeVariables.colorInputOnBrand }}
              />
            </UiCol>
            <UiCol>
              <RouterLink to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
                <UiAvatar
                  size="large"
                  src={profile?.smallAvatar}
                  style={{ border: `2px solid ${variables.themePalette.white}` }}
                />
              </RouterLink>
            </UiCol>
            <UiCol>
              <HeaderActions />
            </UiCol>
          </UiRow>
        </UiCol>
      </UiRow>
    </UiCol>
  );
};
