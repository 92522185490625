import { UiOptionData } from '..';
import { Dictionaries } from './dictionary.model';
import { FileStorageEntryId } from './file.model';
import { NewsId } from './news.model';
import { PostId } from './post';

// TagId для сохранения, UiOptionData для формы, Dictionaries.Dict для хранения на бэке
export type TagId = string;
export type BaseTag = TagId | UiOptionData | Dictionaries.Dict;

export enum TagRelationObjectType {
  Blog = 'blog',
  Entry = 'entry',
  Micropost = 'micropost',
  News = 'news',
  StorageObject = 'storage_object',
  Page = 'page',
  User = 'user',
}

export type TagRelation = {
  objectId: PostId | NewsId | FileStorageEntryId;
  objectType: TagRelationObjectType;
};

export type Tag = {
  id: TagId;
  name: string;
  objectsCount: number;
};
