import { CellInput } from '@vkph/components';
import { UiFlex, UiModal, UiModalTypes, UiTooltip, UiTypography, useSpace, useToggle } from '@vkph/ui';
import React, { FC } from 'react';

import { DictionaryResponseCellValue, ListColumnId, ListRowValues } from '@vkph/common/types/models';
import { declension, notesDeclension } from '@vkph/common/utils';

import { DynamicTableCellContentProps } from '../types';
import { DictionaryCellForm } from './dictionary-cell-form/DictionaryCellForm';

type Props = DynamicTableCellContentProps<DictionaryResponseCellValue[]>;

export type TreeOptions = {
  value: string;
  title: string;
  children?: TreeOptions[];
};

type DictionaryFormValue = { label: string; value: string };

const normalizedValuesFromApi = (values: DictionaryResponseCellValue[]) => {
  return values.map((value) => {
    return {
      value: value.dictionaryValueId,
      label: value.dictionaryValue,
    };
  });
};

export const DictionaryCell: FC<Props> = (props) => {
  const {
    inputSuffix,
    dataIndex,
    fieldOptions: { dictionaryType },
    readOnly,
    value,
    onFinish,
  } = props;
  const { spaceXL } = useSpace();
  const [isOpenModal, toggleModalOpen] = useToggle([false, true]);
  const values = Array.isArray(value) && value.length > 0 ? normalizedValuesFromApi(value) : [];

  const onSave = (formValues: ListRowValues & Record<ListColumnId, DictionaryFormValue[]>) => {
    if (dictionaryType) {
      onFinish({
        ...formValues,
        [dataIndex]: {
          dictionaryType,
          dictionaryValuesIds: formValues[dataIndex].map((item) => item.value),
        },
      });
    }
  };

  const getButtonText = () => {
    if (values.length === 1) {
      return values[0].label;
    }

    if (values.length > 1) {
      return `Выбрано ${values.length} ${declension(values.length, notesDeclension)}`;
    }

    return 'Добавить запись';
  };

  const openModal = () => {
    toggleModalOpen();
  };

  const isBlueColorText = values.length === 0 || values.length > 1;

  const getTooltipText = () =>
    values.length > 1 && (
      <>
        {values.map((item) => (
          <UiTypography.Paragraph key={item.value}>{item.label}</UiTypography.Paragraph>
        ))}
      </>
    );

  return (
    <>
      <UiModal type={UiModalTypes.Medium} onClose={toggleModalOpen} isOpen={isOpenModal}>
        {dictionaryType && (
          <DictionaryCellForm
            dataIndex={dataIndex}
            dictionaryType={dictionaryType}
            values={values}
            onSave={onSave}
            onCancel={() => toggleModalOpen()}
          />
        )}
      </UiModal>
      <UiTooltip shift={spaceXL} placement="top" title={getTooltipText()}>
        <UiFlex style={{ position: 'relative' }}>
          <CellInput.Button
            onClick={readOnly ? undefined : openModal}
            readOnly={readOnly}
            isEmpty={isBlueColorText}
            suffix={inputSuffix}
          >
            {getButtonText()}
          </CellInput.Button>
        </UiFlex>
      </UiTooltip>
    </>
  );
};
