import { Menu as AntMenu } from 'antd';
import { MenuProps } from 'antd/es/menu';
import classNames from 'classnames';
import {
  MenuInfo,
  SelectInfo,
  MenuClickEventHandler,
  SelectEventHandler,
  MenuItemType as RcMenuItemType,
  MenuDividerType as RcMenuDividerType,
  SubMenuType as RcSubMenuType,
  MenuItemGroupType as RcMenuItemGroupType,
} from 'rc-menu/lib/interface';
import React, { FC, ReactNode, useMemo } from 'react';

import { useSpace } from '../../hooks';
import { useToken } from '../config-provider';
import { UiTruncateMarkup, UiTruncateMarkupProps } from '../truncate-markup';
import styles from './UiMenu.scss';

interface MenuItemType extends RcMenuItemType {
  danger?: boolean;
  icon?: ReactNode;
  title?: string;
}
interface SubMenuType extends Omit<RcSubMenuType, 'children'> {
  icon?: ReactNode;
  theme?: 'dark' | 'light';
  children: ItemType[];
}
interface MenuItemGroupType extends Omit<RcMenuItemGroupType, 'children'> {
  children?: ItemType[];
  key?: React.Key;
}
interface MenuDividerType extends RcMenuDividerType {
  dashed?: boolean;
}
export declare type ItemType = MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType | null;
export type UiMenuInfo = MenuInfo;
export type UiSelectInfo = SelectInfo;

export interface UiMenuItemType extends MenuItemType {
  extraIcon?: ReactNode;
  truncateProps?: UiTruncateMarkupProps;
}
export type UiSubMenuType = SubMenuType;
export type UiMenuItemGroupType = MenuItemGroupType;
export type UiMenuDividerType = MenuDividerType;
export type UiMenuItemTypes = UiMenuItemType | UiSubMenuType | UiMenuDividerType | UiMenuItemGroupType | null;

export interface UiMenuProps extends Omit<MenuProps, 'items'> {
  items?: UiMenuItemTypes[];
  strong?: boolean;
  isStickyBadge?: boolean;
  subMenuScrollableItems?: number;
}
export type UiMenuClickEventHandler = MenuClickEventHandler;
export type UiMenuSelectEventHandler = SelectEventHandler;

export const UiMenu: FC<UiMenuProps> = (props) => {
  const { items = [], strong, isStickyBadge, subMenuScrollableItems, ...menuProps } = props;
  const isMenuItemType = (menuItem: Exclude<UiMenuItemTypes, null>): menuItem is UiMenuItemType =>
    'extraIcon' in menuItem;
  const { token } = useToken();
  const { space2XS } = useSpace();

  const menuItemHeight = token.Menu?.itemHeight || 0;
  const itemMarginBlock = space2XS;
  const maxHeight = subMenuScrollableItems
    ? subMenuScrollableItems * (menuItemHeight + itemMarginBlock) + itemMarginBlock
    : undefined;

  const menuItems: ItemType[] = useMemo(() => {
    return items?.map((item) => {
      if (item && isMenuItemType(item)) {
        const { label, truncateProps = { truncate: false }, extraIcon, ...itemProps } = item;

        return {
          ...itemProps,
          label: (
            <>
              <UiTruncateMarkup {...truncateProps}>
                {strong && <strong>{label}</strong>}
                {!strong && <>{label}</>}
              </UiTruncateMarkup>
              {extraIcon && (
                <span style={isStickyBadge ? { marginLeft: 8 } : { marginLeft: 'auto' }}>{extraIcon}</span>
              )}
            </>
          ),
        };
      }

      return item;
    });
  }, [items]);

  return (
    <AntMenu
      {...menuProps}
      items={items?.length ? menuItems : undefined}
      className={classNames(styles.uiMenu)}
      style={{ [styles.uiMenuScrollHeight]: `${maxHeight}px` }}
    />
  );
};
