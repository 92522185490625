import { Layout, UiEmpty } from '@vkph/ui';
import { useStore } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks/useAbstractStorage';
import { useLocalStorage } from '@vkph/common/hooks/useLocalStorage';
import { portalSettingStorage } from '@vkph/common/store';
import { headerLogoImageStorage } from '@vkph/common/store/header-logo-image';
import { themeStorage } from '@vkph/common/store/theme';
import { FileLogoVariants } from '@vkph/common/types/models';
import { PortalSettingData } from '@vkph/common/types/settings';
import { themeOptionsDefault, ThemeOptions, ThemeProvider, OnChangeTheme } from '@vkph/ui/providers/theme';

const PORTAL_TITLE_DEFAULT = 'VK People Hub';

type ThemImages = Record<FileLogoVariants, string>;

export const ThemeService: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [userTheme, setUserTheme] = useLocalStorage<ThemeOptions>(
    themeOptionsDefault.varsPrefix,
    {} as ThemeOptions,
  );

  const [portalSettingLocalData, setPortalSettingLocalData] = useLocalStorage<PortalSettingData>(
    'portalSetting',
    {},
  );

  const { loading: isThemeLoading, error: themeError } = useAbstractStorage(themeStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: headerLogoImageData } = useAbstractStorage(headerLogoImageStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: portalSettingData } = useAbstractStorage(portalSettingStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const themeImages = useMemo<ThemImages>(() => {
    return Object.entries(headerLogoImageData).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value?.file };
    }, {} as ThemImages);
  }, [headerLogoImageData]);

  const { colors, isUserColorsFilled } = useStore(themeStorage.themeState);

  const isUserThemeExist = !isEmpty(userTheme);
  const isThemeError = isEmpty(themeError);
  const isShowRouter = isUserThemeExist || isThemeError;

  const theme = useMemo<ThemeOptions>(() => {
    const themeDefault = !userTheme?.varsPrefix ? themeOptionsDefault : userTheme;
    const themeVariables = { ...themeDefault.variables };

    if (isUserColorsFilled) {
      colors.forEach(({ name, color }) => {
        themeVariables[name] = color;
      });
    }

    return { ...themeDefault, variables: themeVariables, images: themeImages };
  }, [userTheme, colors, isUserColorsFilled, themeImages]);

  const onChangeTheme = useCallback<OnChangeTheme>(
    ({ variables: themeVariables, ...rest }) => {
      setUserTheme({
        ...rest,
        variables: { ...userTheme?.variables, ...themeVariables },
        images: themeImages,
      });
    },
    [userTheme, themeImages],
  );

  useEffect(() => {
    if (isUserColorsFilled) {
      setUserTheme(theme);
    }
  }, [isUserColorsFilled]);

  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("head > link[rel='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    const faviconUrl = headerLogoImageData.portalFavicon?.file;

    link.href = faviconUrl ? `${faviconUrl}?v=${Date.now()}` : '';
  }, [headerLogoImageData.portalFavicon?.file]);

  useEffect(() => {
    if (portalSettingData?.title) {
      setPortalSettingLocalData({ title: portalSettingData.title });
    }
  }, [portalSettingData?.title]);

  useEffect(() => {
    const value = portalSettingLocalData?.title || PORTAL_TITLE_DEFAULT;
    const title = document.querySelector<HTMLLinkElement>('head > title');

    if (title) {
      title.textContent = value;
    }
  }, [portalSettingLocalData?.title]);

  return (
    <ThemeProvider theme={theme} onChange={onChangeTheme} renderEmpty={() => <UiEmpty />}>
      {isShowRouter && children}
      {!isUserThemeExist && <Layout.Loading spinning={isThemeLoading} />}
    </ThemeProvider>
  );
};
