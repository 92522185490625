import {
  ActionsDropdownItem,
  FileStorageList,
  FileStorageListItemSelect,
  UploadDraggerArea,
} from '@vkph/components';
import {
  UiCard,
  UiCheckboxValueType,
  UiCol,
  UiDivider,
  UiEmpty,
  UiModal,
  UiModalTypes,
  UiRender,
  UiRenderType,
  UiRow,
  UiUploadFile,
  UiUploadOriginFile,
  message,
  useModalBase,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers';
import {
  FileStorageListStorageParams,
  getFileStorageFolderInfoStorage,
  getFileStorageHierarchyStorage,
  getFileStorageListStorage,
  getFileStorageSearchListStorage,
  getFilesUploadStorage,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import { GlobalModalNames, closeGlobalModal, openGlobalModal } from '@vkph/common/store/global-modals';
import {
  FileStorageEntryId,
  FileStorageEntryType,
  FileStorageListEntryModel,
  FileStoragePermissions,
  PreloadedFileModel,
} from '@vkph/common/types/models';
import {
  getErrorResponseMessage,
  getFormattedEndpointExtensions,
  replaceParams,
  UploadFile,
} from '@vkph/common/utils';
import { FileListManagerMultiSelectAction, FileListProps } from '@vkph/modules/components';
import { useSpace } from '@vkph/ui/hooks';
import ArrowFrontSvg from '@vkph/ui/svg/arrow-front.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';
import FileSvg from '@vkph/ui/svg/file.svg';
import UserAddSvg from '@vkph/ui/svg/user-add.svg';
import { downloadFileByURL } from '@vkph/ui/utils';

import { FileListManagerControlBar } from '../control-bar/FileListManagerControlBar';
import { CreateFolderDialog } from '../create-folder-dialog';
import { FileListManagerExtensionsModal } from '../extensions-modal/FileListManagerExtensionsModal';
import { FileListManagerNavigation } from '../navigation/FileListManagerNavigation';
import { FileListManagerPermissionFilestorage } from '../permission-filestorage/FileListManagerPermissionFilestorage';
import { RenameFolderDialog } from '../rename-folder-dialog';

export const FileListManagerDesktop: FC<FileListProps> = (props) => {
  const {
    rootId,
    filePath,
    folderPath,
    rootRoute,
    categoryIds,
    isMultiSelectPermission,
    isManageFilesStorage = true,
  } = props;
  const navigate = useNavigate();
  const { id: currentUserId, superAdmin: isSuperAdmin } = useCurrentProfile();
  const { entryId } = useParams<{ entryId?: FileStorageEntryId }>();
  const activeFileStorageId: FileStorageEntryId = entryId || rootId;
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false);
  const [editFolder, setEditFolder] = useState<FileStorageListEntryModel | null>(null);
  const [editFilestorageObjectPermissions, setEditFilestorageObjectPermissions] =
    useState<FileStorageListEntryModel | null>(null);
  const [editFolderExtensions, setEditFolderExtensions] = useState<FileStorageListEntryModel | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedEntries, setSelectedEntries] = useState<FileStorageEntryId[]>([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const { warning } = useModalBase();
  const { spaceM } = useSpace();

  const fileStorageStorage = useMemo(
    () => getFileStorageListStorage(activeFileStorageId),
    [activeFileStorageId],
  );
  const { storage: fileStorageSearchListStorage } = useMemo(
    () => getFileStorageSearchListStorage(rootId),
    [rootId],
  );

  const {
    storage: fileStorageListStorage,
    deleteFileStorageEntryEffect,
    updateFileStorageFolderEffect,
    createUpdateFileStorageFileEvent,
  } = fileStorageStorage;

  const fileInfoStorage = useMemo(getFileStorageFolderInfoStorage, []);
  const { data: fileStorageInfo } = useAbstractStorage(fileInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: activeFileStorageId,
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnChangeParams: { id: activeFileStorageId },
  });

  const fileAllowedTypes = useMemo(() => {
    if (fileStorageInfo?.extensions) {
      const { video, image } = fileStorageInfo.extensions;
      let result = '';

      if (image.length > 0) {
        result += getFormattedEndpointExtensions(image, 'image');
      }

      if (video.length > 0) {
        result += getFormattedEndpointExtensions(video, 'video');
      }

      return result;
    }

    return '';
  }, [fileStorageInfo]);

  const isHasPermissionToCreateFiles = useMemo(() => {
    return fileStorageInfo?.permissionsV2?.includes(FileStoragePermissions.FileCreate);
  }, [fileStorageInfo]);

  const { data: fileStorageList } = useAbstractStorage(fileStorageListStorage);
  const { data: fileStorageSearchList, loading: fileStorageSearchListLoading } = useAbstractStorage(
    fileStorageSearchListStorage,
  );

  const fileStorageHierarchyStorage = useMemo(
    () => getFileStorageHierarchyStorage(activeFileStorageId),
    [activeFileStorageId],
  );
  const uploadAttachmentEffect = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>();
  }, []);

  const isCreateFilePending = useStore(uploadAttachmentEffect.pending);
  const isUpdateFolderPending = useStore(updateFileStorageFolderEffect.pending);

  const { data: fileStorageHierarchy } = useAbstractStorage(fileStorageHierarchyStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    autoFetchParams: {
      upto: rootId,
    },
  });

  const routes = useMemo(() => {
    const parentRoutes = fileStorageHierarchy
      .filter((route) => route.id !== rootId)
      .map((route) => ({
        path: replaceParams(folderPath, { entryId: route.id }),
        title: route.name,
      }));

    return [rootRoute, ...parentRoutes];
  }, [rootRoute, fileStorageHierarchy]);

  const onDelete = (items: FileStorageListEntryModel[]) => {
    let interactionText = {
      title: 'Удалить вложения?',
      successText: 'Вложения успешно удалены',
      errorText: 'Ошибка удаления вложения',
    };

    if (items.length === 1) {
      const isFolder = items[0].type === FileStorageEntryType.Folder;

      interactionText = {
        title: `Удалить ${isFolder ? 'папку' : 'файл'}?`,
        successText: isFolder ? 'Папка успешно удалена' : 'Файл успешно удален',
        errorText: `Ошибка удаления ${isFolder ? 'папки' : 'файла'}`,
      };
    }

    warning({
      title: interactionText.title,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: (close) => {
        deleteFileStorageEntryEffect({ objectIds: items.map((item) => item.id) })
          .then(() => {
            close();
            message.success(interactionText.successText);
            setIsMultiSelect(false);
          })
          .catch((e) => message.error(getErrorResponseMessage(e, interactionText.errorText)));
      },
    });
  };

  const onClick = (item: FileStorageListEntryModel) => {
    if (item.type === FileStorageEntryType.Folder) {
      navigate(replaceParams(folderPath, { entryId: item.id }));
    } else {
      navigate(replaceParams(filePath, { fileId: item.id }));
    }
  };

  const onChangePermissions = (item: FileStorageListEntryModel) => {
    setEditFilestorageObjectPermissions(item);
  };

  const onChangeExtensions = (item: FileStorageListEntryModel) => {
    if (item.type === FileStorageEntryType.Folder) {
      setEditFolderExtensions(item);
    }
  };

  const onMove = (items: FileStorageListEntryModel[]) => {
    openGlobalModal(GlobalModalNames.FileStorageMoveEntries, {
      rootId,
      rootName: 'Файлы',
      moveItems: items,
      onSuccess: () => {
        fileStorageListStorage.refetchWithLastParams();
        setIsMultiSelect(false);
      },
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageMoveEntries),
    });
  };

  const onRenameFolderApply = (name: string) => {
    if (editFolder) {
      updateFileStorageFolderEffect({ name, id: editFolder.id })
        .then(() => {
          message.success('Папка успешно переименована');
          setEditFolder(null);
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка переименования папки')));
    }
  };

  const { storage: filesUploadStorage } = useMemo(getFilesUploadStorage, []);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      filesUploadStorage.uploadFilesEvent({ filesToUpload: uploadFileList, appendData: false });

      if (filesToUpload.length > 1) {
        openGlobalModal(GlobalModalNames.FileStorageCreateMultiple, {
          parent: activeFileStorageId,
          uploadStore: filesUploadStorage,
          onSuccess: fileStorageListStorage.refetchWithLastParams,
        });
      } else {
        openGlobalModal(GlobalModalNames.FileStorageCreate, {
          parent: activeFileStorageId,
          uploadStore: filesUploadStorage,
          onSuccess: createUpdateFileStorageFileEvent,
        });
      }
    },
    [filesUploadStorage, activeFileStorageId, fileStorageListStorage, createUpdateFileStorageFileEvent],
  );

  const onPickFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      uploadFiles(filesToUpload);
    },
    [uploadFiles],
  );

  const filterQuery = useMemo<Partial<FileStorageListStorageParams>>(() => {
    const categoriesParams = {
      categories: categoryIds,
    };

    if (searchText) {
      return {
        ...categoriesParams,
        search: searchText,
      };
    }

    return categoriesParams;
  }, [searchText, categoryIds]);

  const isSearchQuery = useMemo(() => Boolean(filterQuery?.search), [filterQuery]);
  const isSearchDataNotFound = useMemo(
    () => isSearchQuery && !fileStorageSearchListLoading && fileStorageSearchList.length === 0,
    [isSearchQuery, fileStorageSearchList, fileStorageSearchListLoading],
  );

  const onSelectChange = useCallback(
    (newSelectedRowKeys: UiCheckboxValueType[]) => {
      setSelectedEntries(newSelectedRowKeys.map(String));
    },
    [setSelectedEntries],
  );

  const onSelectAllEntries = useCallback(() => {
    const entriesList = isSearchQuery ? fileStorageSearchList : fileStorageList;

    setSelectedEntries(entriesList.map((entry) => entry.id));
  }, [setSelectedEntries, fileStorageSearchList, fileStorageList, isSearchQuery]);

  const onMultipleAction = (action: FileListManagerMultiSelectAction) => {
    const selectedEntriesMap = new Map(selectedEntries.map((id) => [id, id]));
    const entriesList = isSearchQuery ? fileStorageSearchList : fileStorageList;
    const entriesToAction = entriesList.filter((entry) => selectedEntriesMap.has(entry.id));

    if (action === FileListManagerMultiSelectAction.Delete) {
      onDelete(entriesToAction);
    }

    if (action === FileListManagerMultiSelectAction.Move) {
      onMove(entriesToAction);
    }
  };

  useEffect(() => {
    setSelectedEntries([]);
  }, [fileStorageSearchList, fileStorageList]);

  const fileSelect = useMemo<FileStorageListItemSelect>(
    () => ({
      selectedItemKeys: selectedEntries,
      onChange: onSelectChange,
    }),
    [selectedEntries, onSelectChange],
  );

  const onFileSelect = useCallback(
    (file: FileStorageListEntryModel) => {
      let selected = [...selectedEntries, file.id];

      if (selectedEntries.includes(file.id)) {
        selected = selectedEntries.filter((id) => id !== file.id);
      }

      setSelectedEntries(selected);
    },
    [selectedEntries],
  );

  const isHasUpload = !isSearchQuery && isHasPermissionToCreateFiles;

  const getFileActions = useCallback(
    (file: FileStorageListEntryModel): ActionsDropdownItem[] => {
      const { permissionsV2 } = file;
      const isFolder = file.type === FileStorageEntryType.Folder;

      const isFolderEditAllowed = permissionsV2?.includes?.(FileStoragePermissions.FolderEdit);
      const isManageAccessAllowed = isFolder
        ? permissionsV2?.includes?.(FileStoragePermissions.FolderManageAccess)
        : permissionsV2?.includes?.(FileStoragePermissions.FileManageAccess);
      const isMoveAllowed = isFolder
        ? permissionsV2?.includes(FileStoragePermissions.FolderMove)
        : permissionsV2?.includes(FileStoragePermissions.FileMove);
      const isDeleteAllowed = isFolder
        ? permissionsV2?.includes?.(FileStoragePermissions.FolderDelete)
        : permissionsV2?.includes?.(FileStoragePermissions.FileDelete);

      const result = [];

      if (!isFolder) {
        result.push({
          label: 'Скачать',
          onClick: () => downloadFileByURL(file.file as string),
          icon: DownloadSvg,
        });
      }

      if (isFolder) {
        result.push({
          label: 'Перейти',
          onClick: () => onClick(file),
          icon: ArrowFrontSvg,
        });
      }

      if (isManageAccessAllowed) {
        result.push({
          label: 'Изменить доступ',
          onClick: () => onChangePermissions(file),
          icon: UserAddSvg,
        });
      }

      if (isFolder && isManageAccessAllowed) {
        result.push({
          label: 'Ограничить по типу файла',
          onClick: () => onChangeExtensions(file),
          icon: FileSvg,
        });
      }

      if (isFolder && isFolderEditAllowed) {
        result.push({
          label: 'Переименовать',
          onClick: () => setEditFolder(file),
          icon: EditSvg,
        });
      }

      if (isMoveAllowed && onMove) {
        result.push({
          label: 'Переместить',
          onClick: () => onMove([file]),
          icon: ArrowFrontSvg,
        });
      }

      if (isDeleteAllowed) {
        result.push({
          label: 'Удалить',
          onClick: () => onDelete([file]),
          icon: DeleteSvg,
        });
      }

      return result;
    },
    [currentUserId, isSuperAdmin, onMove, setEditFolder],
  );

  const onCloseEditFolderExtensions = () => setEditFolderExtensions(() => null);
  const onCloseEditFilestorageObjectPermissions = () => setEditFilestorageObjectPermissions(() => null);
  const onCloseEditFolder = () => setEditFolder(() => null);

  return (
    <>
      <UiCard size="default" style={{ width: '100%' }} emptyPadding>
        <UiRow>
          <UiCol span={24}>
            <FileListManagerNavigation
              fileStorageRootId={rootId}
              routes={routes}
              onSearch={setSearchText}
              onClear={() => setSearchText('')}
            />
            <UiDivider emptyMargin />
            <FileListManagerControlBar>
              <FileListManagerControlBar.LeftActions
                isSearching={isSearchQuery}
                isMultiSelect={isMultiSelect}
                isMultiSelectPermission={isMultiSelectPermission}
                isManageFilesStorage={isManageFilesStorage}
                selectedCount={selectedEntries.length}
                totalCount={isSearchQuery ? fileStorageSearchList.length : fileStorageList.length}
                onMultipleModeClick={() => setIsMultiSelect(true)}
                onCreateFolderClick={() => setIsCreateFolderOpen(true)}
                onCancel={() => setIsMultiSelect(false)}
                onSelectAll={onSelectAllEntries}
                onDeselectAll={() => setSelectedEntries([])}
                foundItemsCount={fileStorageSearchList.length}
              />
              {isMultiSelect && (
                <FileListManagerControlBar.RightActions
                  onMove={() => onMultipleAction(FileListManagerMultiSelectAction.Move)}
                  onDelete={() => onMultipleAction(FileListManagerMultiSelectAction.Delete)}
                />
              )}
            </FileListManagerControlBar>
            <UiDivider emptyMargin />
            {isHasUpload && isManageFilesStorage && (
              <UiCol style={{ padding: '20px 24px' }}>
                <UploadDraggerArea
                  onPickFiles={onPickFiles}
                  loading={isCreateFilePending}
                  multiple
                  accept={fileAllowedTypes}
                  compact
                />
              </UiCol>
            )}
            <UiRender type={UiRenderType.DisplayNone} visible={!isSearchDataNotFound}>
              <UiCol style={{ paddingTop: isSearchQuery ? spaceM : 0 }}>
                <FileStorageList
                  storage={isSearchQuery ? fileStorageSearchListStorage : fileStorageListStorage}
                  pageSize={20}
                  onClick={onClick}
                  query={filterQuery}
                  fileSelect={isMultiSelect ? fileSelect : undefined}
                  onFileSelect={onFileSelect}
                  getFileActions={getFileActions}
                />
              </UiCol>
            </UiRender>
            {isSearchDataNotFound && (
              <UiEmpty.Feed.Message
                emptyMessage={{
                  header: 'Ничего не найдено',
                  description: 'Попробуйте сформулировать запрос по-другому',
                }}
              />
            )}
          </UiCol>
        </UiRow>
      </UiCard>
      <CreateFolderDialog
        title="Создать папку"
        inputPlaceholder="Название папки"
        filestorageListStorage={fileStorageStorage}
        parentFilestorageId={activeFileStorageId}
        onClose={() => setIsCreateFolderOpen(false)}
        isOpen={isCreateFolderOpen}
      />

      {editFolder && (
        <RenameFolderDialog
          title="Переименовать папку"
          inputPlaceholder="Название папки"
          existingItemName={editFolder.name}
          isOpen={Boolean(editFolder)}
          onClose={onCloseEditFolder}
          onApply={onRenameFolderApply}
          loading={isUpdateFolderPending}
        />
      )}

      <UiModal
        type={UiModalTypes.Medium}
        isOpen={Boolean(editFilestorageObjectPermissions)}
        onClose={onCloseEditFilestorageObjectPermissions}
      >
        {editFilestorageObjectPermissions && (
          <FileListManagerPermissionFilestorage
            onClose={onCloseEditFilestorageObjectPermissions}
            filestorageObject={editFilestorageObjectPermissions}
          />
        )}
      </UiModal>
      <UiModal
        type={UiModalTypes.Medium}
        isOpen={Boolean(editFolderExtensions)}
        onClose={onCloseEditFolderExtensions}
      >
        {editFolderExtensions && (
          <FileListManagerExtensionsModal
            onClose={onCloseEditFolderExtensions}
            folder={editFolderExtensions}
          />
        )}
      </UiModal>
    </>
  );
};
