import type { PickerProps } from 'antd/lib/date-picker/generatePicker';
import React, { FC } from 'react';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';

import { UiDatePicker } from '../date-picker';

export type UiTimePickerProps = PickerProps;

export const UiTimePicker: FC<UiTimePickerProps> = (props) => {
  return (
    <UiDatePicker
      showNow={false}
      format="HH:mm"
      placeholder="––:––"
      suffixIcon={<DropDownSvg />}
      minuteStep={5}
      hideDisabledOptions
      {...props}
      picker="time"
      mode={undefined}
    />
  );
};

UiTimePicker.displayName = 'TimePicker';
