import { endpoints } from '@vkph/common/endpoints';
import { BlogStorageParams } from '@vkph/common/store/blogs/api';
import {
  UserIdModel,
  BlogAlbumId,
  BlogAlbumModel,
  BlogId,
  BlogSlugId,
  BlogRequestId,
  BlogRequestStatus,
  FileStorageEntryId,
  BlogAlbumCategoryId,
  FileStorageImage,
  BlogAlbumImageId,
} from '@vkph/common/types/models';
import { FileUploadAccepts, checkFileMimeType, api } from '@vkph/common/utils';

import { DecideUserRequestToBlogModel } from '../../types/models/blog-members';

// TODO заимплементить
// export type CreateBlogParams = {};
// export type UpdateBlogParams = {};
// export type DeleteBlogParams = {};

export type InviteUserParams = { userId: UserIdModel } & BlogStorageParams;
export type RevokeInviteUserParams = { userId: UserIdModel } & BlogStorageParams;
export type MemberRequestParams = {
  requestId?: BlogRequestId;
  decide: BlogRequestStatus;
} & BlogStorageParams;

export const getBlogAvailableUsersEndpoint = (blogId: BlogId) =>
  endpoints.asyncBlogs.blogAvailableUsers(blogId);

export const getBlogSentInvitationsEndpoint = ({ blogId }: BlogStorageParams) =>
  endpoints.asyncBlogs.blogSentInvitations(blogId);

export const getBlogRequestsEndpoint = ({ blogId }: BlogStorageParams) =>
  endpoints.asyncBlogs.blogSubscribeRequest(blogId);

export const decideUserRequestToBlogEndpoint = ({ blogId, requestId, decide }: MemberRequestParams) => {
  return api.patch<DecideUserRequestToBlogModel>({
    url: endpoints.asyncBlogs.blogSubscribeRequest(blogId, requestId),
    data: {
      state: decide,
    },
  });
};

export const inviteUserToBlog = ({ blogId, userId }: InviteUserParams) => {
  return api.post({
    url: endpoints.asyncBlogs.blogInvite(blogId),
    data: { user: userId },
  });
};

export const revokeBlogMemberInvite = ({ blogId, userId }: RevokeInviteUserParams) => {
  return api.post({
    url: endpoints.asyncBlogs.blogRevokeInviteUser(blogId, userId),
  });
};

export type DeleteImageFromAlbumParams = { imageId: BlogAlbumImageId };

export const deleteImageFromAlbum = ({ imageId }: DeleteImageFromAlbumParams) =>
  api.delete({
    url: endpoints.asyncBlogs.blogAlbumImageId(imageId),
  });

export type DeleteImagesFromAlbumParams = { data: { ids: BlogAlbumImageId[] }; albumId: BlogAlbumId };

export const deleteImagesFromAlbum = ({ data, albumId }: DeleteImagesFromAlbumParams) =>
  api.post({
    url: endpoints.asyncBlogs.albumsAlbumIdImagesDelete(albumId),
    data,
  });

export type DeleteAllImagesFromAlbumParams = { albumId: BlogAlbumId };

export const deleteAllImagesFromAlbum = ({ albumId }: DeleteAllImagesFromAlbumParams) =>
  api.delete({
    url: endpoints.asyncBlogs.blogAlbumIdImages(albumId),
  });

export type DeleteAlbumFromBlogParams = { albumId: BlogAlbumId };

export const deleteAlbumFromBlog = ({ albumId }: DeleteAlbumFromBlogParams) =>
  api.delete({
    url: endpoints.asyncBlogs.blogAlbumId(albumId),
  });

export type UploadBlogAlbumParams = { data: File[]; albumId: BlogAlbumId };

export interface UploadBlogAlbumByIdsParams extends Omit<UploadBlogAlbumParams, 'data'> {
  data: FileStorageEntryId[];
}

const getUploadBlogAlbumData = (data: File[]) => {
  const formData = new window.FormData();

  data.forEach((file) => {
    if (checkFileMimeType(FileUploadAccepts.ImagePngJpgGif, file.type)) {
      formData.append('files', file);
    }
  });

  return formData;
};

export const uploadBlogAlbum = ({ data, albumId }: UploadBlogAlbumParams) =>
  /* TODO раскоментить и заменить после выполнения пункта 5 задачи B2BCORE-2823 */
  // api
  //   .post<BlogAlbumImageModel>({
  //     url: endpoints.asyncBlogs.blogAlbumUpload(blogId, albumId),
  //     data: getUploadBlogAlbumData(data),
  //   })
  //   .then(({ data: blogAlbumImage }) => blogAlbumImage);
  api.post({
    url: endpoints.asyncBlogs.blogAlbumIdUpload(albumId),
    data: getUploadBlogAlbumData(data),
  });

export const uploadBlogAlbumByIds = ({ data, albumId }: UploadBlogAlbumByIdsParams) =>
  api.post({
    url: endpoints.asyncBlogs.blogAlbumIdUpload(albumId),
    data: {
      fileStorageImageIds: data,
    },
  });

type EditBlogAlbumDataParam = {
  title: string;
  description?: string;
  categoryId?: BlogAlbumCategoryId;
  coverId?: FileStorageImage['fileStorageImageId'];
};

export type EditBlogAlbumParams = {
  blogId?: BlogId;
  blogSlugId: BlogSlugId;
  albumId: BlogAlbumId;
  data: EditBlogAlbumDataParam;
};

export type CreateBlogAlbumParams = Omit<EditBlogAlbumParams, 'albumId'>;

export const createBlogAlbum = ({ blogId, blogSlugId, data }: CreateBlogAlbumParams) =>
  api
    .post<BlogAlbumModel>({
      url: blogId
        ? endpoints.asyncBlogs.blogIdAlbums(blogId)
        : endpoints.asyncBlogs.blogSlugAlbums(blogSlugId),
      data,
    })
    .then(({ data: blogAlbum }) => blogAlbum);

export const editBlogAlbum = ({ data, albumId }: EditBlogAlbumParams) =>
  api
    .put<BlogAlbumModel>({
      url: endpoints.asyncBlogs.blogAlbumId(albumId),
      data,
    })
    .then(({ data: blogAlbum }) => blogAlbum);
