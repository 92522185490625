import { EntityId } from '../blogs';
import { ContactLink } from './contactLinks.model';
import { RecordResponse } from './dictionary.model';
import {
  UserIdModel,
  BaseJobModel,
  BaseUserProfileModel,
  FullNameModel,
  JobModel,
  UserJobModel,
  UserProfileModel,
} from './user.model';

// TODO: anton.a.ivanov: заполнить все поля
export type ProfileSearchEmployee = {
  fullName: FullNameModel;
  id: UserIdModel;
};

export type ContactsModel = {
  externalLinks: ContactLink[];
  id: string;
  mobilePhone: string;
  otherContacts: ContactLink[];
  otherLinks: ContactLink[];
  personalEmail: string;
  workEmail: string;
  workPhone?: string;
  workPhoneExtension?: string;
  workMobilePhone: string;
  workAddress: string | null;
  workModeRid?: RecordResponse['id'];
};

export interface PeopleModel extends BaseUserProfileModel {
  externalId: EntityId;
  bossKeycloakId: UserIdModel;
  job: BaseJobModel;
  username: string;
  workMessenger: string;
  approvers: number;
}

type ApproverJob = Pick<BaseJobModel, 'position'>;

export interface ApproverUserSkill {
  id: UserIdModel;
  avatar: string;
  fullName: FullNameModel;
  job: ApproverJob;
}

export type SkillApprover = {
  approvedAt: string;
  userFrom: ApproverUserSkill;
};

export type SkillId = string;

type Skill = {
  id: SkillId;
  name: string;
};

export interface UserSkill {
  approvers: SkillApprover[];
  count: number;
  proveThreshold: number;
  skill: Skill;
}

export enum SkillsUsersSteps {
  Possess = 1,
  Confirmed = 2,
}

export enum SkillType {
  Competence = 'competence',
  Skill = 'skill',
}

export enum ProfileVisibilityValues {
  Visible = 0,
  Hidden = 2, // TODO 1 - "Отображается для коллег", в разработке (см. B2BCORE-997)
}

export type ProfileUserSettingsNames =
  | 'aboutMe'
  | 'availability'
  | 'awards'
  | 'birthday'
  | 'birthdayYear'
  | 'contactsPersonal'
  | 'contactsExternal'
  | 'contactsSocial'
  | 'education'
  | 'follows'
  | 'languages'
  | 'managementExperience'
  | 'motivationAwards'
  | 'photo'
  | 'publication'
  | 'raiting'
  | 'skills'
  | 'hobbies'
  | 'thanks'
  | 'transitions'
  | 'virtualCoin'
  | 'workPlace'
  | 'workStartedDate'
  | 'workTown'
  | 'mobilePhone'
  | 'personalEmail'
  | 'townLive'
  | 'townLiveRid';

export type ProfileUserSettings = Record<ProfileUserSettingsNames, ProfileVisibilityValues>;

export type ProfileAdditionalInfoCarModel = {
  id: string;
  model: string;
  car: string;
  carRid: string;
  carNumber: string;
  visibility: ProfileVisibilityValues;
};

export type ProfileAdditionalInfoDeviceModel = {
  id: number;
  device: string;
  deviceRid: string;
  type: string | null;
  typeRid: string | null;
  os: string | null;
  osRid: string | null;
  visibility: ProfileVisibilityValues;
};

export type ProfileAdditionalInfoModel = {
  id: string;
  cars: ProfileAdditionalInfoCarModel[];
  devices: ProfileAdditionalInfoDeviceModel[];
};

export type ProfileBiographyEducModel = {
  dateEnd: string;
  id: number | string;
  educationType: string;
  educationTypeId: string;
  institution: string;
  institutionId: string;
  specialization: string | null;
  specializationId: string | null;
  qualification: string | null;
  qualificationId: string | null;
  visibility: ProfileVisibilityValues;
};

export type ProfileBiographyEducationModel = {
  additional: ProfileBiographyEducModel[];
  general: ProfileBiographyEducModel | null;
};

export type ProfileBiographyHobbyId = string;

export type ProfileBiographyHobbyModel = {
  id: ProfileBiographyHobbyId;
  name: string;
};

export type ProfileBiographyLanguagesModel = {
  languageId: string;
  languageName: string;
  levelRid: string | null;
  levelName: string | null;
  visibility: ProfileVisibilityValues;
};

export type ProfileBiographyModel = {
  about: string;
  education: ProfileBiographyEducationModel;
  hobbies: ProfileBiographyHobbyModel[];
  languages: ProfileBiographyLanguagesModel[];
};

export interface ProfileJobHistoryModel extends Omit<JobModel, 'dateStart' | 'dateEnd'> {
  organization: string;
  organizationRid: string;
  beginDate: string;
  endDate: string;
  visibility: ProfileVisibilityValues;
}

export interface ProfileJobHistoryForm extends Omit<ProfileJobHistoryModel, 'position'> {
  position: string;
  workRange: [Date, Date];
}

export interface PatchProfileJobHistoryModel extends Omit<ProfileJobHistoryModel, 'position'> {
  position: string;
}

export type ProfileProjectModel = {
  id: number;
  name: string;
};

export type ProfileProjectItemModel = {
  project: ProfileProjectModel;
  createdAt?: string;
};

export type ProfileInfoModel = {
  job: UserJobModel;
  biography: ProfileBiographyModel;
  main: UserProfileModel;
  additionalInfo: ProfileAdditionalInfoModel;
  jobHistory: ProfileJobHistoryModel[];
  projects: ProfileProjectItemModel[];
};
