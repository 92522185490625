// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4293bb15_ui-card-footer{display:flex;align-items:center;padding:24px}._4293bb15_ui-card-footer_empty-padding{padding:0}._4293bb15_ui-card-footer_top-border{border-top:1px solid var(--default-themeVariables-colorStroke)}._4293bb15_ui-card-footer__buttons{display:flex;flex-wrap:nowrap;align-items:center}._4293bb15_ui-card-footer__buttons>:not(:last-child){margin-right:16px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/card/footer/UiCardFooter.scss"],"names":[],"mappings":"AACA,0BACE,YAAA,CACA,kBAAA,CACA,YAAA,CAEA,wCACE,SAAA,CAGF,qCACE,8DAAA,CAGF,mCACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAEA,qDACE,iBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-card-footer {\n  display: flex;\n  align-items: center;\n  padding: $baseSpacing;\n\n  &_empty-padding {\n    padding: 0;\n  }\n\n  &_top-border {\n    border-top: $spacerBorder;\n  }\n\n  &__buttons {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n\n    & > :not(:last-child) {\n      margin-right: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-card-footer": "_4293bb15_ui-card-footer",
	"uiCardFooter": "_4293bb15_ui-card-footer",
	"ui-card-footer_empty-padding": "_4293bb15_ui-card-footer_empty-padding",
	"uiCardFooter_emptyPadding": "_4293bb15_ui-card-footer_empty-padding",
	"ui-card-footer_top-border": "_4293bb15_ui-card-footer_top-border",
	"uiCardFooter_topBorder": "_4293bb15_ui-card-footer_top-border",
	"ui-card-footer__buttons": "_4293bb15_ui-card-footer__buttons",
	"uiCardFooter__buttons": "_4293bb15_ui-card-footer__buttons"
};
export default ___CSS_LOADER_EXPORT___;
