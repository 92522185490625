import { ActionsDropdown } from '@vkph/components';
import {
  UiButton,
  UiIcon,
  UiModal,
  UiSpace,
  UiTag,
  useModalBase,
  useSpace,
  usePaddingStyle,
  UiTypography,
  UiDivider,
  UiList,
  UiFlex,
  notification,
} from '@vkph/ui';
import React, { FC, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  GlobalModalNames,
  GlobalModalsStorePayloads,
  closeGlobalModal,
  openGlobalModal,
} from '@vkph/common/store/global-modals';
import { OrderingParams } from '@vkph/common/types';
import { BlogAlbumCategoryModel } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import AddCircleSVG from '@vkph/ui/svg/add-circle.svg';
import CloseSVG from '@vkph/ui/svg/close.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';
import SortingDownSvg from '@vkph/ui/svg/sorting-down.svg';
import SortingUpSvg from '@vkph/ui/svg/sorting-up.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.BlogAlbumCategoryList]['payload']>;

export const BlogAlbumCategoryListModal: FC<Props> = (props) => {
  const { onClose, categoriesStorage, entryId } = props;
  const { storage: blogAlbumCategoriesStorage, deleteBlogAlbumCategoryEffect } = categoriesStorage;

  const { warning } = useModalBase();
  const { spaceS, spaceL, spaceXL } = useSpace();
  const paddingStyle = usePaddingStyle([spaceL, spaceXL]);
  const [sortDirection, setSortDirection] = useState<OrderingParams>(OrderingParams.NameAtAsc);

  const { data: blogAlbumCategoriesData } = useAbstractStorage(blogAlbumCategoriesStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onAddCategory = () => {
    openGlobalModal(GlobalModalNames.BlogAlbumCategoryCreateUpdate, {
      blogId: entryId,
      onClose: () => closeGlobalModal(GlobalModalNames.BlogAlbumCategoryCreateUpdate),
    });
  };

  const onEdit = (category: BlogAlbumCategoryModel) => {
    openGlobalModal(GlobalModalNames.BlogAlbumCategoryCreateUpdate, {
      blogId: entryId,
      category,
      onClose: () => closeGlobalModal(GlobalModalNames.BlogAlbumCategoryCreateUpdate),
    });
  };

  const onDelete = (category: BlogAlbumCategoryModel) => {
    warning({
      title: `Удалить категорию?`,
      content: `Вы действительно хотите удалить категорию "${category.name}" без возможности восстановления?`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: (close) => {
        deleteBlogAlbumCategoryEffect({ categoryId: category.id })
          .then(() => {
            close();
            notification.success({ message: 'Категория успешно удалена' });
          })
          .catch((e) =>
            notification.error({ message: getErrorResponseMessage(e, 'Ошибка удаления категории') }),
          );
      },
    });
  };

  if (sortDirection === OrderingParams.NameAtAsc) {
    blogAlbumCategoriesData.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    blogAlbumCategoriesData.sort((a, b) => b.name.localeCompare(a.name));
  }

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          style={{ justifyContent: 'space-between' }}
          steps={getModalStepsForSingleTitle('Категории')}
          noCloseIcon
        >
          <UiSpace size={spaceXL}>
            <UiButton
              type="link"
              label="Добавить"
              icon={<UiIcon component={AddCircleSVG} height={20} width={20} />}
              onClick={onAddCategory}
            />
            <UiButton size="middle" type="link-secondary" onClick={onClose} icon={<CloseSVG />} />
          </UiSpace>
        </UiModal.Header.Title>
      </UiModal.Header>
      <UiModal.Content>
        <UiModal.Content>
          <UiSpace size={spaceS} style={paddingStyle}>
            <UiTypography.Text strong>Сортировка</UiTypography.Text>
            <ActionsDropdown
              items={[
                {
                  label: 'От А до Я',
                  onClick: () => setSortDirection(OrderingParams.NameAtAsc),
                },
                {
                  label: 'От Я до А',
                  onClick: () => setSortDirection(OrderingParams.NameAtDesc),
                },
              ]}
              icon={sortDirection === OrderingParams.NameAtAsc ? SortingDownSvg : SortingUpSvg}
            />
          </UiSpace>
          <UiDivider emptyMargin />
          <UiList
            itemLayout="vertical"
            dataSource={blogAlbumCategoriesData}
            renderItem={(item) => (
              <UiList.Item style={paddingStyle}>
                <UiFlex justify="space-between" align="center">
                  <UiTag title={item.name} color={item.markerColor} />
                  <ActionsDropdown
                    items={[
                      { icon: EditSvg, label: 'Редактировать', onClick: () => onEdit(item) },
                      {
                        icon: DeleteSvg,
                        label: 'Удалить',
                        onClick: () => onDelete(item),
                      },
                    ]}
                  />
                </UiFlex>
              </UiList.Item>
            )}
          />
        </UiModal.Content>
      </UiModal.Content>
    </>
  );
};
