import {
  UiButton,
  UiFile,
  UiFileDirection,
  UiFlex,
  UiIcon,
  UiTooltip,
  UiTypography,
  getFormattedFileSize,
} from '@vkph/ui';
import React, { FC } from 'react';

import {
  FileStorageEntryType,
  FileStorageListEntryContext,
  FileStorageListEntryModel,
} from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';
import FileAddSvg from '@vkph/ui/svg/file-add.svg';

type Props = {
  data: FileStorageListEntryModel<FileStorageListEntryContext>;
  addFile: (id: string) => void;
};

export const FileTreeNode: FC<Props> = (props) => {
  const { data, addFile } = props;
  const isFolder = data.type === FileStorageEntryType.Folder;

  return (
    <UiFlex justify="space-between" align="center">
      <UiFile
        isFolder={isFolder}
        direction={UiFileDirection.Horizontal}
        title={
          <UiTypography.Paragraph style={{ marginBottom: 0 }} strong>
            {data.name}
          </UiTypography.Paragraph>
        }
        subtitle={
          !isFolder && (
            <UiTypography.Text type="secondary">
              {getFormattedFileSize(data.size)} • {getFormattedDate(data.createdAt, 'dd.MM.yyyy')}
            </UiTypography.Text>
          )
        }
        fileName={data.name}
      />
      {isFolder && (
        <UiTooltip title="Добавить файл" placement="left">
          <UiButton
            icon={
              <UiIcon
                component={FileAddSvg}
                onClick={() => {
                  addFile(data.id);
                }}
                width={20}
                height={20}
              />
            }
          />
        </UiTooltip>
      )}
    </UiFlex>
  );
};
