import { Editor, Plugin } from '@ckeditor/ckeditor5-core';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import { Image, ImageUpload } from '@ckeditor/ckeditor5-image';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

import { editorConfiguration } from '../../config/editor-config';
import { fileIdAttributeModelName } from './constants';
import { cropAndInsertImage } from './utils';

const registerImageFileIdAttributes = (editor: Editor) => {
  const imageSchema = editor.model.schema.getDefinition('imageBlock');

  if (!imageSchema?.allowAttributes?.includes?.(fileIdAttributeModelName)) {
    editor.model.schema.extend('imageBlock', { allowAttributes: fileIdAttributeModelName });
  }
};

export class ImageCropUiPlugin extends Plugin {
  public static get requires() {
    return [Image, ImageUpload] as const;
  }

  init() {
    const { editor } = this;
    const cropConfig = editor.config.get('crop') || editorConfiguration.crop;

    registerImageFileIdAttributes(editor);

    editor.ui.componentFactory.add('uploadImageCrop', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Загрузить изображение',
        tooltip: 'Загрузить изображение',
        icon: imageIcon,
      });

      view.on('execute', () => cropAndInsertImage({ editor, cropConfig }));

      return view;
    });
  }

  public static get pluginName() {
    return 'ImageCropUiPlugin' as const;
  }
}
