import {
  notification,
  UiButton,
  UiDrawer,
  UiForm,
  UiInput,
  UiModal,
  UiModalProps,
  UiModalTypes,
  UiSpace,
  UiSpinner,
  useBreakpoint,
  usePaddingStyle,
  useSpace,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { GetFileStorageListStorage, hintMap, HintType } from '@vkph/common/store';
import { FileStorageEntryId } from '@vkph/common/types/models';
import {
  getErrorResponseMessage,
  MAX_LENGTH_INPUT_100,
  normalizeValueTrimStart,
  requiredRule,
} from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import {
  FileListManagerPermissionFilestorageForm,
  onSavePermissionFormEffect,
} from '../permission-filestorage/form';

export interface CreateFolderModalProps extends Pick<UiModalProps, 'onClose' | 'isOpen'> {
  existingItemName?: string;
  title: string;
  filestorageListStorage: GetFileStorageListStorage;
  parentFilestorageId: FileStorageEntryId;
  inputPlaceholder: string;
}

type FormValues = {
  name: string;
};

export const CreateFolderDialog: FC<CreateFolderModalProps> = (props) => {
  const { onClose, isOpen, parentFilestorageId, filestorageListStorage, title } = props;

  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();
  const paddingStyle = usePaddingStyle([0, spaceXL]);

  const [form] = UiForm.useForm<FormValues>();
  const [permissionsForm] = UiForm.useForm();

  const isCreateFolderPending = useStore(filestorageListStorage.createFileStorageFolderEffect.pending);
  const isSavePermissionFormPending = useStore(onSavePermissionFormEffect.pending);
  const isLoading = isCreateFolderPending || isSavePermissionFormPending;

  const onFinish = async (values: FormValues) => {
    try {
      const { id } = await filestorageListStorage.createFileStorageFolderEffect({
        name: values.name,
        parent: parentFilestorageId,
      });

      notification.success({ message: 'Папка успешно создана' });

      const { success } = await onSavePermissionFormEffect({
        showNotification: false,
        entryId: id,
      });

      if (success) {
        notification.success({ message: 'Права успешно установлены' });
        onClose();
      }
    } catch (e) {
      notification.error({
        message: getErrorResponseMessage(e, 'Ошибка создания папки с предустановленными правами доступа'),
      });
    }
  };

  const nameValue = UiForm.useWatch('name', form);

  const buttons = (
    <>
      <UiButton
        block
        disabled={isLoading || !nameValue}
        type="primary"
        size="large"
        onClick={form.submit}
        label="Сохранить"
      />
      <UiButton block disabled={isLoading} type="secondary" size="large" onClick={onClose} label="Отмена" />
    </>
  );

  const content = (
    <>
      <UiForm layout="vertical" size="large" requiredMark form={form} onFinish={onFinish} clearOnDestroy>
        <UiForm.Item
          name="name"
          rules={[requiredRule]}
          normalize={normalizeValueTrimStart}
          label="Название папки"
        >
          <UiInput placeholder="Введите название папки" maxLength={MAX_LENGTH_INPUT_100} />
        </UiForm.Item>
      </UiForm>
      <UiForm form={permissionsForm} size="large" layout="vertical" clearOnDestroy>
        <FileListManagerPermissionFilestorageForm
          type="folder"
          onClose={onClose}
          hintViewList={hintMap[HintType.FilestorageFolderView]}
          hintEditList={hintMap[HintType.FilestorageFolderEdit]}
        />
      </UiForm>
    </>
  );

  return (
    <>
      {isLayoutLarge && (
        <UiModal type={UiModalTypes.Medium} isOpen={isOpen} onClose={onClose}>
          <UiModal.Header>
            <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
          </UiModal.Header>
          <UiModal.Content style={paddingStyle}>
            <UiSpinner spinning={isLoading}>{content}</UiSpinner>
          </UiModal.Content>
          <UiModal.Footer hasTopBorder={false}>
            <UiModal.Footer.Buttons>{buttons}</UiModal.Footer.Buttons>
          </UiModal.Footer>
        </UiModal>
      )}
      {!isLayoutLarge && (
        <UiDrawer
          height="100vh"
          open={isOpen}
          onClose={onClose}
          placement="bottom"
          title={title}
          footer={
            <UiSpace direction="vertical" size={spaceM} full style={{ marginTop: spaceM }}>
              {buttons}
            </UiSpace>
          }
        >
          {content}
        </UiDrawer>
      )}
    </>
  );
};
