import { Image as AntImage } from 'antd';
import classNames from 'classnames';
import isObject from 'lodash/isObject';
import {
  PreviewGroupPreview as AntPreviewGroupPreview,
  GroupConsumerProps as AntGroupConsumerProps,
} from 'rc-image/lib/PreviewGroup';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import CloseSvg from '@vkph/ui/svg/close.svg';

import { UiIcon } from '../../../icon';
import { UiImageProps } from '../../UiImage';
import styles from '../UiImagePreview.scss';
import {
  UiImageCustomContentPreview,
  UiImagePreviewGroupSiderProps,
  useImageCustomContentPreview,
} from '../custom-content';
import { UiImagePreviewToolbar, UiImagePreviewToolbarExtension, PREVIEW_TRANSFORM_SCALE } from '../toolbar';

export interface UiImagePreviewGroupPreviewExtended
  extends AntPreviewGroupPreview,
    UiImagePreviewToolbarExtension {
  sider?: UiImagePreviewGroupSiderProps;
}

export interface UiImagePreviewGroupProps
  extends Omit<AntGroupConsumerProps, 'preview' | 'items'>,
    Pick<UiImageProps, 'wrapperStyle'> {
  preview: UiImagePreviewGroupPreviewExtended | true;
  items?: string[];
}
type UiImagePreviewGroupComponent = FC<PropsWithChildren<UiImagePreviewGroupProps>>;

export const UiImagePreviewGroup: UiImagePreviewGroupComponent = (props) => {
  const { preview, children, items } = props;

  const {
    customize,
    visible,
    onChange,
    current,
    onVisibleChange,
    sider,
    toolbarRender,
    ...restPreviewConfig
  }: UiImagePreviewGroupPreviewExtended = isObject(preview) ? preview : {};

  const { ref, getContainer, containerElement } = useImageCustomContentPreview();

  const getDefaultToolbar: AntPreviewGroupPreview['toolbarRender'] = (_, { transform, actions }) => (
    <UiImagePreviewToolbar transform={transform} actions={actions} customize={customize} />
  );

  const previewConfig = useMemo<UiImagePreviewGroupPreviewExtended>(() => {
    return {
      toolbarRender: toolbarRender || getDefaultToolbar,
      mask: <div style={{ cursor: 'pointer' }} />,
      scaleStep: PREVIEW_TRANSFORM_SCALE.SCALE_STEP,
      minScale: PREVIEW_TRANSFORM_SCALE.SCALE_MIN,
      maxScale: PREVIEW_TRANSFORM_SCALE.SCALE_MAX,
      closeIcon: <UiIcon width={20} height={20} component={CloseSvg} />,
      countRender: () => null,
      ...restPreviewConfig,
    };
  }, [current, sider]);

  const AntImagePreviewGroup = (
    <AntImage.PreviewGroup
      preview={{
        rootClassName: classNames(
          styles.uiImagePreview,
          sider?.visible && styles.uiImagePreview_sidebarOpened_true,
          sider?.placement === 'left'
            ? styles.uiImagePreview_sidebarPosition_left
            : styles.uiImagePreview_sidebarPosition_right,
        ),
        getContainer,
        visible,
        onChange,
        current,
        onVisibleChange,
        ...(previewConfig as Record<string, unknown>),
      }}
      items={items}
    >
      {React.Children.map<ReactNode, ReactNode>(children, (child) => {
        if (React.isValidElement<UiImageProps>(child)) {
          return React.cloneElement(child, {});
        }

        return null;
      })}
    </AntImage.PreviewGroup>
  );

  if (sider) {
    return (
      <UiImageCustomContentPreview ref={ref} parentElement={containerElement} {...sider}>
        {AntImagePreviewGroup}
      </UiImageCustomContentPreview>
    );
  }

  return AntImagePreviewGroup;
};
