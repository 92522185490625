import { UiAvatarProps } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationModel, NotificationUserBadgeCommentModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames, parseEntityText } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../NotificationsDrawer';
import { NotificationComment, NotificationTargetTypes } from '../../../comment';

type Props = {
  notification: NotificationModel<NotificationUserBadgeCommentModel>;
};

export const NotificationGamificationRewardComment: FC<Props> = (props) => {
  const { notification } = props;

  const { content, createdAt, image, initiator } = notification;
  const { fullName, keycloakId, isActive } = initiator;

  const { userBadge, comment, parent } = content;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const userRewardsLink = getRoutePath(RouteNames.ProfileRewardsProgress, {
    id: content.userBadge.user.id,
    rewardId: content.userBadge.id,
  });

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      subtitle={notificationDescription}
      avatarProps={avatar}
      isActive={isActive}
      to={userLink}
      commentText={commentText}
      replyText={replyText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Page}
      targetName={userBadge.badge?.name || 'Награда'}
      targetUrl={userRewardsLink}
      targetDescription="К награде:"
    />
  );
};
