import { UiCell, UiOptionData } from '@vkph/ui';
import React from 'react';

import { ServiceRolesGroupDataId, ServiceRolesGroupDataModel } from '@vkph/common/types';
import {
  PermissionRoleDetailModel,
  PermissionRoleId,
  PermissionRoles,
  UserAvatarModel,
  UserIdModel,
} from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';
import AvatarUsersSvg from '@vkph/ui/svg/avatar-users.svg';
import { OptionModel } from '@vkph/ui/types/option';

import { MultiSelectAvatarLabelDataType } from '../../components/multi-select-avatar-label';
import { PermissionSettingsType } from '../../components/permission-settings-fields';
import { UserAvatar } from '../../components/user-avatar';

export const getPermissionType = (isOverride: boolean, list: unknown[]): PermissionSettingsType => {
  if (isOverride && !list.length) {
    return PermissionSettingsType.Nobody;
  }

  if (!isOverride && !list.length) {
    return PermissionSettingsType.Anyone;
  }

  return PermissionSettingsType.Some;
};

type GroupRoleToOptionParams = Pick<ServiceRolesGroupDataModel, 'id' | 'name'>;

interface UserRoleToOptionParams extends Pick<UserAvatarModel, 'avatar'> {
  name: string;
  id: UserIdModel;
}

export const userRoleToOption = (
  user: UserRoleToOptionParams,
): OptionModel<UserRoleToOptionParams['id'], MultiSelectAvatarLabelDataType> => {
  const { name, id, avatar } = user;

  return {
    value: id,
    data: {
      avatar,
      label: <UiCell avatar={<UserAvatar size="extraSmall" src={avatar} />} title={name} />,
      selectedLabel: name,
    },
  };
};

export const groupRoleToOption = (
  params: GroupRoleToOptionParams,
): OptionModel<GroupRoleToOptionParams['id'], MultiSelectAvatarLabelDataType> => {
  const { id, name } = params;

  return {
    value: id,
    data: {
      label: <UiCell title={name} avatar={<UserAvatar size="extraSmall" icon={<AvatarUsersSvg />} />} />,
      selectedLabel: name,
    },
  };
};

type RolesFormData = {
  permissionType: PermissionSettingsType;
  groups?: UiOptionData[];
  users?: UiOptionData[];
};

export const getPermissionRoleDataForm = (role?: PermissionRoleDetailModel): Required<RolesFormData> => {
  const groups = (role?.groups || []).map(({ id, name, displayName }) =>
    groupRoleToOption({ id, name: displayName || name }),
  );

  const users = (role?.users || []).map((user) => {
    const { keycloakId, avatar, firstName, lastName, username } = user;

    return userRoleToOption({
      id: keycloakId || '',
      avatar: avatar || '',
      name: getFullNameWithoutPatronymic({ firstName, lastName, username }),
    });
  });
  const permissionType = getPermissionType(Boolean(role?.isOverride), [...groups, ...users]);

  return {
    permissionType,
    users,
    groups,
  };
};

type PermissionRoleAssign = {
  id: PermissionRoleId;
  users: UserIdModel[];
  groups: ServiceRolesGroupDataId[];
  isOverride: boolean;
};
type FindPermissionRoleParams = {
  role: PermissionRoles;
  roles: PermissionRoleDetailModel[];
};

export const findPermissionRole = (
  params: FindPermissionRoleParams,
): PermissionRoleDetailModel | undefined => {
  const { roles, role } = params;

  return roles.find((permissionRole) => permissionRole.name === role);
};

interface GetPermissionRoleAssignParams extends RolesFormData {
  id?: PermissionRoleId;
}

export const getPermissionRoleAssign = (params: GetPermissionRoleAssignParams): PermissionRoleAssign => {
  const { id, groups, permissionType, users } = params;

  const isOverride = permissionType !== PermissionSettingsType.Anyone;
  const isSendList = permissionType === PermissionSettingsType.Some;

  return {
    id: id || 0,
    groups: isSendList && groups ? groups.map(({ value }) => value) : [],
    users: isSendList && users ? users.map(({ value }) => value) : [],
    isOverride,
  };
};
