import React, { FC } from 'react';

import {
  NotificationModel,
  NotificationTypes,
  NotificationSitectorCommentModel,
  SitePageModel,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationSitectorComment } from './comment';
import { NotificationSitectorMention } from './mention';

interface Props {
  notification: NotificationModel;
}

export const NotificationSitector: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<SitePageModel>(notification, [NotificationTypes.SitectorPageUserMentioned])) {
    return <NotificationSitectorMention notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationSitectorCommentModel>(notification, [
      NotificationTypes.SitectorCommentCreated,
      NotificationTypes.SitectorCommentReactionCreated,
      NotificationTypes.SitectorCommentUserMentioned,
      NotificationTypes.SitectorCommentReply,
    ])
  ) {
    return <NotificationSitectorComment notification={notification} />;
  }

  return null;
};
