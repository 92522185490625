import { UiFlex, UiForm, UiSelect } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getOrganizationDictLikeStorage, getProfileDepartmentDictLikeStorage } from '@vkph/common/store';
import { generateSelectOptions } from '@vkph/common/utils';

import { ColumnCreateUpdateFormValues } from '../CreateUpdateColumn';

const OPTION_SCHEME = {
  valuePath: 'id',
  labelPath: 'attributes.name',
};

export const UserFormFields: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const form = UiForm.useFormInstance<ColumnCreateUpdateFormValues>();
  const organizationId = UiForm.useWatch('organizationId', form);

  const [organizationSearchValue, setOrganizationSearchValue] = useState('');
  const [departmentSearchValue, setDepartmentSearchValue] = useState('');

  const { storage: organizationDictStorage } = useMemo(getOrganizationDictLikeStorage, []);
  const { storage: departmentDictStorage } = useMemo(
    () => getProfileDepartmentDictLikeStorage(organizationId),
    [organizationId],
  );

  const getPopupContainer = () => ref.current || document.body;

  const { loading: isOrganizationDictLoading } = useAbstractStorage(organizationDictStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { value: organizationSearchValue },
  });

  const { loading: isDepartmentDictLoading } = useAbstractStorage(departmentDictStorage, {
    autoFetchAndRefetch: Boolean(organizationId),
    autoFetchParams: { value: departmentSearchValue },
  });

  const organizationOptions = useStoreMap(organizationDictStorage.store, ({ data }) =>
    generateSelectOptions(data, OPTION_SCHEME),
  );

  const departmentOptions = useStoreMap(departmentDictStorage.store, ({ data }) =>
    generateSelectOptions(data, OPTION_SCHEME),
  );

  const debouncedFetchOrganizationDict = useDebouncedCallback(setOrganizationSearchValue, 400);
  const debouncedFetchDepartmentDict = useDebouncedCallback(setDepartmentSearchValue, 400);

  return (
    <UiFlex vertical ref={ref}>
      <UiForm.Item shouldUpdate noStyle>
        {({ setFieldValue, getFieldValue }) => {
          const organizationValue = getFieldValue('organizationId');
          const isLoading = isOrganizationDictLoading && organizationValue;

          return (
            <UiForm.Item name="organizationId" label="Организация">
              <UiSelect
                allowClear
                showSearch
                filterOption={false}
                placeholder="Выбрать организацию"
                disabled={isLoading}
                options={organizationOptions}
                loading={isOrganizationDictLoading}
                onSearch={debouncedFetchOrganizationDict}
                getPopupContainer={getPopupContainer}
                onChange={() => {
                  setFieldValue('departmentId', undefined);
                }}
              />
            </UiForm.Item>
          );
        }}
      </UiForm.Item>
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isLoading = isDepartmentDictLoading && getFieldValue('departmentId');

          return (
            <UiForm.Item name="departmentId" label="Подразделение">
              <UiSelect
                disabled={!organizationId || isLoading}
                allowClear
                showSearch
                filterOption={false}
                placeholder="Выбрать подразделение"
                options={departmentOptions}
                loading={isDepartmentDictLoading}
                onSearch={debouncedFetchDepartmentDict}
                getPopupContainer={getPopupContainer}
              />
            </UiForm.Item>
          );
        }}
      </UiForm.Item>
    </UiFlex>
  );
};
