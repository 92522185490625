import { ActionsDropdownItem } from '@vkph/components';

import {
  FileStorageApiVersions,
  UploadFileStorageMultipleAttachmentsEffect,
} from '@vkph/common/store/filestorage';
import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { PostAttachmentTypes } from '@vkph/common/types/models';
import { FileUploadAccepts, openFileDialog } from '@vkph/common/utils';

import { OnAttachmentUpdateParams } from '../../attachments/adapter';
import { attachmentActionFields, singleAttachmentTypes } from './constants';

type GetAttachmentsActionByTypeFn = {
  type: PostAttachmentTypes;
  uploadEffect: UploadFileStorageMultipleAttachmentsEffect;
  callback: (params: OnAttachmentUpdateParams) => void;
};

export interface AttachmentsAction extends ActionsDropdownItem {
  type: PostAttachmentTypes;
}

const getAttachmentActionByType = (params: GetAttachmentsActionByTypeFn) => {
  const { type, callback, uploadEffect } = params;

  if (type === PostAttachmentTypes.Poll) {
    openGlobalModal(GlobalModalNames.PollEditor, {
      onSuccess: (poll) =>
        callback({ attachmentId: poll.id, attachmentType: PostAttachmentTypes.Poll, attachment: poll }),
    });
  }

  if (type === PostAttachmentTypes.Event) {
    openGlobalModal(GlobalModalNames.EventsList, {
      onSelect: (event) =>
        callback({ attachmentId: event.id, attachmentType: PostAttachmentTypes.Event, attachment: event }),
    });
  }

  if (type === PostAttachmentTypes.Image || type === PostAttachmentTypes.File) {
    const accept = type === PostAttachmentTypes.Image ? FileUploadAccepts.ImageAll : FileUploadAccepts.All;

    openFileDialog({ multiple: true, accept }).then((files) => {
      uploadEffect({
        files,
        apiVersion: FileStorageApiVersions.v3,
      }).then((uploadedFiles) =>
        uploadedFiles.forEach((uploadedFile) =>
          callback({ attachmentId: uploadedFile.id, attachmentType: type, attachment: uploadedFile }),
        ),
      );
    });
  }
};

export const getAttachmentActions = (
  uploadEffect: GetAttachmentsActionByTypeFn['uploadEffect'],
  callback: GetAttachmentsActionByTypeFn['callback'],
): AttachmentsAction[] => {
  return Object.values(PostAttachmentTypes).map((attachmentType) => {
    const type = attachmentType as PostAttachmentTypes;

    return {
      type,
      label: attachmentActionFields[attachmentType].label,
      icon: attachmentActionFields[attachmentType].icon,
      onClick: () => getAttachmentActionByType({ type, uploadEffect, callback }),
    };
  });
};

export const isSingleAttachmentType = (type: PostAttachmentTypes) => singleAttachmentTypes.has(type);
