import { DictPaginated } from '../api';
import { EntityContentData } from '../editor';
import { AttachmentEntryId, AttachmentTypes } from './attachment.model';
import { FileStorageEntryId, FileStorageEntryUrl } from './file.model';
import { UserAuthorModel, FullNameModel, UserModel, UserAvatarModel, UserPositionModel } from './user.model';

export type SurveyId = number;
export type SendingSurveyId = string;
export type SurveyQuestionId = string;
export type GroupsMailingId = string;
export type SurveyGroupId = string;
export type SurveyChoicePosition = number;
export type SurveyChoiceId = string;

export enum SurveySubpages {
  All = 'all',
  Mailings = 'mailings',
  History = 'history',
}

export enum SurveyStatus {
  Draft = 'draft',
  Active = 'active',
  Inactive = 'inactive',
}

export enum SendingSurveyType {
  NotAnonymous = 'not_anonymous',
  Anonymous = 'anonymous',
}

export interface SurveyAuthor extends UserModel {
  fullName: FullNameModel;
}

export interface SurveyCover {
  coverId: FileStorageEntryId | null;
  coverUrl: FileStorageEntryUrl | null;
}

export interface SurveyModel extends SurveyCover {
  id: SurveyId;
  description: EntityContentData;
  title: string;
  author: SurveyAuthor;
  status: SurveyStatus;
}

export enum SurveyQuestionType {
  STRING_SINGLE_CHOICE = 'STRING_SINGLE_CHOICE',
  STRING_MULTIPLE_CHOICE = 'STRING_MULTIPLE_CHOICE',
  IMAGE_SINGLE_CHOICE = 'IMAGE_SINGLE_CHOICE',
  FREE_ANSWER = 'FREE_ANSWER',
  FREE_PARAGRAPH = 'FREE_PARAGRAPH',
  RATING_STAR = 'RATING_STAR',
  RATING_SMILE = 'RATING_SMILE',
}

export enum SurveyFreeAnswerFormatType {
  Text = 'text',
  Number = 'number',
  Email = 'email',
  Phone = 'phone',
  Employee = 'employee',
}

export type SurveyChoice = {
  imageId?: FileStorageEntryId;
  imageUrl?: FileStorageEntryUrl;
  textAnswer?: string;
  isOther?: boolean;
  positionChoice?: number;
};

export type SurveyQuestion = {
  id: SurveyQuestionId;
  text: string;
  isRequired: boolean;
  isRequiredComment: boolean;
  isCommented: boolean;
  type: SurveyQuestionType;
  formatString?: SurveyFreeAnswerFormatType;
  choice?: SurveyChoice[];
};

export type SurveyAttachment = {
  id: AttachmentEntryId;
  entityType: AttachmentTypes.File;
  entityId: AttachmentEntryId;
  surveyId: SurveyId;
};

export interface SurveyDetailedModel extends SurveyModel {
  questions?: SurveyQuestion[];
  authorId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum SurveySendingStatus {
  Completed = 'completed',
  Future = 'future',
  Relevant = 'relevant',
}

export type SurveyListModel = DictPaginated<SurveyModel>;
interface BaseSurveySendingModel {
  id: SendingSurveyId;
  typeSendingSurvey: SendingSurveyType;
  status: SurveySendingStatus;
  startAt: string;
  endAt: string;
  authorSending: SurveyAuthor;
  surveyId: SurveyId;
}

export interface SurveySendingModel extends BaseSurveySendingModel {
  mailingGroups: string[];
  userInvites: string[];
}
export type SurveySendingParams = Omit<SurveySendingModel, 'authorSending' | 'status' | 'id'>;
export type SurveySendingUpdateParams = Omit<SurveySendingModel, 'authorSending' | 'status'>;
export type GroupsMailingSendingParams = {
  title?: string;
  members?: string[];
  excludeMembers?: string[];
};

export interface GroupsMailingUpdateParams extends GroupsMailingSendingParams {
  id: SurveyGroupId;
}

export interface SurveyGroupListItemModel {
  id: SurveyGroupId;
  title: string;
  countMember: number;
  countSurvey: number;
}

export type SurveyGroupListModel = DictPaginated<SurveyGroupListItemModel>;

export interface GroupsMailingListItemModel {
  id: GroupsMailingId;
  title: string;
  countMembers: number;
  countSurveys: number;
  createdAt: string;
  updatedAt: string;
}

export type SurveyGroupMailingCreateModal = {
  title: string;
  members?: UserAuthorModel[];
  excludeMembers?: UserAuthorModel[];
  isAllUsers: boolean;
};

export type GroupsMailingListModel = DictPaginated<GroupsMailingListItemModel>;

type GroupMemberJob = {
  position: UserPositionModel;
};

export interface GroupsMailingGroupMemberModel extends UserAvatarModel {
  id: SurveyGroupId;
  fullName: FullNameModel;
  job: GroupMemberJob;
}

export type MemberSurveyQuestionsAnswerModel = {
  questionId: SurveyQuestionId;
  choicePositions: number[];
  textAnswer: string;
  comment: string;
  spentTime: number;
};

type SurveyQuestionForMember = Omit<SurveyQuestion, 'isRequiredComment'>;
export interface MemberSurveyQuestions extends SurveyQuestionForMember {
  isAnswered?: boolean;
  isCurrentQuestions?: boolean;
  isRequiredComment?: string;
  maxScale?: number;
  isNegative?: boolean;
  accuracy?: number;
  answer: MemberSurveyQuestionsAnswerModel;
}

// TODO будет добавлена пагинация для вопросов
export type MemberSurveyQuestionsModel = {
  items: MemberSurveyQuestions[];
};

type SurveyModelForMember = Omit<SurveyModel, 'questions' | 'id'>;
export interface MemberSurveysDetailModal extends SurveyModelForMember {
  countQuestions: number;
  typeSendingSurvey: SendingSurveyType;
  surveyId: SurveyId;
  authorId: UserModel['keycloakUser'];
  currentQuestion: number;
  createdAt: string;
  updatedAt: string;
}

export type SendingSurveyParams = {
  sendingSurveyId: SendingSurveyId;
};

// TODO:: Где то используется authorSending, где то AuthorId у бэка.
//  Желательно привести к одному базовому виду SurveySendingModel.

export type MailingGroups = {
  id: string;
  title: string;
};

interface SendingSurveyDetail extends SurveyModel {
  authorId: UserModel['keycloakUser'];
  createdAt: string;
  updatedAt: string;
}

export interface SendingSurveyInstanceModel extends BaseSurveySendingModel {
  surveyTitle: string;
  userInvites: UserAuthorModel[];
  mailingGroups: MailingGroups[];
}

export interface SurveySendingGroupDetailModel extends BaseSurveySendingModel {
  authorId: UserModel['keycloakUser'];
  progress: number;
  survey: SendingSurveyDetail;
}

export enum MemberPassStatus {
  NotOpen = 'not_open',
  Finished = 'finished',
  NotFinished = 'not_finished',
}

interface MemberSurveySendingInfo extends Omit<BaseSurveySendingModel, 'authorSending'> {
  authorId: UserModel['keycloakUser'];
}

export interface SurveySendingDetailModel extends BaseSurveySendingModel {
  authorId: UserModel['keycloakUser'];
  progress: number;
  countUsersInvites: number;
}

export interface SurveySendingMemberModel {
  id: SendingSurveyId;
  status: MemberPassStatus;
  sendingCountCompleted: number;
  sendingCountMembers: number;
  member: UserAuthorModel;
  survey: SendingSurveyDetail;
  surveySending: MemberSurveySendingInfo;
}

interface SurveyQuestionStatisticDetail {
  questionAnswer: string;
  countAnswerQuestions: number;
  percentCountAnswerQuestions: number;
  countComments: number;
  positionChoice: number;
}

export interface SurveyQuestionStatistic {
  totalAnswers: number;
  percentTotalAnswers: number;
  questionType: SurveyQuestionType;
  missed: number;
  percentMissed: number;
  questionId: SurveyQuestionId;
  questionText: string;
  formatText: string;
  more: SurveyQuestionStatisticDetail[];
}

export enum SurveyStatisticKeys {
  CountMembers = 'countMembers',
  CountAcceptedMembers = 'countAcceptedMembers',
  RatePassage = 'ratePassage',
  CountViewers = 'countViewers',
  CountNoPassed = 'countNoPassed',
  AverageParticipationTime = 'averageParticipationTime',
}

export type SurveyQuestionStatisticCommonTypes = Record<SurveyStatisticKeys, number>;

export type SurveyQuestionStatisticCommon = {
  label: SurveyStatisticKeys;
  value: number;
};

export type SurveyQuestionStatisticCommonList = SurveyQuestionStatisticCommon[];

export enum MemberSurveysStatuses {
  All = 'all',
  NotOpen = 'not_open',
  Looked = 'looked',
  Completed = 'completed',
}

export type MemberSurveysModal = {
  description: string;
  sendingSurveyId: SendingSurveyId;
  typeSendingSurvey: SendingSurveyType;
  status: MemberSurveysStatuses;
  startAt: string;
  endAt: string;
  authorSending: SurveyAuthor;
  countQuestions: number;
  countCompletedQuestions: number;
  title: string;
  coverId: FileStorageEntryId;
  coverUrl: FileStorageEntryUrl;
};

export type MemberSurveysListModel = DictPaginated<MemberSurveysModal>;

export type SurveyChoiceParams = {
  sendingSurveyId: SendingSurveyId;
  questionId: SurveyQuestionId;
  positionChoice: SurveyChoicePosition;
};

export interface SurveyChoiceModel extends SurveyChoiceParams {
  amount: number;
  percent: number;
  choiceText: string;
  questionText: string;
  sendingType: SendingSurveyType;
}

export type SurveyChoiceDetailsModel = {
  member?: UserAuthorModel;
  comment?: string;
};

export type SurveyGroupMailingCloneModel = {
  groupId: SurveyGroupId;
};

export type CreateGroupData = {
  title: string;
  excludeMembers?: string[];
  members?: string[];
};

export interface UpdateGroupData extends CreateGroupData {
  id: GroupsMailingId;
}

export interface SurveyChoiceListModel
  extends Pick<SurveyChoiceModel, 'positionChoice' | 'amount' | 'percent'> {
  id: SurveyChoiceId;
  answer: string;
  comments: number;
}
