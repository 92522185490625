import { PageNotFound, ErrorBoundary, ScrollToTopButton } from '@vkph/components';
import React, { FC, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { LONGREAD_ROUTE_PATH, useMatchRoutes } from '@vkph/common/hooks';
import { PrivateRouteProvider } from '@vkph/common/providers';
import { buildRoutesFromConfig, RouteNames, routes } from '@vkph/common/utils';
import { dynamicRenderBlogRoutes } from '~groups/components/blog/constants';

import { GlobalModals } from '../../global-modals';
import { UserAgreementGuard } from '../../user-agreement-guard/UserAgreementGuard';
import { AdminRoute } from '../admin-route';
import { DynamicRenderRoute } from '../dynamic-render-route';
import { LongreadRoute } from '../longread-route';
import { adminPrivatePagesMap, pagesMap, publicPagesMap, sharedPagesMap } from '../pages';
import { PrivateRoute } from '../private-route';

const dynamicRenderRouteModuleMap = dynamicRenderBlogRoutes.reduce<Record<string, string>>((acc, route) => {
  acc[route] = 'BlogGalleryWizardModule';
  return acc;
}, {});

export const Router: FC = () => {
  const location = useLocation();
  const { longreadLocation } = location.state || {};
  const isLongread = useMatch(LONGREAD_ROUTE_PATH);
  const [isPrivateRoute, setIsPrivateRoute] = useState(false);
  const matchesDynamicRenderRoutes = useMatchRoutes(dynamicRenderBlogRoutes);

  return (
    <PrivateRouteProvider value={{ isPrivateRoute, setIsPrivateRoute }}>
      <ErrorBoundary>
        {(!(isLongread || matchesDynamicRenderRoutes) || longreadLocation || isPrivateRoute) && (
          <Routes location={longreadLocation || location}>
            {buildRoutesFromConfig(publicPagesMap)}
            <Route Component={PrivateRoute}>
              <Route path="/" element={<Navigate to={routes[RouteNames.Home]} replace />} />
              {buildRoutesFromConfig(pagesMap)}
              {buildRoutesFromConfig(sharedPagesMap)}
              <Route Component={AdminRoute}>{buildRoutesFromConfig(adminPrivatePagesMap)}</Route>
              <Route path="*" Component={PageNotFound} />
            </Route>
          </Routes>
        )}
        {isLongread && (
          <Routes>
            <Route path={LONGREAD_ROUTE_PATH} Component={LongreadRoute} />
          </Routes>
        )}
        {matchesDynamicRenderRoutes && !isPrivateRoute && (
          <Routes>
            <Route path="/" element={<DynamicRenderRoute routeModuleMap={dynamicRenderRouteModuleMap} />}>
              {buildRoutesFromConfig(sharedPagesMap)}
            </Route>
            <Route path="*" Component={PageNotFound} />
          </Routes>
        )}
        <GlobalModals />
        <UserAgreementGuard />
        <ScrollToTopButton />
      </ErrorBoundary>
    </PrivateRouteProvider>
  );
};
