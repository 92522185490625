import React, { PropsWithChildren } from 'react';

import { useDnDSortable, UseDnDSortableProps } from '../../hooks';
import { UiDnDSortableItem, UiDnDSortableItemProps } from '../item/UiDnDSortableItem';

export type UiDnDSortableDragItemProps<Data> = Pick<UseDnDSortableProps<Data>, 'id' | 'data'> &
  PropsWithChildren<Partial<UiDnDSortableItemProps>>;

export const UiDnDSortableDragItem = <Data,>(props: UiDnDSortableDragItemProps<Data>) => {
  const { id, data, disabled, hoverable, children, useForwardRef, className, style, dragMode } = props;
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
    active,
    over,
    activeIndex,
    overIndex,
    isOver,
  } = useDnDSortable<Data>({
    id,
    data,
    disabled,
  });

  return (
    <UiDnDSortableItem
      ref={setNodeRef}
      disabled={disabled}
      hoverable={hoverable}
      isDragging={isDragging}
      style={style}
      className={className}
      {...attributes}
      listeners={listeners}
      transform={transform}
      transition={transition}
      useForwardRef={useForwardRef}
      dragMode={dragMode}
      active={active}
      over={over}
      activeIndex={activeIndex}
      overIndex={overIndex}
      isOver={isOver}
    >
      {children}
    </UiDnDSortableItem>
  );
};
