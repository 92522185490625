import { NotificationTypes } from '@vkph/common/types/models';

const commentCreatedDefaultDescription = 'Оставил(а) комментарий';
const commentReactionDefaultDescription = 'Отреагировал(а) на ваш комментарий';
const commentUserMentionedDefaultDescription = 'Упомянул(а) вас в комментарии';
const commentReplyDefaultDescription = 'Ответил(а)';

export const notificationDescriptionValues: Partial<Record<NotificationTypes, string>> = {
  [NotificationTypes.AlbumImageCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.AlbumImageCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.AlbumImageCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.AlbumImageCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.BlogEntryCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.BlogEntryCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.BlogEntryCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.BlogEntryCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.BlogEntryCreated]: 'Создан пост',
  [NotificationTypes.BlogEntryUpdated]: 'Обновлен пост',
  [NotificationTypes.BlogEntryRequireModeration]: 'Предложен пост',
  [NotificationTypes.BlogEntryUserMentioned]: 'Упомянул(а) вас в посте',
  [NotificationTypes.BlogEntryReactionCreated]: 'Отреагировал(а) на пост',
  [NotificationTypes.BlogUserInviteRequestClosedBlog]: 'Подал(а) заявку на вступление в группу',
  [NotificationTypes.BlogUserApproveRequestClosedBlog]: 'Подтвердил(а) заявку на вступление в группу',
  [NotificationTypes.BlogUserRejectRequestClosedBlog]: 'Отклонил(а) заявку на вступление в группу',
  [NotificationTypes.CalendarGroupEventAttendeeApproveDecision]: 'Пользователь принял приглашение',
  [NotificationTypes.CalendarGroupEventAttendeeRejectDecision]: 'Пользователь отклонил приглашение',
  [NotificationTypes.CalendarGroupEventAttendeeMaybeDecision]: 'Пользователь возможно пойдет',
  [NotificationTypes.CalendarGroupEventInvite]: 'Приглашение на встречу',
  [NotificationTypes.CalendarGroupEventUpdated]: 'Изменил(а) встречу',
  [NotificationTypes.CalendarGroupEventDeleteAttendee]: 'Исключил(а) вас из встречи',
  [NotificationTypes.SitectorPageUserMentioned]: 'Упоминание вас на странице',
  [NotificationTypes.EventsDelete]: 'Мероприятие отменено',
  [NotificationTypes.EventsInvite]: 'Приглашение на мероприятие',
  [NotificationTypes.FileVersionCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.FileVersionCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.FileVersionCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.FileVersionCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.GamificationEventsManualGiveBadges]:
    'Вами получена новая награда, вы можете посмотреть ее в профиле',
  [NotificationTypes.MicropostReactionCreated]: 'Отреагировал(а) на микропост',
  [NotificationTypes.GamificationEventsAutomaticGiveBadges]:
    'Вами получена новая награда, вы можете посмотреть ее в профиле',
  [NotificationTypes.MicropostCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.MicropostCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.MicropostCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.MicropostCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.BlogMicropostUserMentioned]: 'Упомянул(а) вас в персональном блоге',
  [NotificationTypes.TimelinesSignerMicropostCreated]: 'Опубликовал(а) пост в персональном блоге',
  [NotificationTypes.NewsCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.NewsCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.NewsCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.NewsCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.SitectorCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.SitectorCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.SitectorCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.SitectorCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.NewsUserMentioned]: 'Упомянул(а) вас в новости',
  [NotificationTypes.FileStorageFileShared]: 'Отправил(а) ссылку на файл',
  [NotificationTypes.IdeaApprove]: 'Идея одобрена',
  [NotificationTypes.IdeaDecline]: 'Идея отклонена',
  [NotificationTypes.IdeaRequiredReview]: 'Идея подана на рассмотрение',
  [NotificationTypes.UserBadgeCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.UserBadgeCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.UserBadgeReactionCreated]: 'Отреагировал(а) на награду',
  [NotificationTypes.UserBadgeCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.UserBadgeCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.ThanksCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.ThanksCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.ThanksReactionCreated]: 'Отреагировал(а) на благодарность',
  [NotificationTypes.ThanksCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.ThanksCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.RecordCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.RecordCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.RecordCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.RecordCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.TasksAssignee]: 'Новая задача',
  [NotificationTypes.TasksChangeAttributes]: 'Изменения в задаче',
  [NotificationTypes.TasksCompleted]: 'Задача выполнена',
  [NotificationTypes.TaskCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.IdeaCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.IdeaCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.IdeaCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.ProjectCommentReactionCreated]: commentReactionDefaultDescription,
  [NotificationTypes.ListRowCreated]: 'Создание записи в списке',
  [NotificationTypes.ListRowUpdated]: 'Изменение записи в списке',
  [NotificationTypes.ListRowDeleted]: 'Удаление записи в списке',
};
