import {
  getModalStepsForSingleTitle,
  UiButton,
  UiDrawer,
  UiDrawerProps,
  UiForm,
  UiModal,
  UiSpace,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC } from 'react';

import { hintMap, HintType } from '@vkph/common/store';
import { FileStorageEntryType, FileStorageListEntryModel } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';

import {
  FileListManagerPermissionFilestorageForm,
  FileListManagerPermissionFilestorageFormValues,
} from './form/FileListManagerPermissionFilestorageForm';

interface Props extends UiDrawerProps {
  filestorageObject: FileStorageListEntryModel;
  onClose?: () => void;
  open?: boolean;
}

export const FileListManagerPermissionFilestorage: FC<Props> = (props) => {
  const { onClose, open, filestorageObject } = props;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { space2XS, spaceM } = useSpace();
  const isFolder = filestorageObject?.type === FileStorageEntryType.Folder;

  const [form] = UiForm.useForm<FileListManagerPermissionFilestorageFormValues>();

  const actionsBtn = (
    <>
      <UiButton block={!isLayoutLarge} type="primary" size="large" onClick={form.submit} label="Сохранить" />
      <UiButton block={!isLayoutLarge} type="secondary" size="large" onClick={onClose} label="Отмена" />
    </>
  );

  const hintViewList = isFolder
    ? hintMap[HintType.FilestorageFolderView]
    : hintMap[HintType.FilestorageFileView];
  const hintEditList = isFolder
    ? hintMap[HintType.FilestorageFolderEdit]
    : hintMap[HintType.FilestorageFileEdit];

  const content = (
    <>
      <UiSpace size={space2XS} direction="vertical" style={{ marginBottom: spaceM }}>
        <UiTypography.Text type="secondary">{`Название ${isFolder ? 'папки' : 'файла'}`}</UiTypography.Text>
        <UiTypography.Title level={3}>{filestorageObject.name}</UiTypography.Title>
      </UiSpace>
      <UiForm form={form} size="large" layout="vertical">
        <FileListManagerPermissionFilestorageForm
          filestorageEntryType={filestorageObject?.type}
          filestorageObjectId={filestorageObject.id}
          onClose={onClose}
          type={isFolder ? 'folder' : 'file'}
          hintViewList={hintViewList}
          hintEditList={hintEditList}
        />
      </UiForm>
    </>
  );

  return (
    <>
      {isLayoutLarge && (
        <>
          <UiModal.Header hasBottomBorder>
            <UiModal.Header.Title
              steps={getModalStepsForSingleTitle(`Настройка доступов ${isFolder ? 'папки' : 'файла'}`)}
            />
          </UiModal.Header>
          <UiModal.Content basePadding>{content}</UiModal.Content>
          <UiModal.Footer hasTopBorder>
            <UiModal.Footer.Buttons>{actionsBtn}</UiModal.Footer.Buttons>
          </UiModal.Footer>
        </>
      )}
      {!isLayoutLarge && (
        <UiDrawer
          height="100vh"
          open={open}
          onClose={onClose}
          placement="bottom"
          title="Настройки доступа"
          footer={
            <UiSpace size="middle" direction="vertical" full>
              {actionsBtn}
            </UiSpace>
          }
        >
          {content}
        </UiDrawer>
      )}
    </>
  );
};
