import { fileStorageContextRouteMap, getFileStorageRouteParams } from '@vkph/common/store/filestorage';
import {
  LongreadTypes,
  NewsLongreadParams,
  PostLongreadParams,
  TimelineLongreadParams,
} from '@vkph/common/types/longread';
import {
  PostTypes,
  SiteServiceType,
  TimelineCommentAlbumImageContentModel,
  TimelineCommentContentModels,
  TimelineCommentFileVersionContentModel,
  TimelineCommentMicropostContentModel,
  TimelineCommentNewsContentModel,
  TimelineCommentPostContentModel,
  TimelineCommentRecordContentModel,
  TimelineCommentSitectorPageContentModel,
  TimelineCommentThanksContentModel,
  TimelineCommentUserBadgeContentModel,
  TimelineRecordModel,
  TimelineTypes,
} from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import { isTimelineRecordModelSomeOfTypes } from '../../../types/guards/timeline';
import { TimelineCardCommentEntry, TimelineCardCommentEntryTypes } from './TimelineCardComment';

export const getTimelineCardCommentEntry = (
  record: TimelineRecordModel<TimelineCommentContentModels>,
): TimelineCardCommentEntry | null => {
  const {
    content: { comment, parent: parentComment },
  } = record;

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentNewsContentModel>(record, [
      TimelineTypes.CommentCreatedNews,
      TimelineTypes.CommentReplyNews,
    ])
  ) {
    const { content } = record;
    const { news } = content;

    const longreadParams: NewsLongreadParams = {
      type: LongreadTypes.News,
      id: news.id,
      newsType: PostTypes.Simple,
    };

    return {
      type: TimelineCardCommentEntryTypes.Longread,
      title: news.title,
      comment,
      parentComment,
      longreadParams,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentPostContentModel>(record, [
      TimelineTypes.CommentCreatedEntry,
      TimelineTypes.CommentReplyEntry,
    ])
  ) {
    const { content } = record;
    const { entry: post } = content;

    const longreadParams: PostLongreadParams = {
      type: LongreadTypes.Post,
      id: post.id,
      postType: PostTypes.Entry,
    };

    return {
      type: TimelineCardCommentEntryTypes.Longread,
      title: post?.title || 'Перейти к посту',
      comment,
      parentComment,
      longreadParams,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentMicropostContentModel>(record, [
      TimelineTypes.CommentCreatedMicropost,
      TimelineTypes.CommentReplyMicropost,
    ])
  ) {
    const { content } = record;
    const { micropost } = content;

    const longreadParams: PostLongreadParams = {
      type: LongreadTypes.Post,
      id: micropost.id,
      postType: PostTypes.Micropost,
    };

    return {
      type: TimelineCardCommentEntryTypes.Longread,
      title: micropost?.title || 'Перейти к посту',
      comment,
      parentComment,
      longreadParams,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentFileVersionContentModel>(record, [
      TimelineTypes.CommentCreatedFileVersion,
      TimelineTypes.CommentReplyFileVersion,
    ])
  ) {
    const { content } = record;
    const { file, fileVersion } = content;

    const url =
      file?.context &&
      getRoutePath(fileStorageContextRouteMap[file.context.service], {
        ...getFileStorageRouteParams(file),
        fileId: file.id,
      });

    return {
      type: TimelineCardCommentEntryTypes.Entry,
      title: `${file.name} (Версия ${fileVersion.revision})`,
      comment,
      parentComment,
      url,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentAlbumImageContentModel>(record, [
      TimelineTypes.CommentCreatedAlbumImage,
      TimelineTypes.CommentReplyAlbumImage,
    ])
  ) {
    const { content } = record;
    const { blog } = content;
    const { slug, id } = blog;

    return {
      type: TimelineCardCommentEntryTypes.Entry,
      title: `В альбоме группы: ${blog.name}`,
      comment,
      parentComment,
      url: { pathname: getRoutePath(RouteNames.GroupView, { id: slug || id }), hash: '#picture' },
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentSitectorPageContentModel>(record, [
      TimelineTypes.CommentCreatedSitectorPage,
      TimelineTypes.CommentReplySitectorPage,
    ])
  ) {
    const { content } = record;
    const { sitector } = content;
    const { name, site, slug: pageSlug } = sitector;

    return {
      type: TimelineCardCommentEntryTypes.Entry,
      title: name,
      comment,
      parentComment,
      url: getRoutePath(RouteNames.Site, {
        slug: site?.slug,
        serviceType: SiteServiceType.Page,
        serviceSlug: pageSlug,
      }),
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentThanksContentModel>(record, [
      TimelineTypes.CommentCreatedThanks,
      TimelineTypes.CommentReplyThanks,
    ])
  ) {
    const { content } = record;
    const { thanks } = content;
    const { attributes, toUser } = thanks;
    const { name } = attributes;

    return {
      type: TimelineCardCommentEntryTypes.Entry,
      title: name,
      comment,
      parentComment,
      url: getRoutePath(RouteNames.Profile, { id: toUser.id }),
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentUserBadgeContentModel>(record, [
      TimelineTypes.CommentCreatedUserBadge,
      TimelineTypes.CommentReplyUserBadge,
    ])
  ) {
    const { content } = record;
    const { userBadge } = content;
    const { badge, user } = userBadge;
    const { name } = badge;

    return {
      type: TimelineCardCommentEntryTypes.Entry,
      title: name,
      comment,
      parentComment,
      url: getRoutePath(RouteNames.Profile, { id: user.id }),
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentRecordContentModel>(record, [
      TimelineTypes.CommentCreatedRecord,
      TimelineTypes.CommentReplyRecord,
    ])
  ) {
    const { content } = record;
    const { record: innerRecord } = content;
    const { content: innerRecordContent, id } = innerRecord;
    const { oldPosition, oldGroup, group, position } = innerRecordContent;
    const actionWord = !oldGroup && !oldPosition ? 'Принят(а) ' : 'Переведен(а) ';
    const groupName = group ? `в ${group.name} ` : '';
    const positionName = position ? `на должность ${position.name}` : '';

    const longreadParams: TimelineLongreadParams = { type: LongreadTypes.Timeline, id };

    return {
      type: TimelineCardCommentEntryTypes.Longread,
      title: `${actionWord}${groupName}${positionName}`,
      comment,
      parentComment,
      longreadParams,
    };
  }

  return null;
};
