import { EditorValue } from '../editor';
import { CommentCountModel } from './comment.model';
import { FileStorageEntryId } from './file.model';
import { CreateIssuerDateModel, UpdateIssuerDateModel } from './issuerDate.model';
import {
  PermissionRoleId,
  RolePermission,
  PermissionRoles,
  SitesAllowedPermission,
} from './permissions.model';
import { ReactableModel } from './reactions.model';
import { TagId } from './tags.model';
import { FullNameModel, UserAvatarModel, UserIdModel, UserModel, UserProfileModel } from './user.model';

export type SiteId = string;
export type SiteSlug = string;
export type SiteNavbarItemId = string;
export type SiteServiceId = string;
export type SiteServiceSlug = string;
export type SitePageId = string;

export enum SiteStatus {
  Published = 'published',
  Draft = 'draft',
}

export enum SitePageStatus {
  Published = 'published',
  Draft = 'draft',
}

export enum SiteServiceType {
  Page = 'page',
  News = 'news',
  Calendar = 'calendar',
  List = 'list',
  FileStorage = 'filestorage',
  ExternalLink = 'external_link',
  Site = 'site',
}

export interface SiteBaseModel {
  id: SiteId;
  name: string;
  slug: SiteSlug;
}

export interface SiteModel
  extends SiteBaseModel,
    CreateIssuerDateModel<UserProfileModel>,
    UpdateIssuerDateModel<UserProfileModel> {
  filestorageRootFolderId: FileStorageEntryId;
  description: string;
  status: SiteStatus;
  isNavigationVisible: boolean;
  parent?: SiteId;
  avatar?: string;
  permissions: SitesAllowedPermission[];
}

export type SiteServiceAdditionalModel = {
  isMain?: boolean;
};

export interface SiteServiceModel {
  id: SiteServiceId;
  siteId: SiteId;
  name: string;
  additional: SiteServiceAdditionalModel;
  slug: SiteServiceSlug;
  type: SiteServiceType;
  isVisible: boolean;
  visibleChildrenCount: number;
  hiddenChildrenCount: number;
  externalId?: SiteServiceId;
  parent?: SiteServiceModel;
}

export type SiteNavbarItemModel = {
  id: SiteNavbarItemId;
  name: string;
  slug: SiteSlug;
  type: SiteServiceType;
  externalId: SiteServiceId;
  parentId?: SiteServiceId;
  children: SiteNavbarItemModel[];
  additional: SiteServiceAdditionalModel;
};

export type SitePageSettings = {
  isReacted: boolean;
  isComments: boolean;
  isViewsCount: boolean;
};

type SiteTag = { id: TagId; name: string };
export interface SitePageModel
  extends CreateIssuerDateModel<UserProfileModel>,
    UpdateIssuerDateModel<UserProfileModel>,
    ReactableModel,
    CommentCountModel {
  id: SitePageId;
  externalId: SiteServiceId;
  name: string;
  slug: SiteServiceSlug;
  body: EditorValue;
  status: SiteStatus;
  viewsCount: number;
  isMain?: boolean;
  parent?: Omit<SitePageModel, 'parent'>;
  isFavorite: boolean;
  settings: SitePageSettings;
  tags?: SiteTag[];
  site?: SiteBaseModel;
}

export type SiteNavbarSortModel = {
  id: SiteServiceId;
  type: SiteServiceType;
  children: SiteNavbarSortModel[];
};

export interface SiteAuthorModel
  extends FullNameModel,
    UserAvatarModel,
    Pick<UserModel, 'username' | 'email' | 'position' | 'gender' | 'isActive'> {
  keycloakId: UserIdModel;
  fullName: Omit<FullNameModel, 'patronymic'>;
}

export interface SiteRoleModel extends Pick<RolePermission, 'name' | 'subscope' | 'scope'> {
  id: PermissionRoleId;
  displayName: PermissionRoles;
  isInstanceSpecific: boolean;
  isSystemDefined: boolean;
}
