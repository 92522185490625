import { asyncBlogsEndpoints } from './asyncBlogs';
import { blogsEndpoints } from './blogs';
import { calendarEndpoints } from './calendar';
import { commentsEndpoints } from './comments';
import { dictionariesEndpoints } from './dictionaries';
import { discoveryEndpoints } from './discovery';
import { eventsEndpoints } from './events';
import { filestorageEndpoints } from './filestorage';
import { gamificationEndpoints } from './gamification';
import { ideasEndpoints } from './ideas';
import { informersEndpoints } from './informers';
import { listsEndpoints } from './lists';
import { meetingsEndpoints } from './meetings';
import { navigationEndpoints } from './navigation';
import { newsEndpoints } from './news';
import { notificationsEndpoints } from './notifications';
import { orgstructureEndpoints } from './orgstructure';
import { pagesEndpoints } from './pages';
import { pollsEndpoints } from './polls';
import { profilesEndpoints } from './profiles';
import { reactionsEndpoints } from './reactions';
import { reportsEndpoints } from './reports';
import { rolesEndpoints } from './roles';
import { settingsEndpoints } from './settings';
import { sitectorEndpoints } from './sites';
import { surveyEndpoints } from './survey';
import { tagsEndpoints } from './tags';
import { tasksEndpoints } from './tasks';
import { tenantsEndpoints } from './tenants';
import { termsOfUseEndpoints } from './termsOfUse';
import { timelinesEndpoints } from './timelines';
import { widgetsEndpoints } from './widgets';

export const endpoints = {
  asyncBlogs: asyncBlogsEndpoints,
  blogs: blogsEndpoints,
  calendar: calendarEndpoints,
  comments: commentsEndpoints,
  dictionaries: dictionariesEndpoints,
  discovery: discoveryEndpoints,
  events: eventsEndpoints,
  filestorage: filestorageEndpoints,
  gamification: gamificationEndpoints,
  informers: informersEndpoints,
  ideas: ideasEndpoints,
  meetings: meetingsEndpoints,
  news: newsEndpoints,
  notifications: notificationsEndpoints,
  orgstructure: orgstructureEndpoints,
  pages: pagesEndpoints,
  polls: pollsEndpoints,
  profiles: profilesEndpoints,
  reactions: reactionsEndpoints,
  reports: reportsEndpoints,
  roles: rolesEndpoints,
  settings: settingsEndpoints,
  survey: surveyEndpoints,
  tasks: tasksEndpoints,
  tags: tagsEndpoints,
  tenants: tenantsEndpoints,
  termsOfUse: termsOfUseEndpoints,
  timelines: timelinesEndpoints,
  widgets: widgetsEndpoints,
  navigation: navigationEndpoints,
  sitector: sitectorEndpoints,
  lists: listsEndpoints,
};
