import {
  UiButton,
  UiFileDirection,
  UiFlex,
  UiIcon,
  UiIconLabelProps,
  UiSelect,
  UiTypographyTextTypes,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useFileStorageDisplayType } from '@vkph/common/providers';
import { useSpace } from '@vkph/ui/hooks';
import { variables, useTheme } from '@vkph/ui/providers/theme';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import CheckboxIndeterminateSvg from '@vkph/ui/svg/checkbox-indeterminate.svg';
import CheckboxUncheckedSvg from '@vkph/ui/svg/checkbox-unchecked.svg';
import CheckboxSvg from '@vkph/ui/svg/checkbox.svg';
import TaskCardSvg from '@vkph/ui/svg/task-card.svg';
import WidgetsSvg from '@vkph/ui/svg/widgets.svg';

import { ControlBarLeftActionProps } from '../ControlBarLeftActions';

export const ControlBarLeftActionsMobile: FC<ControlBarLeftActionProps> = (props) => {
  const {
    onCreateFolderClick,
    isSearching,
    selectedCount,
    totalCount,
    onCancel,
    onSelectAll,
    onDeselectAll,
    isMultiSelect,
    isManageFilesStorage,
  } = props;
  const [{ variables: themeVariables }] = useTheme();
  const isAllEntriesSelected = selectedCount === totalCount;
  const { spaceM } = useSpace();

  const counterData = useMemo(() => {
    if (selectedCount === 0) {
      return {
        icon: CheckboxUncheckedSvg,
        color: variables.themePalette.colorIcon,
      };
    }

    return {
      icon: selectedCount === totalCount ? CheckboxSvg : CheckboxIndeterminateSvg,
      color: themeVariables.colorBrand,
    };
  }, [selectedCount, totalCount]);

  const DirectionSelectOptionLabel: FC<UiIconLabelProps> = (labelProps) => {
    return <UiIcon.Label type={UiTypographyTextTypes.Secondary} width={20} height={20} {...labelProps} />;
  };

  const [displayType, setDisplayType] = useFileStorageDisplayType();

  const directionOptions = useMemo(
    () => [
      {
        value: UiFileDirection.Horizontal,
        label: <DirectionSelectOptionLabel component={TaskCardSvg} label="Таблица" />,
      },
      {
        value: UiFileDirection.Vertical,
        label: <DirectionSelectOptionLabel component={WidgetsSvg} label="Плитка" />,
      },
    ],
    [],
  );

  return (
    <UiFlex gap={spaceM}>
      {!isMultiSelect && !isSearching && isManageFilesStorage && (
        <UiButton
          size="large"
          onClick={onCreateFolderClick}
          type="tertiary"
          label="Создать папку"
          icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
        />
      )}
      <UiSelect
        style={{ width: 136 }}
        defaultValue={displayType}
        onSelect={setDisplayType}
        optionLabelProp="label"
        options={directionOptions}
      />
      {isMultiSelect && totalCount > 0 && (
        <>
          <UiIcon.Label
            type={UiTypographyTextTypes.Primary}
            color={counterData.color}
            component={counterData.icon}
            label={`${selectedCount} из ${totalCount}`}
          />
          <UiButton
            type="link"
            onClick={isAllEntriesSelected ? onDeselectAll : onSelectAll}
            label={`${isAllEntriesSelected ? 'Снять выделение' : 'Выбрать все'}`}
          />
          <UiButton onClick={onCancel} type="link-secondary" label="Отмена" />
        </>
      )}
    </UiFlex>
  );
};
