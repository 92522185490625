import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiIcon, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { EventModel, NotificationModel, NotificationTypes } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath, utcToLocalDate } from '@vkph/common/utils';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { isNotificationType } from '../../../../types/guards/notification-guards';
import { getNotificationDescription } from '../../NotificationsDrawer';
import { NotificationEventActions } from './actions/NotificationEventActions';

interface Props {
  notification: NotificationModel<EventModel>;
}

export const NotificationEvent: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    notificationType,
  } = notification;
  const { id, name, since, isRemoved } = content;

  const linkProps = useLongreadLink({ type: LongreadTypes.Events, id });
  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const truncatedName = (
    <UiTruncateMarkup truncate lines={2}>
      {name}
    </UiTruncateMarkup>
  );

  const eventStartTime = useMemo(() => utcToLocalDate(since), [since]);
  const isNotificationEventsInvite = isNotificationType(NotificationTypes.EventsInvite, notificationType);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          {isNotificationEventsInvite && (
            <UiSpace size={4} direction="vertical">
              <RouterLink {...linkProps} disabled={isRemoved}>
                {truncatedName}
              </RouterLink>
              <UiIcon.Label component={CalendarSvg} label={eventStartTime} />
            </UiSpace>
          )}
          {!isNotificationEventsInvite && <UiTypography.Text strong>{truncatedName}</UiTypography.Text>}
          {isNotificationEventsInvite && <NotificationEventActions notification={notification} />}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
