import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import humps from 'humps';
import isPlainObject from 'lodash/isPlainObject';

export interface SendParams extends AxiosRequestConfig {
  headersQuery?: Record<string, string>;
}

type AdditionalParams = {
  [key in string | number]: string | number;
};

type AuthParams = Partial<{
  token: string;
  type: string;
}>;

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const CACHE_TTL = 1 * 1000; // 1s

export class api {
  private static authParams: AuthParams = {};
  public static setAuthParams(params: AuthParams) {
    api.authParams = { ...params };
  }

  private static additionalParams = {};
  public static setAdditionalParams(params: AdditionalParams) {
    api.additionalParams = { ...api.additionalParams, ...params };
  }

  private static headersQueryParams = {};
  public static setHeadersQueryParams(params: AdditionalParams) {
    api.headersQueryParams = { ...api.headersQueryParams, ...params };
  }

  public static generateAuthAuthorizationHeader = (
    auth: AuthParams,
    key = 'Authorization',
  ): { [K: string]: string } => ({ [key]: [auth.type, auth.token].join(' ') });

  public static get baseUrl() {
    if (isDevelopment) {
      return '/api-proxy';
    }

    return process.env.REMOTE_URL || '';
  }

  private static axiosCacheInstance = setupCache(
    axios.create({
      baseURL: api.baseUrl,
      headers: { accept: 'application/json' },
    }),
    {
      ttl: CACHE_TTL,
    },
  );

  public static async send<ResponseType>(params: SendParams) {
    const { headersQuery, ...otherAxiosConfigProps } = params;
    const isAxiosCacheEnabled = localStorage.getItem('isAxiosCacheEnabled') === 'true';

    const baseURL = api.baseUrl;
    const { additionalParams, headersQueryParams } = api;

    if (otherAxiosConfigProps.data && !(otherAxiosConfigProps.data instanceof window.FormData)) {
      otherAxiosConfigProps.data = humps.decamelizeKeys(params.data);
    }

    if (otherAxiosConfigProps.params) {
      otherAxiosConfigProps.params = humps.decamelizeKeys(params.params);
    }

    otherAxiosConfigProps.params = {
      ...otherAxiosConfigProps.params,
      ...additionalParams,
    };

    const axiosRequestParams = {
      ...(baseURL && { baseURL }),
      ...otherAxiosConfigProps,
      headers: {
        accept: 'application/json',
        ...api.generateAuthAuthorizationHeader(api.authParams),
        ...headersQuery,
        ...headersQueryParams,
      },
    };

    try {
      if (isAxiosCacheEnabled) {
        return await api.axiosCacheInstance.request<ResponseType>(axiosRequestParams);
      }

      return await axios.request<ResponseType>(axiosRequestParams);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      return Promise.reject(e);
    }
  }

  static async sendCamelizingData(params: SendParams) {
    return api.transformDataFromSnakeToCamelCase(api.send({ ...params }));
  }

  static async transformDataFromSnakeToCamelCase(responsePromise: Promise<AxiosResponse>) {
    const response = await responsePromise;
    const { data } = response;

    if (isPlainObject(data) || Array.isArray(data)) {
      return {
        ...response,
        data: humps.camelizeKeys(data),
      };
    }

    return responsePromise;
  }

  static async get<ResponseType>(params: SendParams): Promise<AxiosResponse<ResponseType>> {
    return api.sendCamelizingData({
      ...params,
      method: 'get',
    });
  }

  static async delete<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'delete',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async post<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'post',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async put<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'put',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async patch<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'patch',
    }) as Promise<AxiosResponse<ResponseType>>;
  }
}
