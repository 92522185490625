import { UploadDraggerArea } from '@vkph/components';
import {
  message,
  UiButton,
  UiForm,
  UiModal,
  UiOptionData,
  UiUploadFile,
  UiUploadFileList,
  UiUploadFileStatus,
  UiUploadFileType,
  UiUploadOriginFile,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { useUnmount } from '@vkph/common/hooks';
import { createUpdateFileStorageEntryEffect } from '@vkph/common/store/filestorage';
import { closeGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { FileStorageCreatePayload } from '@vkph/common/types/file-storage';
import { FileStorageEntryInfoModel } from '@vkph/common/types/models';
import { FileUploadAccepts, generateTagApiOptions, getErrorResponseMessage } from '@vkph/common/utils';
import { UploadFile } from '@vkph/common/utils/effector';
import { OptionModel } from '@vkph/ui/types/option';
import { getModalStepsForSingleTitle, getSplitFileNameExtension } from '@vkph/ui/utils';

import { FileStorageCreateUpdateFields } from '../fields/FileStorageCreateUpdateFields';

export type FileStorageCreateUpdateFormValues = Pick<
  FileStorageEntryInfoModel<UiOptionData>,
  'id' | 'name' | 'description' | 'tags' | 'categories'
>;

export const FileStorageCreateModal: FC<FileStorageCreatePayload> = (props) => {
  const { onSuccess: onSuccessModal, parent, uploadStore } = props;
  const {
    store: uploadFilesStore,
    uploadFilesEvent,
    removeFilesEvent,
    resetFilesEvent,
    refetchFileEvent,
  } = uploadStore;
  const preloadedFiles = useStore(uploadFilesStore);
  const isCreatePending = useStore(createUpdateFileStorageEntryEffect.pending);

  const [form] = UiForm.useForm<FileStorageCreateUpdateFormValues>();

  useUnmount(resetFilesEvent);

  const onCloseModal = () => closeGlobalModal(GlobalModalNames.FileStorageCreate);

  const preloadedFile = useMemo(() => {
    if (preloadedFiles && preloadedFiles.length) {
      return {
        ...preloadedFiles[0],
        fileData: {
          ...preloadedFiles[0].fileData,
          name: preloadedFiles[0].fileData.name,
          status: preloadedFiles[0].status,
          error: preloadedFiles[0].errorMessage,
          progressEvent: preloadedFiles[0]?.progressEvent,
        },
      };
    }

    return null;
  }, [preloadedFiles]);

  const { name: fileName, extension } = useMemo(() => {
    return getSplitFileNameExtension(preloadedFile ? preloadedFile.fileData.name : '');
  }, [preloadedFile, getSplitFileNameExtension]);

  useEffect(() => {
    if (fileName) {
      form.setFieldValue('name', fileName);
    }
  }, [fileName, getSplitFileNameExtension]);

  const onFinish = () => {
    const { id, tags, categories, name, ...restFormValues } = form.getFieldsValue(true);
    const params = {
      ...restFormValues,
      name: extension ? `${name}.${extension}` : name,
      tags: generateTagApiOptions(tags),
      categories: categories?.map(({ value }: OptionModel) => value),
      id: preloadedFile ? preloadedFile.fileData.uid : id,
      parent,
    };

    createUpdateFileStorageEntryEffect(params)
      .then((data) => {
        message.success('Файл успешно создан');
        onSuccessModal?.(data);
        onCloseModal();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка создания файла')));
  };

  const isSaveDisabled = useMemo(() => {
    const disabledStatuses: UiUploadFileStatus[] = ['uploading', 'error'];
    const isDisabledByStatus = preloadedFile?.status && disabledStatuses.includes(preloadedFile.status);

    return isDisabledByStatus && !form.getFieldValue('id');
  }, [preloadedFile, form]);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      uploadFilesEvent({ filesToUpload: uploadFileList, appendData: false });
    },
    [uploadFilesEvent],
  );

  const onPickFiles = useCallback(
    (files: UiUploadOriginFile[]) => {
      uploadFiles(files);
    },
    [uploadFiles],
  );

  const onRemoveFile = useCallback(() => {
    if (preloadedFile) {
      removeFilesEvent([preloadedFile.key]);
    }
  }, [removeFilesEvent, preloadedFile]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузить файл')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm size="large" onFinish={onFinish} form={form} requiredMark>
          <FileStorageCreateUpdateFields extension={extension} parent={parent}>
            <UiForm.Item required label="Файл">
              {!preloadedFile && (
                <UploadDraggerArea
                  multiple={false}
                  onPickFiles={onPickFiles}
                  accept={FileUploadAccepts.All}
                  description="Максимальный размер файла: 2 Гб"
                />
              )}
              {preloadedFile && (
                <UiUploadFileList
                  type={UiUploadFileType.Progress}
                  files={[preloadedFile.fileData satisfies UiUploadFile]}
                  onDelete={onRemoveFile}
                  onRefetch={refetchFileEvent}
                />
              )}
            </UiForm.Item>
          </FileStorageCreateUpdateFields>
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Сохранить"
            onClick={form.submit}
            size="large"
            type="primary"
            disabled={isSaveDisabled}
            loading={isCreatePending}
          />
          <UiButton label="Отмена" onClick={onCloseModal} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
