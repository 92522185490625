import React, { PropsWithChildren, useMemo, useState } from 'react';

import { useSpace } from '../../../../hooks';
import { UiDnDActiveType, UiDnD, dndClosesCenterCollision } from '../../../dnd';
import {
  getDnDDragEndArray,
  dndHorizontalListSortingStrategy,
  snapDnDItemToCursor,
} from '../../../dnd/utils';
import { ColumnType, UiTableProps } from '../../UiTable';
import { UiTableDnDCellOverlay } from '../cell-overlay/UiTableDnDCellOverlay';
import { TableDnDConfigProps } from '../useTableDnDConfig';

type UiTableDnDContextHeaderProps<T extends object = Record<string, unknown>> = Pick<
  UiTableProps<T>,
  'columns'
> &
  Pick<TableDnDConfigProps<T>, 'onColumnDragEnd' | 'renderCellOverlay'>;

export const UiTableDnDContextHeader = <T extends Record<string, unknown>>(
  props: PropsWithChildren<UiTableDnDContextHeaderProps<T>>,
) => {
  const { children, columns = [], onColumnDragEnd, renderCellOverlay, ...restProps } = props;
  const [activeItem, setActiveItem] = useState<UiDnDActiveType | null>(null);
  const { spaceXL } = useSpace();

  const overlayContent = useMemo(() => {
    if (activeItem) {
      const column = columns?.[activeItem.data?.current?.sortable?.index];

      if (renderCellOverlay) {
        return renderCellOverlay({ id: activeItem.id, column });
      }

      return typeof column?.title === 'function' ? column.title({}) : column.title;
    }

    return null;
  }, [activeItem, columns, renderCellOverlay]);

  return (
    <thead {...restProps}>
      <UiDnD.Context<ColumnType<T>>
        modifiers={[snapDnDItemToCursor({ horizontalShift: spaceXL })]}
        collisionDetection={dndClosesCenterCollision}
        onDragStart={({ active }) => {
          setActiveItem(active);
        }}
        onDragEnd={(event) => {
          Promise.resolve(getDnDDragEndArray(event, columns)).then(onColumnDragEnd);

          setActiveItem(null);
        }}
      >
        <UiDnD.Sortable.Context
          items={columns.map((item) => item.key as string)}
          strategy={dndHorizontalListSortingStrategy}
        >
          {children}
        </UiDnD.Sortable.Context>
        <UiDnD.Overlay>
          {overlayContent && <UiTableDnDCellOverlay>{overlayContent}</UiTableDnDCellOverlay>}
        </UiDnD.Overlay>
      </UiDnD.Context>
    </thead>
  );
};
