import {
  UiButton,
  UiForm,
  UiIcon,
  message,
  UiTypography,
  UiUploadOriginFile,
  UiUpload,
  UiUploadProps,
  UiUploadFileType,
  UiUploadRequestOption,
  UploadChangeParam,
  UiUploadFile,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useState, useEffect, useMemo } from 'react';

import {
  AddAttachmentParams,
  FileStorageApiVersions,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import { FileId, FileStorageEntryId, PreloadedFileModel } from '@vkph/common/types/models';
import { UiUploadFileAttach, UiUploadOriginFileAttach } from '@vkph/common/types/uploadsFormItem';
import { checkFileSizeUploadEffect } from '@vkph/common/utils';
import ClearSVG from '@vkph/ui/svg/clear.svg';

import { UploadsFormListItem } from '../list-item/UploadsFormListItem';
import styles from './UploadsFormItem.scss';

const MAX_FILES_COUNT = 10;
const MAX_FILES_TOTAL_SIZE = 10;

export interface UploadsFormItemProps extends UiUploadProps {
  name: string;
  buttonLabel: string;
  formItemLabel?: string;
  uploadFileType: UiUploadFileType;
  fileSizeText?: string;
  maxTotalSize?: number;
  maxCount?: number;
  disabled?: boolean;
  formItemClassName?: string;
  onAddAttachment?: ({ id, size, name, url }: AddAttachmentParams) => void;
}

const patchAttachProperty = (file: UiUploadFileAttach<FileStorageEntryId>) => {
  if (!file.attachmentId) {
    // eslint-disable-next-line no-param-reassign
    file.attachmentId = file.originFileObj?.attachmentId;
  }
};

const getFileListFromEvent = (e: { fileList: UiUploadFileAttach<FileId | FileStorageEntryId> }) => e.fileList;

export const UploadsFormItem: FC<UploadsFormItemProps> = (props) => {
  const {
    name,
    buttonLabel,
    formItemLabel,
    disabled = false,
    uploadFileType,
    fileSizeText,
    maxTotalSize = MAX_FILES_TOTAL_SIZE,
    maxCount = MAX_FILES_COUNT,
    formItemClassName,
    onAddAttachment,
    onRemove,
    multiple = true,
    style,
    ...restProps
  } = props;

  const uploadAttachmentEffectV3 = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(FileStorageApiVersions.v3);
  }, []);

  const [maxAmountError, setMaxAmountError] = useState(false);
  const [maxTotalSizeError, setMaxTotalSizeError] = useState(false);

  const clearIcon = <UiIcon component={ClearSVG} width={20} height={20} />;

  useEffect(() => {
    if (maxAmountError) {
      message.error(`Общее количество файлов должно быть не больше ${maxCount}`);
      setMaxAmountError(false);
    }

    if (maxTotalSizeError) {
      message.error(`Общий вес файлов должен быть не больше ${maxTotalSize}Мб`);
      setMaxTotalSizeError(false);
    }
  }, [maxAmountError, maxTotalSizeError]);

  const changesInFiles = useMemo(() => {
    return new Map<UiUploadFileAttach<FileStorageEntryId>['uid'], UiUploadFileAttach<FileStorageEntryId>>();
  }, []);

  const onCustomRequest = (options: UiUploadRequestOption) => {
    const { file, onSuccess, onError } = options;
    const uploadedFile = file as UiUploadOriginFileAttach<FileStorageEntryId | FileId>;

    uploadAttachmentEffectV3({ file: uploadedFile })
      .then(({ storageObject, fileUrl }) => {
        const status = changesInFiles.get(uploadedFile.uid)?.status;
        const isSkipUpload = status && ['removed', 'error'].includes(status);

        if (!isSkipUpload) {
          uploadedFile.attachmentId = storageObject;
        }

        // onSuccess, onError - не удалять, перестанет работать статус загрузки (UploadFileStatus)
        onSuccess?.({ ...uploadedFile, url: fileUrl });
      })
      .catch((error) => onError?.(error));
  };

  const onChange = ({ file }: UploadChangeParam) => {
    changesInFiles.set(file.uid, file);
  };

  const onRemoveFile = (file: UiUploadFile) => {
    const currentFile = file as UiUploadOriginFileAttach<FileStorageEntryId>;

    patchAttachProperty(currentFile);

    if (currentFile.attachmentId) {
      onRemove?.(currentFile);
    }
  };

  const onBeforeUpload = async (file: UiUploadOriginFile, fileList: UiUploadOriginFile[]) => {
    await checkFileSizeUploadEffect({ file }).catch((e) => {
      message.error(`${file.name}. ${e}`);
      return UiUpload.LIST_IGNORE;
    });

    if (!maxAmountError && maxCount && fileList.length > maxCount) {
      setMaxAmountError(true);
      return UiUpload.LIST_IGNORE;
    }

    if (maxTotalSize) {
      const summarySize = fileList.reduce((acc, currentFile) => acc + currentFile.size, 0);
      const isLessThanMaxSize = summarySize / 1024 / 1024 < maxTotalSize;

      if (!isLessThanMaxSize && !maxTotalSizeError) {
        setMaxTotalSizeError(true);
      }

      return isLessThanMaxSize || UiUpload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <UiForm.Item
      name={name}
      valuePropName="fileList"
      getValueFromEvent={getFileListFromEvent}
      className={classNames(styles.uploadsFormItem, formItemClassName)}
      label={formItemLabel}
      style={style}
    >
      <UiUpload
        multiple={multiple}
        maxCount={maxCount}
        showUploadList={{ showRemoveIcon: true, removeIcon: clearIcon }}
        beforeUpload={onBeforeUpload}
        customRequest={onCustomRequest}
        onChange={onChange}
        onRemove={onRemoveFile}
        itemRender={(...params) => <UploadsFormListItem itemRenderParams={params} type={uploadFileType} />}
        disabled={disabled}
        {...restProps}
      >
        <UiButton type="link" label={buttonLabel} disabled={disabled} />
        {fileSizeText && (
          <UiTypography.Footnote type="secondary" style={{ display: 'block', margin: '16px 0' }}>
            {fileSizeText}
          </UiTypography.Footnote>
        )}
      </UiUpload>
    </UiForm.Item>
  );
};
