import { UiAvatarProps } from '@vkph/ui';
import React, { FC } from 'react';

import { fileStorageContextRouteMap, getFileStorageRouteParams } from '@vkph/common/store/filestorage';
import { NotificationFileVersionCommentModel, NotificationModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames, parseEntityText } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../NotificationsDrawer';
import { NotificationComment, NotificationTargetTypes } from '../../comment';

interface Props {
  notification: NotificationModel<NotificationFileVersionCommentModel>;
}

export const NotificationFileVersionComment: FC<Props> = (props) => {
  const { notification } = props;

  const { image, createdAt, initiator, content } = notification;
  const { fullName, keycloakId, isActive } = initiator;
  const { fileVersion, file, comment, parent } = content;
  const { name, id } = file;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const targetDescription = `К версии ${fileVersion.revision}, файла:`;
  const targetUrl =
    file?.context &&
    getRoutePath(fileStorageContextRouteMap[file.context.service], {
      ...getFileStorageRouteParams(file),
      fileId: id,
    });

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      subtitle={notificationDescription}
      avatarProps={avatar}
      isActive={isActive}
      to={userLink}
      commentText={commentText}
      replyText={replyText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Page}
      targetName={name}
      targetUrl={targetUrl}
      targetDescription={targetDescription}
      disabled={file.isDeleted}
    />
  );
};
