import { Image as AntImage, ImageProps as AntImageProps } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import ImgPlaceholderSvg from '@vkph/ui/svg/img-placeholder.svg';

import { UiIcon } from '../icon';
import styles from './UiImage.scss';
import {
  UiImagePreview,
  UiImagePreviewGroup,
  UiImagePreviewToolbar,
  UiImagePreviewButton,
  UiImagePreviewPropExtended,
  UiImagePreviewGroupProps,
} from './preview';

export type UiImagePreviewGroupPropsExtended = UiImagePreviewGroupProps;

export type UiImagePreviewProp = UiImagePreviewPropExtended | boolean;

export interface UiImageProps extends Omit<AntImageProps, 'preview'> {
  placeholderSize?: number;
  rounded?: boolean;
  preview?: UiImagePreviewProp;
  transparent?: boolean;
}

type UiImageComposition = {
  Preview: typeof UiImagePreview;
  PreviewGroup: typeof UiImagePreviewGroup;
  PreviewToolbar: typeof UiImagePreviewToolbar;
  PreviewButton: typeof UiImagePreviewButton;
};

type UiImageComponent = FC<UiImageProps> & UiImageComposition;

type ImageStatus = 'normal' | 'error' | 'loading';

export const UiImage: UiImageComponent = (props) => {
  const {
    alt = '',
    src: imgSrc,
    width = '100%',
    height,
    placeholder,
    className,
    wrapperStyle,
    wrapperClassName,
    onError: onImageError,
    placeholderSize,
    rounded = true,
    preview = false,
    transparent,
    ...imageProps
  } = props;

  const isCustomPlaceholder = placeholder && placeholder !== true;
  const [status, setStatus] = useState<ImageStatus>(isCustomPlaceholder ? 'loading' : 'normal');

  const isError = status === 'error';
  const isShowPlaceholder = isError || !imgSrc;

  const wrapperClass = classNames(
    styles.uiImage,
    {
      [styles.uiImage__background]: isShowPlaceholder,
      [styles.uiImage__background_transparent]: transparent,
      [styles.uiImage_rounded]: rounded,
    },
    wrapperClassName,
  );

  const imageClassNames = classNames(
    {
      [styles.uiImage__image]: !isError,
    },
    className,
  );

  const onLoad = () => {
    setStatus('normal');
  };

  const onError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (onImageError) {
      onImageError(e);
    }

    setStatus('error');
  };

  useEffect(() => {
    if (!imgSrc) {
      setStatus('error');
    }

    if (!isError) {
      setStatus('normal');
    }
  }, [imgSrc]);

  const errorPlaceholder = useMemo(() => {
    if (isShowPlaceholder) {
      return (
        placeholder || (
          <UiIcon
            width={placeholderSize || '100%'}
            height={placeholderSize || '100%'}
            component={ImgPlaceholderSvg}
            className={styles.uiImage__placeholder}
          />
        )
      );
    }

    return null;
  }, [placeholder, isShowPlaceholder]);

  const imageWrapperStyles: CSSProperties = {
    width,
    height,
    ...wrapperStyle,
  };

  const imagePropsBase: UiImageProps = {
    alt,
    wrapperClassName: wrapperClass,
    wrapperStyle: imageWrapperStyles,
    src: imgSrc,
    onLoad,
    onError,
    loading: 'lazy',
    className: imageClassNames,
    preview,
    ...imageProps,
  };

  const image = errorPlaceholder || <AntImage {...imagePropsBase} />;

  return (
    <div style={imageWrapperStyles} className={wrapperClass}>
      {!preview && image}
      {preview && <UiImagePreview preview={preview}>{image}</UiImagePreview>}
    </div>
  );
};

UiImage.Preview = UiImagePreview;
UiImage.PreviewGroup = UiImagePreviewGroup;
UiImage.PreviewToolbar = UiImagePreviewToolbar;
UiImage.PreviewButton = UiImagePreviewButton;
