import {
  notification,
  UiButton,
  UiFlex,
  UiForm,
  UiIcon,
  UiInput,
  UiSpinner,
  UiSwitch,
  UiTypography,
  useModalBase,
  useSpace,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetListColumnsStorage, getListColumnStorage, GetListRowsStorage } from '@vkph/common/store/lists';
import { DictionaryType, ListColumnFieldType, ListColumnId, ListId } from '@vkph/common/types/models';
import {
  getMaxLengthRule,
  requiredRule,
  MAX_LENGTH_INPUT_250,
  getErrorResponseMessage,
  getFormattedDateForAPI,
  mergeAntDateAndTime,
  getCharactersLeft,
} from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';

type TextFormFieldOptions = {
  defaultCellValue: string;
  maxLength: number;
};

type BooleanFormFieldOptions = {
  defaultCellValue: boolean;
  trueValueName: string;
  falseValueName: string;
};

type NumericFormFieldOptions = {
  defaultCellValue: number;
  minValue?: number;
  maxValue?: number;
};

type DatetimeFormFieldOptions = {
  defaultCellValue: string;
  defaultDate?: Date | null;
  defaultTime?: Date | null;
};

type HyperlinkFormFieldOptions = {
  defaultCellValue: string;
};

type EnumFormFieldOptions = {
  defaultCellValue: string;
  choices: string[];
};

type DictionaryFormFieldOptions = {
  dictionaryType: DictionaryType;
};

interface FormFieldOptions<T>
  extends Omit<TextFormFieldOptions, 'defaultCellValue'>,
    Omit<NumericFormFieldOptions, 'defaultCellValue'>,
    Omit<BooleanFormFieldOptions, 'defaultCellValue'>,
    Omit<HyperlinkFormFieldOptions, 'defaultCellValue'>,
    Omit<DatetimeFormFieldOptions, 'defaultCellValue'>,
    Omit<EnumFormFieldOptions, 'defaultCellValue'> {
  defaultCellValue: T;
}

export interface ColumnCreateUpdateFormValues
  extends FormFieldOptions<string | boolean | number | null>,
    DictionaryFormFieldOptions {
  name: string;
  isRequired: boolean;
  groupValues: boolean;
  onlyDate: boolean;
}

type Props = {
  fieldType: ListColumnFieldType;
  onClose: () => void;
  storage: GetListColumnsStorage;
  rowStorage: GetListRowsStorage['storage'];
  listId: ListId;
  columnId?: ListColumnId;
  onCreateSuccess?: (id: ListColumnId) => void;
  moveColumnToFirst: (id: ListColumnId) => void;
  formFieldsMap?: Record<ListColumnFieldType, ReactNode>;
};

export const CreateUpdateColumn: FC<Props> = (props) => {
  const {
    fieldType,
    columnId = '',
    storage,
    rowStorage,
    listId,
    onClose,
    onCreateSuccess,
    moveColumnToFirst,
    formFieldsMap,
  } = props;
  const { spaceXL, spaceM, space2XS } = useSpace();
  const { createListColumnEffect, updateListColumnEffect } = storage;
  const [form] = UiForm.useForm<ColumnCreateUpdateFormValues>();
  const columnStorage = useMemo(getListColumnStorage, []);
  const { confirm } = useModalBase();
  const isCreateListColumnPending = useStore(createListColumnEffect.pending);
  const isUpdateListColumnPending = useStore(updateListColumnEffect.pending);
  const isEdit = Boolean(columnId);
  const isPending = isCreateListColumnPending || isUpdateListColumnPending;

  const ref = useRef<HTMLDivElement>(null);

  const { data: columnData, loading: isColumnDataLoading } = useAbstractStorage(columnStorage.storage, {
    autoFetchAndRefetch: isEdit,
    autoFetchParams: { id: listId, columnId },
    resetStoreOnChangeParams: { listId, columnId },
  });

  const onEdit = () => {
    confirm({
      title: 'Сохранить изменения в столбце?',
      content: 'При сохранении заполненные ячейки не изменятся',
      okText: 'Сохранить',
      cancelText: 'Отмена',
      getContainer: () => ref.current || document.body,
      centered: true,
      onOk: form.submit,
    });
  };

  const onFormFinish = (values: ColumnCreateUpdateFormValues) => {
    const {
      name,
      isRequired,
      defaultCellValue,
      minValue,
      maxValue,
      defaultTime,
      defaultDate,
      onlyDate,
      ...rest
    } = values;

    let defaultValue = defaultCellValue;

    if (fieldType === ListColumnFieldType.Numeric) {
      defaultValue = defaultValue !== '' && defaultValue !== undefined ? Number(defaultCellValue) : null;
    }

    if (fieldType === ListColumnFieldType.Datetime && defaultDate) {
      const includeTime = defaultTime && !onlyDate;
      const dateTimeValue = includeTime ? mergeAntDateAndTime(defaultDate, defaultTime) : defaultDate;

      defaultValue = getFormattedDateForAPI(dateTimeValue);
    }

    const data = {
      id: listId,
      name,
      fieldType,
      isRequired,
      fieldOptions: {
        ...(defaultValue !== null && { default: defaultValue }),
        onlyDate,
        ...rest,
        ...((minValue || maxValue) && {
          range: {
            minValue: minValue ? Number(minValue) : null,
            maxValue: maxValue ? Number(maxValue) : null,
          },
        }),
      },
    };

    const effect = isEdit
      ? updateListColumnEffect({
          columnId,
          ...data,
        })
      : createListColumnEffect(data);

    effect
      .then((column) => {
        if (!isEdit) {
          onCreateSuccess?.(column.id);
        }

        if (column.fieldOptions.groupValues) {
          moveColumnToFirst(column.id);
        }

        rowStorage.refetchWithLastParams();
        onClose();
      })
      .catch((e) =>
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось добавить столбец') }),
      );
  };

  const initialValues = useMemo(() => {
    if (columnData) {
      const { name, fieldOptions, isRequired } = columnData;

      return {
        name,
        isRequired,
        ...fieldOptions,
        defaultCellValue: fieldOptions.default,
        ...(fieldType === ListColumnFieldType.Numeric && {
          minValue: fieldOptions.range?.minValue,
          maxValue: fieldOptions.range?.maxValue,
        }),
        ...(fieldType === ListColumnFieldType.Datetime && {
          onlyDate: fieldOptions?.onlyDate,
          defaultDate: typeof fieldOptions?.default === 'string' ? new Date(fieldOptions.default) : null,
          defaultTime: typeof fieldOptions?.default === 'string' ? new Date(fieldOptions.default) : null,
        }),
      };
    }

    return undefined;
  }, [columnData]);

  useEffect(() => {
    if (columnData) {
      form.resetFields();
    }
  }, [columnData]);

  return (
    <div ref={ref}>
      <UiForm
        initialValues={initialValues}
        layout="vertical"
        requiredMark
        style={{ width: '100%' }}
        form={form}
        onFinish={onFormFinish}
      >
        <UiSpinner spinning={isColumnDataLoading}>
          <UiFlex gap={spaceXL} vertical>
            <UiTypography.Title level={4}>Настройки столбца</UiTypography.Title>
            <UiForm.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <UiForm.Item
                  name="name"
                  style={{ marginBottom: 0 }}
                  label="Название столбца"
                  required
                  rules={[requiredRule, getMaxLengthRule(MAX_LENGTH_INPUT_250)]}
                  extra={getCharactersLeft(getFieldValue('name')?.length ?? 0, MAX_LENGTH_INPUT_250)}
                >
                  <UiInput autoFocus maxLength={MAX_LENGTH_INPUT_250} />
                </UiForm.Item>
              )}
            </UiForm.Item>
            {formFieldsMap && formFieldsMap[fieldType]}
            <UiForm.Item noStyle name="isRequired" valuePropName="checked" initialValue={false}>
              <UiSwitch label="Сделать столбец обязательным к заполнению" />
            </UiForm.Item>
            <UiForm.Item noStyle name="groupValues" valuePropName="checked" initialValue={false}>
              <UiSwitch
                label={
                  <UiFlex>
                    Группировать значения столбца
                    <UiIcon.Hint
                      style={{ marginLeft: space2XS }}
                      color={variables.themePalette.colorIcon}
                      width={20}
                      height={20}
                      tooltipProps={{
                        title: 'Уведомления об изменениях и удалениях в своих записях списка',
                      }}
                    />
                  </UiFlex>
                }
              />
            </UiForm.Item>
            <UiForm.Item shouldUpdate noStyle>
              {({ isFieldsTouched }) => (
                <UiFlex gap={spaceM}>
                  <UiButton
                    onClick={isEdit ? onEdit : form.submit}
                    block
                    type="primary"
                    label="Сохранить"
                    size="large"
                    disabled={!isFieldsTouched()}
                    loading={isPending}
                  />
                  <UiButton onClick={onClose} block type="tertiary" label="Отмена" size="large" />
                </UiFlex>
              )}
            </UiForm.Item>
          </UiFlex>
        </UiSpinner>
      </UiForm>
    </div>
  );
};
