import { DragOverlay, DragOverlayProps } from '@dnd-kit/core';
import { FC } from 'react';

import { UiDnDOverlayItem } from './item/UiDnDOverlayItem';

type OverlayComposition = {
  Item: typeof UiDnDOverlayItem;
};

type OverlayProps = FC<DragOverlayProps> & OverlayComposition;

export type UiDnDOverlayProps = DragOverlayProps;

export const UiDnDOverlay = DragOverlay as unknown as OverlayProps;

UiDnDOverlay.Item = UiDnDOverlayItem;
