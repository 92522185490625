import { Tree as AntTree } from 'antd';
import { DataNode as AntDataNode } from 'antd/lib/tree';
import { AntTreeNodeProps, TreeProps } from 'antd/lib/tree/Tree';
import classNames from 'classnames';
import { DraggableFn, AllowDropOptions } from 'rc-tree/lib/Tree';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { Direction, EventDataNode, Key as RcKey } from 'rc-tree/lib/interface';
import React, { FC, useRef } from 'react';

import DragSvg from '../../svg/drag.svg';
import DropDownSvg from '../../svg/drop-down.svg';
import { UiFlex } from '../flex';
import { UiIcon } from '../icon';
import { UiTypography } from '../typography';
import styles from './UiTree.scss';

export type UiTreeKey = RcKey;

export interface UiTreeNode extends AntDataNode {
  children?: UiTreeNode[];
}

export type UiTreeNodeParams = AntTreeNodeProps;
export type UiTreeDragEventParams<T = HTMLDivElement> = NodeDragEventParams<UiTreeNode, T>;
export type UiTreeEventDataNode = EventDataNode<UiTreeNode>;
export type UiTreeAllowDropOptions = AllowDropOptions<UiTreeNode>;

export interface UiTreeDropEventParams extends UiTreeDragEventParams {
  dragNode: UiTreeEventDataNode;
  dragNodesKeys: RcKey[];
  dropPosition: number;
  dropToGap: boolean;
}

export type UiTreeDropIndicatorProps = {
  dropPosition: -1 | 0 | 1;
  dropLevelOffset: number;
  indent: number;
  prefixCls: string;
  direction: Direction;
};

export interface UiTreeDragEnterEventParams extends UiTreeDragEventParams {
  expandedKeys: RcKey[];
}

export type UiTreeDropData = {
  treeData: UiTreeNode[];
  dragItem: UiTreeNode;
  dropItem: UiTreeNode;
  dropPosition: number;
};

export interface UiTreeProps extends Omit<TreeProps, 'draggable' | 'onDrop'> {
  draggable?: boolean;
  emptyPaddingLeftNode?: boolean;
  getItemDraggable?: DraggableFn;
  getOverlayTitle?: (node: UiTreeNode) => string;
  onDrop?: (data: UiTreeDropData) => void;
}

export const UiTree: FC<UiTreeProps> = (props) => {
  const {
    className,
    draggable,
    emptyPaddingLeftNode,
    getOverlayTitle,
    getItemDraggable,
    onDragStart,
    onDrop,
    treeData = [],
    ...rest
  } = props;

  const overlayRef = useRef<HTMLDivElement>(null);

  const onInternalDragStart = (info: UiTreeDragEventParams) => {
    const { node, event } = info;

    if (overlayRef.current && event.target instanceof HTMLElement) {
      const lastElement = overlayRef.current.children[overlayRef.current.children.length - 1];
      const rect = event.target.getBoundingClientRect();

      const x = event.clientX - rect.x;
      const y = event.clientY - rect.y;

      overlayRef.current.style.height = `${rect.height}px`;
      lastElement.textContent = getOverlayTitle?.(node) || '';

      event.dataTransfer.setDragImage(overlayRef.current, x, y);
    }

    onDragStart?.(info);
  };

  const reorderTreeData = (
    allItems: UiTreeNode[],
    dragItem: UiTreeNode,
    dropItem: UiTreeNode,
    dropPosition: number,
  ) => {
    const isMoveBottom = dropPosition === 1;
    const isMoveTop = dropPosition === -1;
    const isInsertItem = dropPosition === 0;

    const loopItems = (items: UiTreeNode[]) => {
      const acc: UiTreeNode[] = [];

      items.forEach((item) => {
        const insertItems: UiTreeNode[] = [];

        if (dropItem.key === item.key) {
          if (isMoveTop) insertItems.push(dragItem, item);
          if (isMoveBottom) insertItems.push(item, dragItem);
          if (isInsertItem) insertItems.push({ ...item, children: [dragItem, ...(item.children || [])] });
        } else {
          insertItems.push(item);
        }

        insertItems.forEach((insertItem) => {
          const { children = [] } = insertItem;

          acc.push({ ...insertItem, children: loopItems(children) });
        });
      });

      return acc;
    };

    return loopItems(allItems);
  };

  const findDragAndDropItems = (
    allItems: UiTreeNode[],
    dragNode: UiTreeEventDataNode,
    dropNode: UiTreeEventDataNode,
  ) => {
    let dragItem: UiTreeNode | undefined;
    let dropItem: UiTreeNode | undefined;

    const loopItems = (items: UiTreeNode[]) => {
      const acc: UiTreeNode[] = [];

      items.forEach((item) => {
        if (item.key === dragNode.key) {
          dragItem = item;
        }

        if (item.key === dropNode.key) {
          dropItem = item;
        }

        if (item.key !== dragNode.key) {
          const { children = [] } = item;

          acc.push({ ...item, children: loopItems(children) });
        }
      });

      return acc;
    };

    return { items: loopItems(allItems), dragItem, dropItem };
  };

  const onInternalDrop = (info: UiTreeDropEventParams) => {
    const { dragNode, node: dropNode, dropPosition } = info;

    const lastDropPosition = parseInt(dropNode.pos.split('-').at(-1) || '0', 10);
    const relativeDropPosition = dropPosition - lastDropPosition;
    const { items, dragItem, dropItem } = findDragAndDropItems(treeData, dragNode, dropNode);

    if (!dragItem || !dropItem) {
      return;
    }

    const newTreeData = reorderTreeData(items, dragItem, dropItem, relativeDropPosition);

    onDrop?.({ treeData: newTreeData, dragItem, dropItem, dropPosition: relativeDropPosition });
  };

  const dragIcon = <UiIcon component={DragSvg} width={20} height={20} />;

  const treeProps = {
    className: classNames(styles.uiTree, className, {
      [styles.uiTree_emptyPaddingLeftNode]: emptyPaddingLeftNode,
    }),
    treeData,
    switcherIcon: <UiIcon component={DropDownSvg} width={20} height={20} />,
  };

  const draggableProps = {
    ...treeProps,
    onDragStart: onInternalDragStart,
    onDrop: onInternalDrop,
    draggable: {
      icon: dragIcon,
      nodeDraggable: getItemDraggable,
    },
  };

  return (
    <>
      {draggable && (
        <UiFlex ref={overlayRef} className={styles.uiTree__draggable__overlay} align="center">
          {dragIcon}
          <UiTypography.Text className={styles.uiTree__draggable__overlay__text} />
        </UiFlex>
      )}
      <AntTree {...(draggable ? draggableProps : treeProps)} {...rest} />
    </>
  );
};
