import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { useTheme } from '../../../providers/theme';
import SunSvg from '../../../svg/sun.svg';
import { UiIcon } from '../../icon';
import { UiImage } from '../../image';
import styles from './UiEmptyFeed.scss';
import { UiEmptyFeedMessage, UiEmptyMessageType } from './message/UiEmptyFeedMessage';

export type UiEmptyFeedProps = {
  emptyMessage?: UiEmptyMessageType;
  bordered?: boolean;
  className?: string;
  emptyPadding?: boolean;
  icon?: ReactNode;
};

type EmptyFeedComposition = {
  Message: typeof UiEmptyFeedMessage;
};

export const EMPTY_STATE_SVG_DEFAULT = SunSvg;

export const UiEmptyFeed: FC<UiEmptyFeedProps> & EmptyFeedComposition = (props) => {
  const { emptyMessage, bordered = false, className, emptyPadding = false, icon } = props;
  const [{ images: themeImages }] = useTheme();
  const emptyImageSrc = themeImages.emptyState;

  let IconComponent: ReactNode = <UiIcon component={EMPTY_STATE_SVG_DEFAULT} width={120} height={104} />;

  if (emptyImageSrc) {
    IconComponent = <UiImage width={120} src={emptyImageSrc} />;
  }

  if (icon) {
    IconComponent = icon;
  }

  return (
    <div
      className={classnames(styles.emptyFeed, className, {
        [styles.emptyFeed_bordered]: bordered,
        [styles.emptyFeed_emptyPadding]: emptyPadding,
      })}
    >
      {IconComponent}
      <UiEmptyFeedMessage emptyMessage={emptyMessage} className={styles.emptyFeed_emptyPadding} />
    </div>
  );
};

UiEmptyFeed.Message = UiEmptyFeedMessage;
