import { UiButton, UiDnD, UiIcon, usePaddingStyle, useSpace } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

import { ListColumnModel } from '@vkph/common/types/models';
import { UiFlex } from '@vkph/ui/components/flex';
import DragSvg from '@vkph/ui/svg/drag.svg';
import HideSvg from '@vkph/ui/svg/hide.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { TableHeaderCellTitle } from '../../header-cell/title/TableHeaderCellTitle';

interface Props {
  column: ListColumnModel;
  onVisibleToggle?: () => void;
  disableDrag?: boolean;
  extra?: ReactNode;
}

export const ListCellItem: FC<Props> = (props) => {
  const { column, extra, disableDrag, onVisibleToggle } = props;
  const { id, fieldType, name, isVisible } = column;
  const { spaceXS, space2XS } = useSpace();
  const { padding } = usePaddingStyle([spaceXS, space2XS]);

  return (
    <UiDnD.Sortable.DragItem id={id} hoverable dragMode="static" disabled={disableDrag}>
      <UiFlex style={{ padding }} justify="space-between">
        <UiFlex gap={spaceXS}>
          {!disableDrag && <UiDnD.Sortable.DragIcon component={DragSvg} visible />}
          <UiButton
            onClick={onVisibleToggle}
            type="link-secondary"
            icon={<UiIcon width={20} height={20} component={isVisible ? ViewSvg : HideSvg} />}
          />
          <TableHeaderCellTitle label={name} fieldType={fieldType} />
        </UiFlex>
        {extra && <UiFlex gap={spaceXS}>{extra}</UiFlex>}
      </UiFlex>
    </UiDnD.Sortable.DragItem>
  );
};
