import { UiSkeleton, useBreakpoint } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

const ControlBarLeftActionsDesktop = React.lazy(() => import('./desktop'));
const ControlBarLeftActionsMobile = React.lazy(() => import('./mobile'));

export type ControlBarLeftActionProps = {
  isSearching?: boolean;
  isMultiSelect?: boolean;
  isMultiSelectPermission?: boolean;
  isManageFilesStorage?: boolean;
  onCreateFolderClick: () => void;
  onMultipleModeClick: () => void;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onCancel: () => void;
  selectedCount: number;
  totalCount: number;
  foundItemsCount: number;
};

export const ControlBarLeftActions: FC<ControlBarLeftActionProps> = (props) => {
  const { lg: isLayoutLarge } = useBreakpoint();

  if (isLayoutLarge) {
    return (
      <Suspense fallback={<UiSkeleton loading />}>
        <ControlBarLeftActionsDesktop {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSkeleton loading />}>
      <ControlBarLeftActionsMobile {...props} />
    </Suspense>
  );
};
