import { Notification } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationModel, NotificationThanksReactionModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../NotificationsDrawer';

interface Props {
  notification: NotificationModel<NotificationThanksReactionModel>;
}

export const NotificationGamificationThanksReaction: FC<Props> = (props) => {
  const { notification } = props;
  const {
    status,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
  } = notification;

  const description = getNotificationDescription(notification);
  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  return (
    <Notification status={status}>
      <Notification.Header
        title={title}
        subtitle={description}
        avatarProps={avatar}
        to={userLink}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <UiTypography.Text strong>{notification.content.thanks.attributes.name}</UiTypography.Text>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
