import { UiImage, useToggle, UiImagePreviewGroupPropsExtended } from '@vkph/ui';
import isObject from 'lodash/isObject';
import React, { FC } from 'react';

import CommentAltSvg from '@vkph/ui/svg/comment-alt.svg';

export const ImagePreviewGroupWithComments: FC<UiImagePreviewGroupPropsExtended> = (props) => {
  const { items, preview, ...restProps } = props;
  const { customize = {}, sider = {}, ...restPreview } = isObject(preview) ? preview : {};
  const [isSidebarOpen, toggleSidebarOpen] = useToggle([false, true]);

  const comments = (
    <UiImage.PreviewButton icon={CommentAltSvg} active={isSidebarOpen} onClick={() => toggleSidebarOpen()} />
  );

  return (
    <UiImage.PreviewGroup
      items={items}
      preview={{
        toolbarRender: (_, { transform, actions }) => (
          <UiImage.PreviewToolbar transform={transform} actions={actions} customize={customize}>
            {comments}
          </UiImage.PreviewToolbar>
        ),
        sider: {
          placement: 'right',
          visible: isSidebarOpen,
          toggleVisibility: toggleSidebarOpen,
          width: 460,
          ...sider,
        },
        ...restPreview,
      }}
      {...restProps}
    />
  );
};
