import { PostBodyAttachmentsGroup } from '@vkph/components';
import React, { FC, useMemo } from 'react';

import { isPostAttachmentsSomeOfType } from '@vkph/common/types/guards';
import {
  AttachmentEntityId,
  EventModel,
  FileStorageFileModel,
  PollModel,
  PostAttachedEntity,
  PostAttachmentTypes,
  PostFormAttachmentId,
} from '@vkph/common/types/models';

import { Poll } from '../../../poll/Poll';
import { PostEvent } from '../event/PostEvent';
import { PostFiles } from '../files/PostFiles';
import { PostImages } from '../images/PostImages';

export type OnAttachmentUpdateParams = {
  attachmentId: AttachmentEntityId;
  attachmentType: PostAttachmentTypes;
  attachment: PostAttachedEntity;
};

export type OnAttachmentRemoveParams = {
  attachmentId: AttachmentEntityId;
  attachmentType: PostAttachmentTypes;
};

type Props = {
  value: PostBodyAttachmentsGroup;
  isEdit?: boolean;
  onUpdate?: (params: OnAttachmentUpdateParams) => void;
  onRemove?: (params: OnAttachmentRemoveParams) => void;
};

export const PostAttachmentsAdapter: FC<Props> = (props) => {
  const { value, isEdit, onRemove, onUpdate } = props;
  const { attachments: groupedAttachments } = value;

  const renderedAttachments = useMemo(() => {
    if (isPostAttachmentsSomeOfType<PollModel>(groupedAttachments, PostAttachmentTypes.Poll)) {
      return groupedAttachments.map((attachment) => (
        <Poll
          isEdit={isEdit}
          key={attachment.attachmentId}
          poll={attachment.attachedEntity}
          onVote={(poll) =>
            onUpdate?.({
              attachmentId: attachment.attachmentId,
              attachmentType: attachment.attachmentType,
              attachment: poll,
            })
          }
          onDelete={() =>
            onRemove?.({
              attachmentId: attachment.attachmentId,
              attachmentType: attachment.attachmentType,
            })
          }
          onSuccess={(poll) =>
            onUpdate?.({
              attachmentId: attachment.attachmentId,
              attachmentType: attachment.attachmentType,
              attachment: poll,
            })
          }
        />
      ));
    }

    if (isPostAttachmentsSomeOfType<EventModel>(groupedAttachments, PostAttachmentTypes.Event)) {
      const onEventDelete = (attachmentId: PostFormAttachmentId) => {
        onRemove?.({ attachmentId, attachmentType: PostAttachmentTypes.Event });
      };

      return groupedAttachments.map((attachment) => (
        <PostEvent
          onDelete={isEdit ? () => onEventDelete(attachment.attachmentId) : undefined}
          key={attachment.attachmentId}
          event={attachment.attachedEntity}
        />
      ));
    }

    if (isPostAttachmentsSomeOfType<FileStorageFileModel>(groupedAttachments, PostAttachmentTypes.File)) {
      return (
        <PostFiles
          isEdit={isEdit}
          attachments={groupedAttachments}
          onDelete={(attachmentId) => onRemove?.({ attachmentId, attachmentType: PostAttachmentTypes.File })}
        />
      );
    }

    if (isPostAttachmentsSomeOfType<FileStorageFileModel>(groupedAttachments, PostAttachmentTypes.Image)) {
      const onImageDelete = (attachmentId: PostFormAttachmentId) => {
        onRemove?.({ attachmentId, attachmentType: PostAttachmentTypes.Image });
      };

      return <PostImages attachments={groupedAttachments} onDelete={isEdit ? onImageDelete : undefined} />;
    }

    return null;
  }, [groupedAttachments]);

  return <>{renderedAttachments}</>;
};
