import { endpoints } from '../../endpoints';
import { PortalSettingModel, SettingsConfigLayoutModel } from '../../types/models';
import { api } from '../../utils';

export const updateSettingsLayouts = (data: SettingsConfigLayoutModel) =>
  api.put({
    url: endpoints.settings.layouts(),
    data,
  });

export const updatePortalSettings = (data: PortalSettingModel<string>[]) =>
  api.post<PortalSettingModel[]>({
    url: endpoints.settings.portalSettings(),
    data,
  });
