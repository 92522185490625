// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._84d9a0e0_dynamic-table_fix-height{min-height:calc(100vh - 64px - 72px - 24px - 32px - 20px - 20px)}._84d9a0e0_dynamic-table__table__dropdown-action-button{margin-left:auto}._84d9a0e0_dynamic-table__table ._84d9a0e0_dynamic-table__dropdown-action-button{opacity:0}._84d9a0e0_dynamic-table__table .ant-table-row:hover ._84d9a0e0_dynamic-table__dropdown-action-button{opacity:1}._84d9a0e0_dynamic-table__table .ant-table-row-indent+.ant-table-row-expand-icon{transform:translateY(24px)}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/dynamic-table/DynamicTable.scss"],"names":[],"mappings":"AAIE,oCACE,gEAAA,CAOA,wDACE,gBAAA,CAGF,iFACE,SAAA,CAIA,sGACE,SAAA,CAKF,iFACE,0BAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.dynamic-table {\n  $parent: &;\n\n  &_fix-height {\n    min-height: calc(\n      100vh - $layoutBreadcrumbHeight - $layoutHeaderHeight - $spaceXL - $space2XL - $outerGutterLG -\n        $innerGutterLG\n    );\n  }\n\n  &__table {\n    &__dropdown-action-button {\n      margin-left: auto;\n    }\n\n    #{$parent}__dropdown-action-button {\n      opacity: 0;\n    }\n\n    :global(.ant-table-row:hover) {\n      #{$parent}__dropdown-action-button {\n        opacity: 1;\n      }\n    }\n\n    :global {\n      .ant-table-row-indent + .ant-table-row-expand-icon {\n        transform: translateY($spaceXL);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamic-table_fix-height": "_84d9a0e0_dynamic-table_fix-height",
	"dynamicTable_fixHeight": "_84d9a0e0_dynamic-table_fix-height",
	"dynamic-table__table__dropdown-action-button": "_84d9a0e0_dynamic-table__table__dropdown-action-button",
	"dynamicTable__table__dropdownActionButton": "_84d9a0e0_dynamic-table__table__dropdown-action-button",
	"dynamic-table__table": "_84d9a0e0_dynamic-table__table",
	"dynamicTable__table": "_84d9a0e0_dynamic-table__table",
	"dynamic-table__dropdown-action-button": "_84d9a0e0_dynamic-table__dropdown-action-button",
	"dynamicTable__dropdownActionButton": "_84d9a0e0_dynamic-table__dropdown-action-button"
};
export default ___CSS_LOADER_EXPORT___;
