import { AxiosError } from 'axios';
import { createEffect, Effect } from 'effector';
import humps from 'humps';

import { endpoints } from '@vkph/common/endpoints';
import { removeMemberFromBlogEffect } from '@vkph/common/store/blogs';
import { blogEndpointsMap, BlogStorageParams } from '@vkph/common/store/blogs/api';
import {
  BlogTypes,
  BaseFieldParams,
  DictPaginated,
  OrderingParams,
  PaginatedListResults,
  PaginatedResults,
  PaginationParams,
  isDictPaginated,
  Ordering,
} from '@vkph/common/types';
import {
  BlogAlbumId,
  BlogAlbumImageModel,
  BlogAlbumModel,
  BlogId,
  BlogSlugId,
  BlogRequestStatus,
  BlogType,
  PostBasicModel,
  PeopleModel,
  PermissionsV2Enum,
} from '@vkph/common/types/models';
import {
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
  AbstractStorage,
  abstractStorageFactory,
} from '@vkph/common/utils';
import { RolesManagers } from '~groups/store/blog/constants';
import {
  BlogParticipantsInfoModel,
  DecideUserRequestToBlogModel,
  MemberPendingInviteModel,
  MemberRequestModel,
} from '~groups/types/models/blog-members';

import {
  createBlogAlbum,
  CreateBlogAlbumParams,
  decideUserRequestToBlogEndpoint,
  deleteAlbumFromBlog,
  DeleteAlbumFromBlogParams,
  deleteImageFromAlbum,
  DeleteImageFromAlbumParams,
  editBlogAlbum,
  EditBlogAlbumParams,
  getBlogAvailableUsersEndpoint,
  getBlogRequestsEndpoint,
  getBlogSentInvitationsEndpoint,
  InviteUserParams,
  inviteUserToBlog,
  MemberRequestParams,
  revokeBlogMemberInvite,
  RevokeInviteUserParams,
  uploadBlogAlbum,
  uploadBlogAlbumByIds,
  UploadBlogAlbumByIdsParams,
  UploadBlogAlbumParams,
  deleteImagesFromAlbum,
  DeleteImagesFromAlbumParams,
  deleteAllImagesFromAlbum,
  DeleteAllImagesFromAlbumParams,
} from './api';

export interface BlogsParams extends Partial<PaginationParams>, Pick<BaseFieldParams, 'search'> {
  isMine?: boolean;
  isSubscribed?: boolean;
  category?: string;
  blogTypeList?: BlogType[];
}

export enum MethodTypes {
  GET = 'get',
  POST = 'post',
}

export type GetBlogsStorageParams = {
  baseParams?: BlogsParams;
  type: BlogTypes;
  method: MethodTypes;
};

const EMPTY_ID = 0;

export const getBlogsStorage = <T extends { permissionsV2: Record<PermissionsV2Enum, boolean> }>({
  baseParams,
  type,
  method,
}: GetBlogsStorageParams) => {
  const storage = abstractStorageFactory<
    DictPaginated<T> | PaginatedResults<T>,
    T[],
    T[],
    Partial<BlogsParams>
  >({
    endpointBuilder: ({ pageNumber, pageSize }) => {
      return buildEndpointWithQueryParams(blogEndpointsMap[type](EMPTY_ID as never), {
        pageNumber,
        pageSize,
      });
    },
    dataBuilder: (data) => ({ ...baseParams, ...data }),
    defaultValue: [],
    requestMethod: method,
    dataMapper: (res) =>
      isDictPaginated(res)
        ? res.items.map((item) => ({ ...item, permissionsV2: humps.decamelizeKeys(item.permissionsV2) }))
        : res.results.map((item) => ({ ...item, permissionsV2: humps.decamelizeKeys(item.permissionsV2) })),
    paginationInfoRetriever: (res) => ({
      count: isDictPaginated(res) ? res.meta.objectsTotal : res.count,
    }),
  });

  return { storage };
};

export interface BlogsStorage<T> {
  storage: AbstractStorage<DictPaginated<T> | PaginatedResults<T>, T[], T[], Partial<BlogsParams>>;
}

export type InvitedMember = PeopleModel & {
  isInvite: boolean;
};

export type BlogInvitedMembersParams = PaginationParams &
  EndpointQueryParamsBaseType &
  Pick<BaseFieldParams, 'search'>;

export const inviteUserToBlogEffect = createEffect<InviteUserParams, unknown, AxiosError>(inviteUserToBlog);

export const revokeUserInviteToBlogEffect = createEffect<RevokeInviteUserParams, unknown, AxiosError>(
  revokeBlogMemberInvite,
);

export const getBlogInvitedMembersStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<InvitedMember>,
    InvitedMember[],
    InvitedMember[],
    BlogInvitedMembersParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(getBlogAvailableUsersEndpoint(blogId), params),
    defaultValue: [],
    dataMapper: (data) =>
      data.items.map((item) => {
        return {
          ...item,
          isInvited: false,
        };
      }),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const toggleInvitedUserState = (user: InvitedMember, userId: string) => {
    if (user.id === userId) {
      return {
        ...user,
        isInvite: !user.isInvite,
      };
    }

    return user;
  };

  storage.store.on([revokeUserInviteToBlogEffect, inviteUserToBlogEffect], (prevState, { userId }) => ({
    ...prevState,
    data: prevState.data.map((user) => toggleInvitedUserState(user, userId)),
  }));

  storage.store.on(
    [revokeUserInviteToBlogEffect.fail, inviteUserToBlogEffect.fail],
    (prevState, { params: { userId } }) => ({
      ...prevState,
      data: prevState.data.map((user) => toggleInvitedUserState(user, userId)),
    }),
  );

  return {
    storage,
  };
};

export type BlogInvitedMembersStorage = ReturnType<typeof getBlogInvitedMembersStorage>;

export type BlogAlbumsFilterParams = {
  createdAtFrom?: string;
  createdAtTo?: string;
  categoryId?: number;
};

export const getBlogAlbumListStorage = ({ blogSlugId }: { blogSlugId: BlogSlugId }) => {
  const storage = abstractStorageFactory<
    BlogAlbumModel[],
    BlogAlbumModel[],
    BlogAlbumModel[],
    Partial<BlogStorageParams> & { filterParams: BlogAlbumsFilterParams }
  >({
    endpointBuilder: (props) => {
      return props?.blogId
        ? buildEndpointWithQueryParams(endpoints.asyncBlogs.blogIdAlbums(props.blogId), props.filterParams)
        : buildEndpointWithQueryParams(endpoints.asyncBlogs.blogSlugAlbums(blogSlugId), props.filterParams);
    },
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const createBlogAlbumEffect = createEffect<CreateBlogAlbumParams, BlogAlbumModel, AxiosError>(
    createBlogAlbum,
  );

  const editBlogAlbumEffect = createEffect<EditBlogAlbumParams, BlogAlbumModel, AxiosError>(editBlogAlbum);

  storage.store.on(createBlogAlbumEffect.done, (prevState, { result: newOrEditedBlog }) => ({
    ...prevState,
    data: [newOrEditedBlog, ...prevState.data],
  }));

  storage.store.on(editBlogAlbumEffect.done, (prevState, { result: editedBlogAlbum }) => ({
    ...prevState,
    data: prevState.data.map((blogAlbum) =>
      blogAlbum.id === editedBlogAlbum.id ? editedBlogAlbum : blogAlbum,
    ),
  }));

  const deleteBlogAlbumEffect = createEffect<DeleteAlbumFromBlogParams, unknown, AxiosError>(
    deleteAlbumFromBlog,
  );

  storage.store.on(deleteBlogAlbumEffect.done, (prevState, { params }) => ({
    ...prevState,
    data: prevState.data.filter((blogAlbum) => blogAlbum.id !== params.albumId),
  }));

  return { storage, createBlogAlbumEffect, editBlogAlbumEffect, deleteBlogAlbumEffect };
};

export type BlogAlbumListStorage = ReturnType<typeof getBlogAlbumListStorage>;

export type BlogAlbumSingleStorage = { albumId: BlogAlbumId };

export const getBlogAlbumImagesStorage = () => {
  const storage = abstractStorageFactory<
    BlogAlbumImageModel[],
    BlogAlbumImageModel[],
    BlogAlbumImageModel[],
    BlogAlbumSingleStorage
  >({
    endpointBuilder: ({ albumId }) => endpoints.asyncBlogs.blogAlbumIdImages(albumId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      data.map((item) => {
        return {
          ...item,
          reactionsCount:
            item?.reactions?.reduce((current, { reactionCount }) => current + reactionCount, 0) || 0,
        };
      }),
  });

  const uploadBlogAlbumEffect = createEffect<UploadBlogAlbumParams, unknown, AxiosError>(uploadBlogAlbum);
  const uploadBlogAlbumByIdEffect = createEffect<UploadBlogAlbumByIdsParams, unknown, AxiosError>(
    uploadBlogAlbumByIds,
  );

  const deleteImageFromAlbumEffect = createEffect<DeleteImageFromAlbumParams, unknown, AxiosError>(
    deleteImageFromAlbum,
  );

  /* TODO: добавить после выполнения пункта 5 задачи B2BCORE-2823. Нужна доработка со стороны бэка, чтоб возвращаемые значения
   *  после загрузки изображений в альбом, совпадали с данными списочной ручки. Т.е было равно BlogAlbumImageModel */
  // const uploadBlogAlbumEffect = createEffect<UploadBlogAlbumParams, BlogAlbumImageModel, AxiosError>(
  //   uploadBlogAlbum,
  // );

  // storage.store.on(uploadBlogAlbumEffect, (prevState, { result: uploadedImages }) => ({
  //   ...prevState,
  //   data: [uploadedImages, ...prevState.data],
  // }));

  storage.store.on(deleteImageFromAlbumEffect.done, (prevState, { params: { imageId } }) => ({
    ...prevState,
    data: prevState.data.filter((image) => image.id !== imageId),
  }));

  const deleteImagesFromAlbumEffect = createEffect<DeleteImagesFromAlbumParams, unknown, AxiosError>(
    deleteImagesFromAlbum,
  );

  storage.store.on(deleteImagesFromAlbumEffect, (prevState, { data: { ids } }) => ({
    ...prevState,
    data: prevState.data.filter(({ id }) => !ids.includes(id)),
  }));

  const deleteAllImagesFromAlbumEffect = createEffect<DeleteAllImagesFromAlbumParams, unknown, AxiosError>(
    deleteAllImagesFromAlbum,
  );

  storage.store.on(deleteAllImagesFromAlbumEffect, (prevState) => ({
    ...prevState,
    data: [],
  }));

  return {
    storage,
    uploadBlogAlbumEffect,
    uploadBlogAlbumByIdEffect,
    deleteImageFromAlbumEffect,
    deleteImagesFromAlbumEffect,
    deleteAllImagesFromAlbumEffect,
  };
};

export type BlogAlbumImagesStorage = ReturnType<typeof getBlogAlbumImagesStorage>;

export type UploadBlogAlbumByIdsEffect = Effect<UploadBlogAlbumByIdsParams, unknown, AxiosError>;

export const getBlogMembersPendingInvitesStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<MemberPendingInviteModel>,
    MemberPendingInviteModel[],
    MemberPendingInviteModel[],
    PaginationParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(getBlogSentInvitationsEndpoint({ blogId }), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  storage.store.on(revokeUserInviteToBlogEffect, (prevState, { userId }) => ({
    ...prevState,
    data: prevState.data.filter((invite) => invite.user.keycloakUser?.keycloakId !== userId),
  }));

  return {
    storage,
  };
};

export type BlogMembersPendingInvitesStorage = ReturnType<typeof getBlogMembersPendingInvitesStorage>;

export const decideUserRequestToBlogEffect = createEffect<
  MemberRequestParams,
  DecideUserRequestToBlogModel,
  AxiosError
>((params) => decideUserRequestToBlogEndpoint(params).then(({ data }) => data));

export const getBlogMembersRequestsStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<MemberRequestModel>,
    MemberRequestModel[],
    MemberRequestModel[],
    PaginationParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(getBlogRequestsEndpoint({ blogId }), params),
    cancelPendingRequestOnFetch: true,
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    dataMapper: ({ items }) => items,
  });

  storage.store.on(decideUserRequestToBlogEffect, (prevState, { requestId }) => ({
    ...prevState,
    data: prevState.data.filter((request) => request.id !== requestId),
  }));

  return {
    storage,
  };
};

export type BlogMembersRequestsStorage = ReturnType<typeof getBlogMembersRequestsStorage>;

interface BlogParticipantsInfoStoredData extends Pick<BlogParticipantsInfoModel, 'invites' | 'requests'> {
  members: number;
  managers: number;
}

const blogParticipantsInfoMapper = (blogParticipantsInfoData: BlogParticipantsInfoModel) => {
  const { requests, invites, follower } = blogParticipantsInfoData;

  const decamelizedRoles = humps.decamelizeKeys<BlogParticipantsInfoModel>(blogParticipantsInfoData);
  const managersCount = RolesManagers.reduce((acc, item) => acc + decamelizedRoles[item], 0);

  return {
    requests,
    invites,
    members: follower,
    managers: managersCount,
  };
};

export const getBlogParticipantsInfoStorage = () => {
  const storage = abstractStorageFactory<
    BlogParticipantsInfoModel,
    BlogParticipantsInfoStoredData,
    BlogParticipantsInfoStoredData,
    BlogStorageParams
  >({
    endpointBuilder: ({ blogId }) => endpoints.asyncBlogs.blogParticipantsInfo(blogId),
    defaultValue: { invites: 0, requests: 0, members: 0, managers: 0 },
    dataMapper: blogParticipantsInfoMapper,
    cancelPendingRequestOnFetch: true,
  });

  storage.store
    .on(inviteUserToBlogEffect.doneData, (state) => ({
      ...state,
      data: {
        ...state.data,
        invites: state.data.invites + 1,
      },
    }))
    .on(revokeUserInviteToBlogEffect.doneData, (state) => ({
      ...state,
      data: {
        ...state.data,
        invites: state.data.invites - 1,
      },
    }))
    .on(removeMemberFromBlogEffect.doneData, (state, { role }) => {
      const isManager = RolesManagers.includes(role);

      return {
        ...state,
        data: {
          ...state.data,
          members: !isManager ? state.data.members - 1 : state.data.members,
          managers: isManager ? state.data.managers - 1 : state.data.managers,
        },
      };
    })
    .on(decideUserRequestToBlogEffect.doneData, (state, data) => {
      return {
        ...state,
        data: {
          ...state.data,
          members: data.state === BlogRequestStatus.Approved ? state.data.members + 1 : state.data.members,
          requests: state.data.requests - 1,
        },
      };
    });

  return { storage };
};

export type BlogPostsStorageOrdered = Partial<Ordering<OrderingParams>>;

export type GetBlogsPostsParams = PaginationParams &
  BlogPostsStorageOrdered &
  Pick<BaseFieldParams, 'search'>;

export const getBlogPostsStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    PaginatedListResults<PostBasicModel>,
    PostBasicModel[],
    PostBasicModel[],
    Partial<GetBlogsPostsParams>
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.asyncBlogs.blogEntries(blogId), params),
    defaultValue: [],
    dataMapper: ({ results }) => results,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};

export type GetBlogPostsStorage = ReturnType<typeof getBlogPostsStorage>;
