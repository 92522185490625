import { UiDivider, UiFlex, UiProgress, UiTypography, UiUploadFile, useSpace } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { declension, filesDeclension } from '@vkph/common/utils';

type Props = {
  files: UiUploadFile[];
};

export const FilesProgress: FC<Props> = (props) => {
  const { files } = props;
  const { space2XS, spaceM } = useSpace();

  const progress = useMemo(
    () =>
      files.reduce(
        (acc, file) => ({
          total: acc.total + (file?.progressEvent?.total || 0),
          loaded: acc.loaded + (file?.progressEvent?.loaded || 0),
        }),
        { total: 0, loaded: 0 },
      ),
    [files],
  );

  const progressPercent = useMemo(() => {
    return Math.ceil((progress.loaded / progress.total) * 100);
  }, [progress]);

  const isFullyUploaded = progress.total === progress.loaded;
  const uploadingCount = files.filter((file) => file.status === 'uploading').length;

  return (
    <UiFlex gap={space2XS} vertical style={{ marginBottom: spaceM }}>
      <UiFlex gap={space2XS} align="center">
        <UiTypography.Title style={{ margin: 0 }} level={4}>
          Загружено
        </UiTypography.Title>
        <UiDivider.Dot />
        <UiTypography.Title style={{ margin: 0 }} level={4}>
          {progressPercent || 100} %
        </UiTypography.Title>
      </UiFlex>
      <UiTypography.Text type="secondary">
        {uploadingCount
          ? `Осталось ${uploadingCount} ${declension(uploadingCount, filesDeclension)}`
          : 'Все файлы загружены'}
      </UiTypography.Text>
      {!isFullyUploaded && (
        <UiProgress
          type="line"
          size={{ height: space2XS }}
          showInfo={false}
          percent={progressPercent}
          strokeLinecap="round"
        />
      )}
    </UiFlex>
  );
};
