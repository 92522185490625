import { NewsCategoryId, NewsId, SiteId, UserIdModel } from '../types/models';

export const newsEndpoints = {
  feed: () => '/api/news/feed/',
  categoryList: () => '/api/news/category/list/',
  newsCategoryId: (categoryId: NewsCategoryId) => `/api/news/category/${categoryId}/`,
  categorySave: () => '/api/news/category/save/',
  list: () => '/api/news/list/',
  newsId: (newsId: NewsId) => `/api/news/${newsId}/`,
  newsIdPlanning: (newsId: NewsId) => `/api/news/${newsId}/planning/`,
  newsIdPublish: (newsId: NewsId) => `/api/news/${newsId}/publish/`,
  save: () => '/api/news/save/',
  rolesList: () => `/api/news/roles/list/`,
  rolesSave: () => '/api/news/roles/save/',
  rolesUserId: (userId: UserIdModel) => `/api/news/roles/${userId}/`,
  siteIdAuthors: (siteId: SiteId) => `/api/news/${siteId}/authors/`,
  newsIdRoles: (newsId: NewsId) => `/api/news/${newsId}/roles/`,
  newsPermissionsRoles: () => '/api/news/permissions/roles/',
  supercover: () => '/api/news/supercover/',
};
