import { Progress } from 'antd';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import React, { FC } from 'react';

import CheckSmallSvg from '@vkph/ui/svg/check-small.svg';
import ClearSvg from '@vkph/ui/svg/clear.svg';

import { UiProgressProps } from '..';
import { useTheme } from '../../../providers/theme';
import { UiButton } from '../../button';
import { UiFlex } from '../../flex';
import { UiIcon } from '../../icon';
import styles from './UiProgressCircle.scss';

export interface UiProgressCircleProps extends UiProgressProps {
  onUploading?: () => void;
  onSuccess?: () => void;
  disabled?: boolean;
}

type UiProgressCircleStatus = 'uploading' | 'success';

export const UiProgressCircle: FC<UiProgressCircleProps> = (props) => {
  const { onUploading, onSuccess, disabled, ...restProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const getStatus = (percent?: number): UiProgressCircleStatus => {
    if (isNumber(percent) && percent !== 0 && percent < 100) {
      return 'uploading';
    }

    return 'success';
  };

  const actions: Record<UiProgressCircleStatus, (() => void) | undefined> = {
    uploading: onUploading,
    success: onSuccess,
  };

  const actionIcons: Record<UiProgressCircleStatus, SvgrComponent> = {
    uploading: ClearSvg,
    success: CheckSmallSvg,
  };

  return (
    <Progress
      className={styles.uiProgressCircle}
      size={36}
      type="circle"
      strokeColor={disabled ? themeVariables.colorStroke : themeVariables.colorBrand}
      format={(percent) => {
        const status = getStatus(percent);

        return (
          <UiFlex align="center" justify="center">
            <UiButton
              type="link-secondary"
              style={{ borderRadius: '50%' }}
              onClick={actions[status]}
              className={styles.uiProgressCircle}
              disabled={disabled || !actions[status]}
            >
              <UiIcon.Label
                className={classNames(styles.uiProgressCircle__icon, {
                  [styles.uiProgressCircle__icon_success]: status === 'success',
                  [styles.uiProgressCircle__icon_disabled]: disabled,
                })}
                component={actionIcons[status]}
                width={20}
                height={20}
                shift={0}
              />
            </UiButton>
          </UiFlex>
        );
      }}
      {...restProps}
    />
  );
};
