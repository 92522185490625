// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d10aa2f0_ui-menu .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub{max-height:var(--ui-menu-scrollHeight)}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/menu/UiMenu.scss"],"names":[],"mappings":"AAQE,0EACE,sCAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n$UiMenuScrollHeight: --ui-menu-scrollHeight;\n\n:export {\n  uiMenuScrollHeight: $UiMenuScrollHeight;\n}\n\n.ui-menu {\n  :global(.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub) {\n    max-height: var($UiMenuScrollHeight);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uiMenuScrollHeight": "--ui-menu-scrollHeight",
	"ui-menu": "d10aa2f0_ui-menu",
	"uiMenu": "d10aa2f0_ui-menu"
};
export default ___CSS_LOADER_EXPORT___;
