import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import {
  GetSummaryAnalyticsReportParams,
  DictPaginated,
  PaginationParams,
  BlogsListItem,
  BestPostAuthor,
} from '../../types';
import { PostModel } from '../../types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory, regexpPatterns } from '../../utils';
import { getReport } from './api';

export const getBlogsReportsTheBestAuthorStorage = () => {
  const storage = abstractStorageFactory<BestPostAuthor, BestPostAuthor, null>({
    endpointBuilder: endpoints.asyncBlogs.reportsTheBestAuthor,
    defaultValue: null,
  });

  return { storage };
};

export const getBlogsReportsTopPostsStorage = () => {
  const storage = abstractStorageFactory<PostModel[], PostModel[], PostModel[]>({
    endpointBuilder: endpoints.asyncBlogs.reportsTopPosts,
    defaultValue: [],
  });

  return { storage };
};

export const getBlogsListTopStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<BlogsListItem>,
    BlogsListItem[],
    BlogsListItem[],
    PaginationParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.asyncBlogs.reportsTopBlogs(), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
  });

  return { storage };
};

type DownloadReport = {
  name: string;
  file: Blob;
};

export const downloadReportEffect = createEffect<GetSummaryAnalyticsReportParams, DownloadReport, AxiosError>(
  (params) =>
    getReport(params).then((res) => {
      const name = res?.headers?.['content-disposition']?.match?.(regexpPatterns?.contentDisposotion)?.[1];

      return { name, file: res.data };
    }),
);
