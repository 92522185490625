import { ActionsDropdown } from '@vkph/components';
import { UiCol, UiFlex, UiIcon, UiRow, UiTruncateMarkup, UiTypography, useSpace } from '@vkph/ui';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import React, { useMemo } from 'react';

import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { PollModel, PollPermissions } from '@vkph/common/types/models';
import { declension, memberDeclension } from '@vkph/common/utils';
import CommunitySVG from '@vkph/ui/svg/community.svg';
import PollSVG from '@vkph/ui/svg/poll.svg';
import TimeSVG from '@vkph/ui/svg/time.svg';

import styles from './PollHeader.scss';

export type PollHeaderType = {
  poll: PollModel;
  onDelete?: () => void;
  onSuccess: (poll: PollModel) => void;
  isEdit?: boolean;
  isLoading?: boolean;
};

export const PollHeader: React.FC<PollHeaderType> = (props) => {
  const { poll, isLoading, onSuccess, onDelete, isEdit } = props;
  const { isAnonymous, title, dateEnd, votedRespondentsCount, permissions, totalAnswersCount } = poll;
  const { spaceXS } = useSpace();

  const editPoll = () => {
    openGlobalModal(GlobalModalNames.PollEditor, { poll, onSuccess });
  };

  const deletePoll = () => {
    onDelete?.();
  };

  const isVoted = totalAnswersCount > 0;
  const isAllowEdit = useMemo(() => permissions?.includes(PollPermissions.PollEdit), [permissions]);
  const isAllowDelete = useMemo(
    () => isEdit && permissions?.includes(PollPermissions.PollDelete),
    [permissions],
  );

  const pollActions = useMemo(() => {
    const result = [];

    if (isAllowEdit && !isVoted && isEdit) {
      result.push({ label: 'Редактировать', onClick: editPoll });
    }

    if (isAllowDelete && isEdit) {
      result.push({ label: 'Удалить', onClick: deletePoll });
    }

    return result;
  }, [isAllowDelete, isAllowEdit, isVoted, poll]);

  const currentStatus = useMemo(() => {
    if (!dateEnd) {
      return '';
    }

    return new Date(dateEnd) < new Date()
      ? 'Голосование закончилось'
      : `Голосование заканчивается ${format(new Date(dateEnd), 'd MMMM в HH:mm', {
          locale: ru,
        })}`;
  }, [dateEnd]);

  const currentTitle = useMemo(() => {
    return `${isAnonymous ? 'Анонимный' : 'Публичный'} опрос «${title}»`;
  }, [title, isAnonymous]);

  return (
    <UiRow justify="space-between" className={styles.pollHeader} wrap={false}>
      <UiCol>
        <UiFlex gap={spaceXS} vertical>
          <UiFlex align="middle">
            <UiIcon component={PollSVG} height={20} width={20} className={styles.pollHeader__icon} />
            <UiTypography.Text strong type="secondary">
              <UiTruncateMarkup truncate lines={3}>
                {currentTitle}
              </UiTruncateMarkup>
            </UiTypography.Text>
          </UiFlex>
          {dateEnd && (
            <UiFlex align="middle">
              <UiIcon component={TimeSVG} height={20} width={20} className={styles.pollHeader__icon} />
              <UiTypography.Text strong type="secondary">
                <UiTruncateMarkup truncate>{currentStatus}</UiTruncateMarkup>
              </UiTypography.Text>
            </UiFlex>
          )}
          <UiCol>
            <UiFlex align="middle">
              <UiIcon component={CommunitySVG} height={20} width={20} className={styles.pollHeader__icon} />
              <UiTypography.Text strong type="secondary">
                <UiTruncateMarkup truncate>{`${votedRespondentsCount} ${declension(
                  votedRespondentsCount,
                  memberDeclension,
                )}`}</UiTruncateMarkup>
              </UiTypography.Text>
            </UiFlex>
          </UiCol>
        </UiFlex>
      </UiCol>
      {pollActions.length > 0 && (
        <UiCol>
          <ActionsDropdown disabled={isLoading} items={pollActions} />
        </UiCol>
      )}
    </UiRow>
  );
};
