import {
  UiButton,
  UiCheckbox,
  UiForm,
  UiFormListItemAddActionType,
  UiIcon,
  UiInput,
  UiModal,
  UiModalTypes,
  UiSelect,
  UiSelectDefaultOption,
  useModalBase,
} from '@vkph/ui';
import { useStore, useStoreMap } from 'effector-react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { currentProfileRolesPermissionsMapStore } from '@vkph/common/providers';
import { AllAllowedPermissions, AllowedPermission } from '@vkph/common/types/models';
import {
  MAX_LENGTH_INPUT_200,
  checkInnerPath,
  normalizeToInnerUrl,
  normalizeValueTrim,
  normalizeValueTrimStart,
  requiredRule,
  validURLWithProtocolRule,
} from '@vkph/common/utils';
import { PermissionForm } from '@vkph/modules/types/navigation';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import ClearSvg from '@vkph/ui/svg/clear.svg';

import styles from '../NavigationEdit.scss';
import { CreateNewSection } from '../create-new-section/CreateNewSection';
import {
  ItemListField,
  SectionItemLinkListField,
  SectionItemListField,
  SectionItemsList,
} from '../section-items-list/SectionItemsList';

const isPermissionOptions = (
  permissions: PermissionForm[],
): permissions is UiSelectDefaultOption<AllowedPermission>[] => {
  if (permissions.length > 0) {
    const [firstItem] = permissions;

    return typeof firstItem === 'object' && 'value' in firstItem;
  }

  return false;
};

export type AddSectionLinkHandler = { addSectionLink: UiFormListItemAddActionType };

export const SectionsList: FC = () => {
  const [addForm] = UiForm.useForm();
  const { confirm } = useModalBase();
  const [isSectionAddOpen, setIsSectionAddOpen] = useState(false);
  const [selectedSectionEdit, setSelectedSectionEdit] = useState<SectionItemListField | null>(null);
  const [addSectionLinkAction, setAddSectionLinkAction] = useState<AddSectionLinkHandler | null>(null);
  const [selectedSectionLinkEdit, setSelectedSectionLinkEdit] = useState<SectionItemLinkListField | null>(
    null,
  );
  const currentProfileRolesPermissionsMap = useStore(currentProfileRolesPermissionsMapStore);
  const permissionOptions = useStoreMap(currentProfileRolesPermissionsMapStore, (permissions) =>
    Array.from(permissions).map(([value, label]) => ({ label, value })),
  );
  const onOpenSectionEdit = (sectionField: SectionItemListField) => {
    addForm.setFieldsValue({ section: sectionField.value?.name });
    setSelectedSectionEdit(sectionField);
  };

  const onOpenSectionLinkEdit = (sectionLinkField: SectionItemLinkListField) => {
    const { value } = sectionLinkField;
    let url = String(value?.url);

    if (checkInnerPath(url)) {
      url = `${process.env.REMOTE_URL || window.location.origin}${url}`;
    }

    addForm.setFieldsValue({
      label: value?.label,
      url,
      blank: value?.blank,
      permissions: value?.permissions,
    });
    setSelectedSectionLinkEdit(sectionLinkField);
  };

  const onCloseCreateNewSection = () => {
    addForm.resetFields();
    setIsSectionAddOpen(false);
    setAddSectionLinkAction(null);
    setSelectedSectionEdit(null);
    setSelectedSectionLinkEdit(null);
  };

  const modalSettings = useMemo(() => {
    const editSectionModalTitle = isSectionAddOpen ? 'Добавить раздел' : 'Редактировать раздел';
    const editSectionLinkModalTitle = addSectionLinkAction ? 'Добавить ссылку' : 'Редактировать ссылку';
    const deleteConfirmModalTitle = selectedSectionEdit ? 'Удалить раздел меню?' : 'Удалить ссылку?';

    const isOpenSectionEditModal = isSectionAddOpen || Boolean(selectedSectionEdit);
    const isOpenSectionLinkEditModal = Boolean(addSectionLinkAction) || Boolean(selectedSectionLinkEdit);

    return {
      title: isOpenSectionEditModal ? editSectionModalTitle : editSectionLinkModalTitle,
      isOpen: isOpenSectionEditModal || isOpenSectionLinkEditModal,
      isSectionForm: isOpenSectionEditModal,
      isSectionLinkForm: isOpenSectionLinkEditModal,
      deleteConfirmModalTitle,
    };
  }, [isSectionAddOpen, selectedSectionEdit, addSectionLinkAction, selectedSectionLinkEdit]);

  const permissionsToOptions = (permissions: AllAllowedPermissions[]) =>
    permissions?.map((value) => ({
      key: value,
      label: currentProfileRolesPermissionsMap.get(value),
      value,
    }));

  const onSaveEditSection = useCallback(
    (addNewSection: UiFormListItemAddActionType) => {
      if (isSectionAddOpen && addNewSection) {
        const section = normalizeValueTrim(addForm.getFieldValue('section'));

        addNewSection({
          name: section,
        });
      }

      if (selectedSectionEdit?.onChange) {
        const { value, onChange } = selectedSectionEdit;

        const section = normalizeValueTrim(addForm.getFieldValue('section'));

        if (value) {
          onChange({ ...value, name: section });
        }
      }

      const onChangeLink = addSectionLinkAction?.addSectionLink || selectedSectionLinkEdit?.onChange;

      if (onChangeLink) {
        const url = normalizeToInnerUrl(addForm.getFieldValue('url'));
        const label = normalizeValueTrim(addForm.getFieldValue('label'));
        const currentPermissions = addForm.getFieldValue('permissions') || [];

        const permissions = isPermissionOptions(currentPermissions)
          ? currentPermissions
          : permissionsToOptions(currentPermissions);

        addForm.setFieldValue('permissions', permissions);

        onChangeLink({
          label,
          url,
          blank: addForm.getFieldValue('blank'),
          permissions: permissions.map(({ value }) => value),
        });
      }

      onCloseCreateNewSection();
    },
    [isSectionAddOpen, selectedSectionEdit, addSectionLinkAction, selectedSectionLinkEdit],
  );

  const onRemoveSectionAction = useCallback(() => {
    const { onRemove } = selectedSectionEdit || selectedSectionLinkEdit || ({} as ItemListField);

    if (onRemove) {
      const confirmModal = confirm({
        title: modalSettings.deleteConfirmModalTitle,
        okText: 'Удалить',
        cancelText: 'Отмена',
        centered: true,
        autoFocusButton: null,
      });

      confirmModal.update({
        onOk: (close) => {
          onRemove();
          close();
          onCloseCreateNewSection();
        },
      });
    }
  }, [selectedSectionEdit, selectedSectionLinkEdit]);

  const RemoveButton = (
    <UiButton
      size="middle"
      type="link-secondary"
      icon={<UiIcon component={ClearSvg} width={20} height={20} />}
    />
  );

  return (
    <UiForm.List name="sections">
      {(sectionsFields, { add, remove, move }) => (
        <>
          {sectionsFields.map((field, index) => {
            return (
              <UiForm.Item noStyle {...field} key={field.key}>
                <SectionItemsList
                  index={index}
                  name={field.name}
                  onRemove={() => remove(field.name)}
                  onSectionMove={move}
                  onSectionEdit={onOpenSectionEdit}
                  onSectionLinkEdit={onOpenSectionLinkEdit}
                  onSectionLinkAdd={setAddSectionLinkAction}
                />
              </UiForm.Item>
            );
          })}

          <UiButton
            size="large"
            type="secondary"
            label="Добавить раздел"
            onClick={() => setIsSectionAddOpen(true)}
            className={styles.navigationEdit__sectionAdd}
            icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          />

          <UiModal type={UiModalTypes.Small} isOpen={modalSettings.isOpen} onClose={onCloseCreateNewSection}>
            <CreateNewSection
              title={modalSettings.title}
              onSave={addForm.submit}
              onCancel={onCloseCreateNewSection}
              onRemove={addSectionLinkAction || isSectionAddOpen ? undefined : onRemoveSectionAction}
            >
              <UiForm
                form={addForm}
                layout="vertical"
                name="add-or-edit-section-form"
                onFinish={() => onSaveEditSection(add)}
              >
                {modalSettings.isSectionForm && (
                  <UiForm.Item
                    label="Название"
                    name="section"
                    rules={[requiredRule]}
                    normalize={normalizeValueTrimStart}
                  >
                    <UiInput size="large" maxLength={MAX_LENGTH_INPUT_200} />
                  </UiForm.Item>
                )}

                {modalSettings.isSectionLinkForm && (
                  <>
                    <UiForm.Item
                      label="Название"
                      name="label"
                      rules={[requiredRule]}
                      normalize={normalizeValueTrimStart}
                    >
                      <UiInput size="large" maxLength={MAX_LENGTH_INPUT_200} />
                    </UiForm.Item>
                    <UiForm.Item
                      name="url"
                      label="URL-адрес"
                      rules={[validURLWithProtocolRule]}
                      normalize={normalizeValueTrim}
                    >
                      <UiInput size="large" />
                    </UiForm.Item>
                    <UiForm.Item name="blank" valuePropName="checked">
                      <UiCheckbox className={styles.navigationEdit__checkbox_center}>
                        Открывать в новом окне
                      </UiCheckbox>
                    </UiForm.Item>
                    <UiForm.Item label="Разрешения" name="permissions">
                      <UiSelect
                        size="large"
                        mode="multiple"
                        showSearch={false}
                        defaultActiveFirstOption
                        filterOption={false}
                        placeholder="Добавить"
                        removeIcon={RemoveButton}
                        options={permissionOptions}
                      />
                    </UiForm.Item>
                  </>
                )}
              </UiForm>
            </CreateNewSection>
          </UiModal>
        </>
      )}
    </UiForm.List>
  );
};
