import { CopyConfig } from 'antd/lib/typography/Base';
import useCopyClick from 'antd/lib/typography/hooks/useCopyClick';
import { useEffect } from 'react';

interface UseClipboardOptions {
  onSuccess?: () => void;
  copyConfig?: CopyConfig;
}

export const useClipboard = (text: string, options?: UseClipboardOptions) => {
  const { onClick, copied, copyLoading } = useCopyClick({
    copyConfig: { text, ...options?.copyConfig },
  });

  useEffect(() => {
    if (copied) {
      options?.onSuccess?.();
    }
  }, [copied]);

  return { setCopied: () => onClick(), copied, copyLoading };
};
